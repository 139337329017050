import { Dialog, Menu, Transition } from "@headlessui/react";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { Bars3BottomLeftIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { Fragment, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { logout } from "src/actions/auth";
import { getMenuData } from "src/actions/menu";
import { getDomoPageList, getDomoWorkspaceList, resetWorkspaceDetails } from "src/actions/page";
import NavItem from "src/components/Navigation/NavItem";
import AdminNavDesktop from "src/components/RoleViews/Admin/AdminNavDesktop";
import Logo from "src/components/Shared/Icons/Logo";
import UserProfile from "src/components/Users/UserProfile";
import { FeatureFlagsProvider } from "src/context/FeatureFlagContext";
import { UserWorkspaceContext } from "src/context/UserWorkspaceContext";
import { classNames } from "src/helpers/classNames";
import useOnClickOutside from "src/hooks/useOnClickOutside";

import Image from "src/components/Shared/Image";
import { noFavicon } from "src/config/host";
import { isSVGString } from "src/helpers";
import Button from "src/components/Shared/Buttons/Button";
import { v4 } from "uuid";

const UserLayout = ({ user, getMenuData, getDomoPageList, resetWorkspaceDetails, workspaceDetails, children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [workspaces, setWorkspaces] = useState([]);
  const [workspaceLoading, setWorkspaceLoading] = useState(true);
  const [userNavigation, setUserNavigation] = useState([
    { name: "My account", path: `/users/edit/${user?._id}`, navName: "Users" },
    { name: "Change Password", path: "/users/edit/me/change-password", navName: "Users" },
    { name: "Sign out", path: "/" },
  ]);
  const [currentNavigation, setCurrentNavigation] = useState("");
  const [groupId, setGroupId] = useState("");

  const ref = useRef();

  useOnClickOutside(ref, () => setSidebarOpen(false));

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [sidebarOpenMobile, setSidebarOpenMobile] = useState(false);
  const [loadedMenu, setLoadedMenu] = useState(false);

  const [pageList, setPageList] = useState([]);

  useEffect(() => {
    if (location.pathname.includes("group")) {
      setGroupId(location.pathname.split("/")[4]);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (!pageList.length) {
      setSidebarOpen(false);
    }
  }, [pageList.length]);

  useEffect(() => {
    if (Object.keys(workspaceDetails).length) {
      setSidebarOpen(true);
    } else {
      setSidebarOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Object.keys(workspaceDetails).length]);

  const updateNavigation = (navItem) => {
    setCurrentNavigation(navItem.slug || navItem.key);
    // navigate(`workspaces/${navItem.key}/portal`);
  };

  useEffect(() => {
    if (user?._id) {
      const getList = async () => {
        try {
          const n = location.pathname.split("/");
          const workspaceId = n.length > 2 ? n[2] : "";
          const groupId = !location.pathname.includes("group") ? null : n[4] || null;
          const response = await getDomoPageList({ user_id: user?._id, workspace_id: workspaceId });
          if (response.menu.length > 0 || response.individualMenu.length > 0 || response.data?.workspace_type === "JWT_FULL_EMBED") {
            let list = [];
            for (const indMenu of response.individualMenu) {
              list.push({
                key: indMenu._id || v4(),
                slug: indMenu?.page_id?.slug,
                name: indMenu?.page_id?.name,
                path: indMenu?.page_id?.page_type === "EXTERNAL_LINK" ? indMenu?.page_id?.page_link : `workspace/${response?.data?.slug || response?.data?._id}/page/${indMenu?.page_id?.slug || indMenu._id}`,
                permissions: [],
                isExternal: indMenu?.page_id?.page_type === "EXTERNAL_LINK" ? true : false,
                page_link_newtab: indMenu?.page_id?.page_link_newtab,
              });
            }
            for (const lt of response.menu) {
              let permissions = [];
              if (response.menu.length + response.individualMenu.length > 1) {
                for (const permission of lt.permissions) {
                  permissions.push({
                    key: permission._id || v4(),
                    slug: permission?.page_slug,
                    name: permission.page?.name,
                    path: permission.page?.page_type === "EXTERNAL_LINK" ? permission.page?.page_link : `workspace/${response?.data?.slug || response?.data?._id}/group/${lt.slug || lt._id}/page/${permission?.page_slug || permission._id}`,
                    isExternal: permission.page?.page_type === "EXTERNAL_LINK" ? true : false,
                    page_link_newtab: permission.page?.page_link_newtab,
                  });
                }

                list.push({
                  key: lt._id || v4(),
                  slug: lt.slug,
                  name: lt.name,
                  image: lt.image,
                  toggle: groupId && (groupId === lt._id || groupId === lt.slug) ? true : response.data && response.data?.layout_type === "TOP_NAVIGATION" ? false : false,
                  permissions: permissions,
                });
              } else {
                for (const permission of lt.permissions) {
                  list.push({
                    key: permission._id || v4(),
                    slug: permission?.page_slug,
                    name: permission.page.name,
                    path: permission.page?.page_type === "EXTERNAL_LINK" ? permission.page?.page_link : `workspace/${response?.data?.slug || response?.data?._id}/group/${lt.slug || lt._id}/page/${permission?.page_slug || permission._id}`,
                    permissions: [],
                    isExternal: permission.page?.page_type === "EXTERNAL_LINK" ? true : false,
                    page_link_newtab: permission.page?.page_link_newtab,
                  });
                }
              }
            }
            setPageList(list);
          } else {
            if (!window.location.pathname.includes("/embed/items")) {
              navigate("/workspaces/menu");
            }
          }
        } catch (error) {}
      };

      const resetWorkspace = async () => {
        try {
          await resetWorkspaceDetails({});
        } catch (error) {}
      };

      if (location.pathname.includes("workspaces/menu")) {
        resetWorkspace();
        setPageList([]);
      } else {
        getList();
      }
      if (user?.creation_type === "SSO_LOGIN") {
        if (location.pathname.includes("workspaces/menu")) {
          setUserNavigation([
            // { name: "My account", path: `/update-user/${user?._id}`, navName: "Users" },
            { name: "Sign out", path: "/" },
          ]);
        } else {
          setUserNavigation([
            // { name: "My account", path: `/update-user/${user?._id}`, navName: "Users" },
            { name: "Switch Workspace", path: `/workspaces/menu`, navName: "Workspace", hidden: workspaces.length <= 1, reloadLink: true },
            { name: "Sign out", path: "/" },
          ]);
        }
      } else {
        if (location.pathname.includes("workspaces/menu")) {
          setUserNavigation([
            // { name: "My account", path: `/update-user/${user?._id}`, navName: "Users" },
            // { name: "Change Password", path: "/users/edit/me/change-password", navName: "Users" },
            { name: "Sign out", path: "/" },
          ]);
        } else {
          setUserNavigation([
            // { name: "My account", path: `/update-user/${user?._id}`, navName: "Users" },
            // { name: "Change Password", path: "/users/edit/me/change-password", navName: "Users" },
            { name: "Switch Workspace", path: `/workspaces/menu`, navName: "Workspace", hidden: workspaces.length <= 1, reloadLink: true },
            { name: "Sign out", path: "/" },
          ]);
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?._id, location.pathname, workspaces.length]);

  useEffect(() => {
    const ac = new AbortController();

    const loadMenuData = async () => {
      try {
        await getMenuData({}, ac.signal);
        setLoadedMenu(true);
      } catch (error) {
        // console.dir("ERROR:", error);
      }
    };

    loadMenuData();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("impersonatedUserId")]);

  useEffect(() => {
    if (loadedMenu && !user) {
      navigate("/login");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    setWorkspaceLoading(true);

    if (user?._id) {
      const getWrkList = async () => {
        try {
          const responseList = await getDomoWorkspaceList({ user_id: user?._id });
          setWorkspaces(responseList?.workspaces);
        } catch (error) {
          console.dir("ERROR:", error);
        } finally {
          setWorkspaceLoading(false);
        }
      };
      getWrkList();
    }
  }, [user?._id]);

  if (window.location.pathname.includes("/embed/items/")) {
    return <>{children}</>;
  }

  return (
    <UserWorkspaceContext.Provider
      value={{
        workspaces,
        setWorkspaces,
        workspaceLoading,
        setWorkspaceLoading,
      }}>
      <FeatureFlagsProvider>
        <div className="flex h-full w-full flex-col">
          <Transition.Root
            show={sidebarOpenMobile}
            as={Fragment}>
            <Dialog
              as="div"
              className="relative z-40 lg:hidden"
              onClose={setSidebarOpenMobile}>
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
              </Transition.Child>

              <div className="fixed inset-0 z-40 flex">
                <Transition.Child
                  as={Fragment}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full">
                  <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-leftNavColor pb-4">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-300"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0">
                      <div className="absolute right-0 top-0 -mr-12 pt-2">
                        <Button
                          type="button"
                          version="default"
                          className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:border-highlightColor focus:ring-0"
                          onClick={() => setSidebarOpenMobile(false)}>
                          <span className="sr-only">Close Side Bar</span>
                          <XMarkIcon
                            className="h-6 w-6 text-white"
                            aria-hidden="true"
                          />
                        </Button>
                      </div>
                    </Transition.Child>
                    <div className="bg-headerColor pl-4">
                      <Logo />
                    </div>
                    <div className="mt-5 h-0 flex-1 overflow-y-auto">
                      <nav className="space-y-1 px-2">
                        {pageList.map((item) => (
                          <Fragment key={item.key}>
                            <NavItem
                              item={item}
                              activeItem={currentNavigation}
                              onClick={updateNavigation}
                            />
                          </Fragment>
                        ))}
                      </nav>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
                <div
                  className="w-14 flex-shrink-0"
                  aria-hidden="true">
                  {/* Dummy element to force leftNavColor to shrink to fit close icon */}
                </div>
              </div>
            </Dialog>
          </Transition.Root>
          <div className={classNames("flex w-full transition-all duration-150", sidebarOpen && pageList.length > 1 ? "lg:pl-0" : "")}>
            <div className="fixed top-0 z-50 flex h-16 w-full flex-shrink-0 bg-headerColor shadow-md">
              <Logo>
                {!window.location.pathname.includes("/workspaces/menu") && (workspaceDetails?._id ? workspaceDetails?.layout_type !== "TOP_NAVIGATION" && pageList.length > 1 : true) && (
                  <>
                    <Button
                      type="button"
                      version="default"
                      className="px-4 text-headerTextColor focus:border-highlightColor focus:ring-0 lg:hidden"
                      onClick={() => setSidebarOpenMobile(true)}>
                      <span className="sr-only">Open left navigation</span>
                      <Bars3BottomLeftIcon
                        className="h-6 w-6 text-headerTextColor"
                        aria-hidden="true"
                      />
                    </Button>
                    <Button
                      type="button"
                      version="default"
                      className="hidden border-0 px-4 text-gray-500 focus:border-headerTextColor focus:ring-0 lg:flex"
                      onClick={() => setSidebarOpen(!sidebarOpen)}>
                      <span className="sr-only">Open leftNavColor</span>
                      <Bars3BottomLeftIcon
                        className="h-6 w-6 text-headerTextColor"
                        aria-hidden="true"
                      />
                    </Button>
                  </>
                )}
              </Logo>
              <div className="flex w-full items-center justify-end pr-6">
                <div className="ml-4 flex items-center lg:ml-6">
                  <Menu
                    as="div"
                    className="flex items-center">
                    <Menu.Button className="flex items-center justify-center">
                      <div className="w-22 m-0 hidden h-6 items-center text-sm font-medium text-headerTextColor sm:flex">{user?.name ? user?.name : null}</div>
                      <span className="sr-only">Open user menu</span>
                      <div className="ml-3 h-8 w-8 overflow-hidden rounded-full shadow">{<UserProfile user={user || {}} />}</div>
                    </Menu.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95">
                      <Menu.Items className="absolute right-7 top-12 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <AdminNavDesktop userNavigation={userNavigation.filter((item) => !item.hidden)} />
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
          </div>
          {!Object.keys(workspaceDetails).length ? (
            <div className="relative flex h-full w-full">
              {/* Static leftNav for desktop */}
              <div className={classNames("fixed top-16 z-20 hidden h-[calc(100%-56px)] transition-all duration-150 lg:flex lg:w-64 lg:flex-col ", sidebarOpen && pageList.length > 1 ? "left-0" : "-left-64")}>
                {pageList.length > 0 && (
                  <nav className="flex h-full flex-col justify-between bg-leftNavColor px-2 py-4 shadow-md">
                    <div className="grid gap-y-1">
                      {pageList.map((item) => (
                        <Fragment key={item.key}>
                          <NavItem
                            item={item}
                            activeItem={currentNavigation}
                            onClick={updateNavigation}
                          />
                        </Fragment>
                      ))}
                    </div>
                    {/* <NavigationPreviewBox
                    workspaceDetails={workspaceDetails}
                    rolePreviewModal={rolePreviewModal}
                    removePreviewUser={removePreviewUser}
                    user={user}
                    hasImpersonatedUserId={hasImpersonatedUserId}
                    setRolePreviewModal={setRolePreviewModal}
                  /> */}
                  </nav>
                )}
              </div>
              <div className={classNames("relative z-10 w-full pt-16 transition-all duration-150", sidebarOpen && pageList.length > 1 ? "lg:pl-64" : "lg:pl-0")}>
                <div className="h-full w-full">{children}</div>
              </div>
            </div>
          ) : (
            <>
              {workspaceDetails?._id && workspaceDetails.layout_type === "LEFT_NAVIGATION" && (
                <div className="relative flex h-full w-full">
                  {/* Static leftNav for desktop */}
                  <div className={classNames("fixed  top-16 z-20 hidden h-[calc(100%-56px)] transition-all duration-150 lg:flex lg:w-64 lg:flex-col ", sidebarOpen && pageList.length > 1 ? "left-0" : "-left-64")}>
                    {pageList.length > 1 && (
                      <nav className="flex h-full flex-col justify-between bg-leftNavColor px-2 py-4 shadow-md">
                        <div className="grid gap-y-1">
                          {pageList.map((item) => (
                            <Fragment key={item.key}>
                              {item.permissions.length === 1 ? (
                                <NavItem
                                  item={item.permissions[0]}
                                  activeItem={currentNavigation}
                                  group={item}
                                  onClick={updateNavigation}
                                />
                              ) : item.permissions.length > 0 ? (
                                <div className="relative">
                                  <Button
                                    onClick={() => {
                                      setPageList(
                                        pageList.map((page) => {
                                          if (page.key === item.key) {
                                            return { ...page, toggle: !item.toggle };
                                          } else {
                                            return page;
                                          }
                                        }),
                                      );
                                    }}
                                    type="button"
                                    version="default"
                                    className="flex w-full items-center rounded-md px-2 py-2 text-leftNavTextColor transition-all duration-300">
                                    {item.image && (
                                      <div className="nav-icon mr-3 h-6 w-6 flex-shrink-0">
                                        {isSVGString(item.image) ? (
                                          <div
                                            className=""
                                            dangerouslySetInnerHTML={{ __html: item.image }}
                                          />
                                        ) : (
                                          <Image
                                            image={item.image}
                                            failImage={noFavicon}
                                            alt={"Groups"}
                                          />
                                        )}
                                      </div>
                                    )}
                                    <div className="w-full text-left font-medium transition-all duration-300">{item.name}</div>
                                    <div className="flex h-8 w-4 min-w-[16px] items-center justify-center text-sm">
                                      <ChevronRightIcon className={classNames(!item.toggle ? "" : "rotate-90")} />
                                    </div>
                                  </Button>
                                  <div className={"scrollbar max-h-[400px] space-y-2 overflow-auto pl-3 transition-all duration-300 " + (!item.toggle ? "h-0" : "")}>
                                    {item.permissions.map((permission) => (
                                      <Fragment key={permission.key}>
                                        <NavItem
                                          item={permission}
                                          activeItem={currentNavigation}
                                          onClick={updateNavigation}
                                        />
                                      </Fragment>
                                    ))}
                                  </div>
                                </div>
                              ) : (
                                <NavItem
                                  item={item}
                                  activeItem={currentNavigation}
                                  onClick={updateNavigation}
                                />
                              )}
                            </Fragment>
                          ))}
                        </div>
                        {/* <NavigationPreviewBox
                        workspaceDetails={workspaceDetails}
                        rolePreviewModal={rolePreviewModal}
                        removePreviewUser={removePreviewUser}
                        user={user}
                        hasImpersonatedUserId={hasImpersonatedUserId}
                        setRolePreviewModal={setRolePreviewModal}
                      /> */}
                      </nav>
                    )}
                  </div>
                  <div className={classNames("relative z-10 w-full pt-16 transition-all duration-150", sidebarOpen && pageList.length > 1 ? "lg:pl-64" : "lg:pl-0")}>
                    <div className="h-full w-full bg-backgroundColor">{children}</div>
                  </div>
                </div>
              )}
              {workspaceDetails?._id && workspaceDetails.layout_type === "TOP_NAVIGATION" && (
                <div className="relative z-40 h-screen w-full pt-16">
                  {/* Static leftNav for desktop */}
                  {pageList.length > 1 && (
                    <div className={classNames("relative z-20 flex transition-all duration-150 ", sidebarOpen ? "" : "")}>
                      <nav className="flex h-16 min-h-[64px] w-full items-center justify-between border-t-[2px] border-gray-100 bg-leftNavColor px-2 py-4 shadow-md">
                        <div className="flex gap-x-3">
                          {pageList.map((item) => (
                            <Fragment key={item.key}>
                              {item.permissions.length === 1 ? (
                                <NavItem
                                  item={item.permissions[0]}
                                  activeItem={currentNavigation}
                                  group={item}
                                  onClick={updateNavigation}
                                />
                              ) : item.permissions.length > 0 ? (
                                <div className="custom-drodown relative">
                                  <Button
                                    // onClick={() => {
                                    //   setPageList(
                                    //     pageList.map((page) => {
                                    //       if (page.key === item.key) {
                                    //         return { ...page, toggle: !item.toggle };
                                    //       } else {
                                    //         return { ...page, toggle: false };
                                    //       }
                                    //     })
                                    //   );
                                    // }}
                                    type="button"
                                    version="default"
                                    className={classNames(
                                      "flex w-full items-center gap-x-2 rounded-md px-2 py-2 text-leftNavTextColor transition-all  duration-300 hover:bg-leftNavActiveColor hover:bg-opacity-80 hover:text-leftNavActiveTextColor",
                                      item.key === groupId ? "!bg-leftNavActiveColor !text-leftNavActiveTextColor" : "",
                                    )}>
                                    {item.image && (
                                      <div className="nav-icon mr-1 h-6 w-6 flex-shrink-0">
                                        {isSVGString(item.image) ? (
                                          <div
                                            className=""
                                            dangerouslySetInnerHTML={{ __html: item.image }}
                                          />
                                        ) : (
                                          <Image
                                            image={item.image}
                                            failImage={noFavicon}
                                            alt={"Groups"}
                                          />
                                        )}
                                      </div>
                                    )}
                                    <div className="w-full text-left font-medium transition-all duration-300">{item.name}</div>
                                    <div className="ml-1 flex h-8 w-4 min-w-[16px] items-center justify-center text-sm">
                                      <ChevronRightIcon className="rotate-90 stroke-2" />
                                    </div>
                                  </Button>
                                  <div
                                    className={"top-100 custom-dropdown-content absolute left-0 z-10 max-h-[400px] min-w-[200px] space-y-2 rounded-md border-none bg-leftNavColor shadow-lg transition-all duration-200 " + (!item.toggle ? "h-0 overflow-hidden" : "overflow-auto")}>
                                    <div className="overflow-hidden rounded-md border border-gray-100 px-3 py-3">
                                      {item.permissions.map((permission) => (
                                        <Fragment key={permission.key}>
                                          <NavItem
                                            item={permission}
                                            activeItem={currentNavigation}
                                            onClick={updateNavigation}
                                          />
                                        </Fragment>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <NavItem
                                  item={item}
                                  activeItem={currentNavigation}
                                  onClick={updateNavigation}
                                />
                              )}
                            </Fragment>
                          ))}
                        </div>
                        {/* <NavItem item={{ name: "page data", path: "/pagedata" }} /> */}
                        {/* <NavigationPreviewBox
                        workspaceDetails={workspaceDetails}
                        rolePreviewModal={rolePreviewModal}
                        removePreviewUser={removePreviewUser}
                        user={user}
                        hasImpersonatedUserId={hasImpersonatedUserId}
                        setRolePreviewModal={setRolePreviewModal}
                      /> */}
                      </nav>
                    </div>
                  )}
                  <div className={classNames("relative z-10 h-screen max-h-[calc(100vh-120px)] w-full transition-all duration-100", sidebarOpen ? "" : "")}>
                    <div
                      className="relative flex h-full w-full justify-center bg-white py-0 pl-0 transition-all"
                      id="content">
                      <div className="w-full bg-backgroundColor lg:overflow-hidden lg:rounded-lg lg:border lg:border-gray-100 lg:shadow-lg">{children}</div>
                    </div>
                  </div>
                </div>
              )}
              {workspaceDetails?._id && workspaceDetails.layout_type === "NO_NAVIGATION" && (
                <div className="relative h-screen w-full pt-16">
                  {/* Static leftNav for desktop */}
                  <div className={classNames("relative z-10 h-screen max-h-[calc(100vh-12px)] w-full transition-all duration-100", sidebarOpen ? "" : "")}>
                    <div className="h-full w-full bg-backgroundColor">{children}</div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </FeatureFlagsProvider>
    </UserWorkspaceContext.Provider>
  );
};

const mapStateToProps = (state) => {
  return {
    collapse: state.collapse,
    site: state.site,
    user: state.auth.user,
    workspaceDetails: state.workspaceDetails,
  };
};

export default connect(mapStateToProps, { getMenuData, logout, getDomoPageList, resetWorkspaceDetails })(UserLayout);
