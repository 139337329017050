import { connect } from "react-redux";
import Button from "src/components/Shared/Buttons/Button";
import WorkspacePermissionItem from "./WorkspacePermissionItem";
import { authorizeUserComponentAccess } from "src/helpers/authorizeUserComponentAccess";
import { classNames } from "src/helpers/classNames";
import UserProfile from "src/components/Users/UserProfile";
import Image from "src/components/Shared/Image";
import QuickMenu from "src/components/Shared/QuickMenu";
import { v4 } from "uuid";

const WorkspacePermissionSelectedItem = ({ pageName = "workspace", permissionChanges, workspaceId, onClear = () => {}, savePermissionChanges = () => {}, activeUser = null, setActiveUser = () => {}, setPermissionChanges = () => {}, deleteEditor = () => {}, ...props }) => {
  const permissionDataList = [
    { id: v4(), name: "Pages", code: "page", caption: "Allow management access to pages controls" },
    { id: v4(), name: "Users", code: "user", caption: "Allow management access to users controls" },
    { id: v4(), name: "Groups", code: "group", caption: "Allow management access to groups and view control of pages" },
    { id: v4(), name: "Permissions", code: "permission", caption: "Allow management access to permissions controls" },
    { id: v4(), name: "Settings", code: "settings", caption: "Allow management access to settings controls" },
  ];

  const UserProfileContainer = ({ user }) => {
    return (
      <div className="relative flex w-full items-center gap-x-2">
        <div className="flex h-12 w-12 items-center justify-center overflow-hidden rounded-full">
          <UserProfile user={user || {}} />
        </div>
        <div className="max-w-[150px]">
          <p className="truncate font-semibold text-gray-700">{user?.name}</p>
          <p className="truncate text-xs text-gray-600">{user?.email}</p>
        </div>
      </div>
    );
  };

  return (
    <>
      {(authorizeUserComponentAccess(props?.user, workspaceId, "permission", ["update"]) || authorizeUserComponentAccess(props?.user, workspaceId, "permission", ["editor_update"])) && (
        <div className={classNames("flex w-full justify-end gap-x-4 rounded border transition-all", permissionChanges > 0 ? "mb-3 h-auto border-gray-200 bg-gray-50/50 p-3 py-2 opacity-100" : "h-0 overflow-hidden border-transparent opacity-0")}>
          <Button
            version="gray"
            onClick={onClear}
            disabled={permissionChanges < 1}>
            Undo
          </Button>
          <Button
            onClick={savePermissionChanges}
            disabled={permissionChanges < 1}>
            Save
          </Button>
        </div>
      )}

      <div className="relative flex w-full flex-shrink rounded-lg border border-gray-200 py-2 shadow">
        <div className="relative flex w-full flex-col">
          <div className="relative flex w-full items-center justify-between px-6 py-4">
            {pageName === "user" ? (
              <div className="flex items-center gap-x-2">
                <div className="mr-2 h-10 w-10 items-center justify-center overflow-hidden rounded-full">
                  <Image
                    image={activeUser?.workspace_id?.square_logo || activeUser?.workspace_id?.image_favicon || activeUser?.workspace_id?.image_logo}
                    alt={"User permission tab data item"}
                  />
                </div>
                <p className="truncate font-semibold text-gray-700">{activeUser?.workspace_id?.name}</p>
              </div>
            ) : (
              <>
                <UserProfileContainer user={activeUser?.user_id || {}} />
              </>
            )}
            {pageName === "workspace" && (authorizeUserComponentAccess(props?.user, workspaceId, "permission", ["update"]) || authorizeUserComponentAccess(props?.user, workspaceId, "permission", ["editor_update"])) && (
              <QuickMenu
                position="LEFT"
                items={[
                  {
                    name: "Delete",
                    color: "text-red-500",
                    onClick: () => {
                      deleteEditor(activeUser?.user_id);
                    },
                  },
                ]}
              />
            )}
          </div>
          <div className="h-full space-y-2 border-t border-gray-200 py-2">
            {permissionDataList.map((item, index) => (
              <div
                key={item?.id}
                className="px-2">
                <WorkspacePermissionItem
                  data={item}
                  index={index}
                  activeUser={activeUser}
                  setActiveUser={setActiveUser}
                  setPermissionChanges={setPermissionChanges}
                  disabled={props?.disabled}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    site: state.site,
  };
};
export default connect(mapStateToProps, {})(WorkspacePermissionSelectedItem);
