import {
  GET_PAGE_DATA,
  GET_PAGE_CREDENTIAL_OPTIONS,
  GET_WORKSPACE_DETAILS,
  CLEAR_WORKSPACE_DETAILS,
  // REMOVE_PAGE
} from "src/actions/type";
import { apiRequest } from "src/async/apiUtils";

export const setPageData = (body) => (dispatch) => {
  dispatch({ type: GET_PAGE_DATA, payload: body });
};

export const managePageData = (body) => async (dispatch) => {
  try {
    let method;
    let url;
    if (body?.workspaceId) {
      if (body?.pageId) {
        method = `PUT`;
        url = `/workspaces/:workspace_id/pages/:page_id`;
      } else {
        method = `POST`;
        url = `/workspaces/:workspace_id/pages`;
      }
    } else {
      if (body?.pageId) {
        method = `PUT`;
        url = `/pages/:page_id/manage`;
      } else {
        method = `POST`;
        url = `/pages/manage`;
      }
    }
    const params = { workspace_id: body?.workspaceId, page_id: body?.pageId };
    const { data: resData } = await apiRequest(method, url, { body, params });

    if (resData.status === 200) {
      dispatch({ type: GET_PAGE_DATA, payload: resData.data });
      return { message: resData.message, data: resData.data };
    } else {
      throw new Error(resData.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getPages = (body, signal = null) => {
  return async (dispatch) => {
    try {
      const { data } = await apiRequest("POST", `/pages/list`, { body }, { signal });

      if (data.status === 200) {
        dispatch({ type: GET_PAGE_DATA, payload: data.data });
        return data.data;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };
};

export const removePage = (body) => {
  return async (dispatch) => {
    try {
      let method = "DELETE";
      let url = `/pages/:page_id`;
      if (body?.workspaceId) {
        method = `DELETE`;
        url = `/workspaces/:workspace_id/pages/:page_id`;
      }
      const params = {workspace_id: body.workspaceId, page_id: body?.deleteId};
      const { data } = await apiRequest(method, url, {params});

      if (data.status === 200) {
        // dispatch({ type: REMOVE_PAGE, payload: id });
        return data.message;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };
};

export const getPageCredentialOptions = (params, signal = null) => {
  return async (dispatch) => {
    try {
      const { data: resData } = await apiRequest("GET", `/pages/integration`, null, { signal });

      if (resData.status === 200) {
        dispatch({ type: GET_PAGE_CREDENTIAL_OPTIONS, payload: resData.data });
        return resData.data;
      } else {
        throw new Error(resData.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };
};

export const filterPageCredentialOptions = (data, signal = null) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_PAGE_CREDENTIAL_OPTIONS, payload: data });
    } catch (error) {
      throw new Error(error.message);
    }
  };
};

export const managePageCredentialOptions = (body, signal = null) => {
  return async (dispatch) => {
    try {
      const { data: resData } = await apiRequest("POST", `/pages/credentials/manage`, { body }, { signal });

      if (resData.status === 200) {
        dispatch({ type: GET_PAGE_CREDENTIAL_OPTIONS, payload: resData.data });
        return resData;
      } else {
        throw new Error(resData.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };
};

export const managePageIntegrationOptions = (body, signal = null) => {
  return async (dispatch) => {
    try {
      const { data: resData } = await apiRequest("POST", "/pages/integration/manage", { body }, { signal });

      if (resData.status === 200) {
        return resData;
      } else {
        throw new Error(resData.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };
};

export const deletePageCredentialOptions = (body, signal = null) => {
  return async (dispatch) => {
    try {
      let params = { workspace_id: body.workspace_id, credential_id: body.credential_id };
      await apiRequest("DELETE", "/workspaces/:workspace_id/pages/credentials/:credential_id", {params}, { signal });
    } catch (error) {
      throw new Error(error.message);
    }
  };
};

export const getDomoWorkspaceList = async (body) => {
  try {
    const { data } = await apiRequest("POST", `/portal/workspaces/list`, { body });
    return data.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getDomoWorkspaceFirstPage = async (body) => {
  try {
    let params = { workspace_id: body.clicked_id, user_id: body.user_id };
    const { data } = await apiRequest("GET", "/portal/:workspace_id/:user_id/page", {params});
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

// export const getDomoPageList = async (body) => {
//   try {
//     const {data} = await apiRequest("POST", `/portal/pages/list`, {body});
//     return data;
//   } catch (error) {
//     throw new Error(error.message);
//   }
// };

export const getDomoPageList = (body) => async (dispatch) => {
  try {
    const { data: resData } = await apiRequest("POST", `/portal/pages/list`, { body });

    if (resData.status === 200) {
      dispatch({ type: GET_WORKSPACE_DETAILS, payload: resData.data });
      return resData;
    } else {
      throw new Error(resData.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const resetWorkspaceDetails = (body) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_WORKSPACE_DETAILS, payload: {} });
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getDomoPageDetails = async (body, signal = null) => {
  try {
    let url = `embed/link/:workspace_slug/:page_slug/:user_id`;

    if (body.groupId) {
      url = `embed/items/:workspace_slug/:group_slug/:page_slug/:user_id`;
    }
    const params = {workspace_slug: body.workspaceId, group_slug: body.groupId, page_slug: body.pageId, user_id: body.userId}
    const { data } = await apiRequest("GET", `/${url}`, {params}, { signal });

    if (data.status === 200) {
      return data;
    } else {
      return data;
    }
  } catch (error) {
    return { status: 400, message: error.message };
    // throw new Error(error.message);
  }
};

export const getPreviousPageDetails = async (body) => {
  try {
    let url = `/previous/page/details`;
    if (body?.workspaceId) {
      url = `/workspaces/:workspace_id/previous/page/details`;
    }
    const params = {workspace_id: body?.workspaceId}
    const { data } = await apiRequest("POST", url, { body, params });

    if (data.status === 200) {
      return data;
    } else {
      return data;
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const updateDomoPage = async (body) => {
  try {
    const params = {workspace_id: body.workspace_id};
    const { data } = await apiRequest("PUT", `/workspaces/:workspace_id/pages/user-group-assign`, { body, params });

    if (data.status === 200) {
      return data;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};
