import React from 'react';
import Search from "src/components/Shared/Search";
import Button from "src/components/Shared/Buttons/Button";
import { classNames } from 'src/helpers/classNames';
import Preloader from 'src/components/Shared/LoadingAnimations/Preloader';
import NoData from 'src/components/Shared/NoData/NoData';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

// Header component
const Header = ({
  searchEnabled = false,
  keyword = '',
  onKeywordChange = () => { },
  addButtonEnabled = false,
  addButtonText = '',
  onAddButtonClick = () => { }
}) => (
  <div className="w-full flex gap-x-2 mb-4">
    {searchEnabled && (
      <div className="w-full flex-1">
        <Search keyword={keyword} setKeyword={onKeywordChange} />
      </div>
    )}
    {addButtonEnabled && (
      <Button version="secondary" onClick={onAddButtonClick}>
        <div>{addButtonText}</div>
      </Button>
    )}
  </div>
);

// List component
const List = ({
  listData = [],
  listDraggableEnabled = false,
  onDragEnd = () => { },
  listComponent = () => { },
  listLoaded = false
}) => (
  listLoaded ? (
    listData.length > 0 ? (
      listDraggableEnabled ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                className="space-y-4"
                {...provided.droppableProps}
                ref={provided.innerRef}>
                {listData.map((item, index) => (
                  <Draggable
                    key={item._id || item.id}
                    draggableId={item._id || item.id}
                    index={index}>
                    {(provided, snapshot) => (
                      listComponent({
                        item,
                        innerRef: provided.innerRef,
                        draggableProps: provided.draggableProps,
                        dragHandleProps: provided.dragHandleProps
                      })
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <div className="space-y-4">
          {listData.map((item, index) => (
            <div key={item._id || item.id}>
              {listComponent({ item })}
            </div>
          ))}
        </div>
      )
    ) : (
      <NoData />
    )
  ) : (
    <div className={classNames("h-20 transition-all duration-300", "opacity-100")}>
      <Preloader className="h-[80px]" circleDimension="10" size="base" />
    </div>
  )
);

// Selected content component
const SelectedContent = ({
  listLoaded = false,
  selectedData = null,
  selectedComponent = () => { }
}) => (
  listLoaded ? (
    selectedData ? (
      selectedComponent()
    ) : (
      <div className="relative flex flex-col shadow border border-gray-200 rounded-md max-w-full lg:min-h-[400px]">
        <div className='flex items-center justify-between py-4 px-6 gap-10'></div>
        <div className='relative flex-grow h-full py-3 sm:py-4 px-3 sm:px-6 border-t border-gray-200'>
          <NoData />
        </div>
      </div>
    )
  ) : (
    <div className="relative flex flex-col shadow border border-gray-200 rounded-md max-w-full lg:min-h-[400px]">
      <div className='flex items-center justify-between py-4 px-6 gap-10'></div>
      <div className='relative flex-grow h-full py-3 sm:py-4 px-3 sm:px-6 border-t border-gray-200'>
        <Preloader />
      </div>
    </div>
  )
);

// MultiColumnContent component
const MultiColumnContent = ({
  headerContentConfig = {},
  listContentConfig = {},
  selectedContentConfig = {}
}) => (
  <div className="relative grid gap-y-4">
    <div className="flex gap-x-5">
      <div>
        {headerContentConfig?.headerEnabled && (
          <div className="w-full"><Header {...headerContentConfig} /></div>
        )}
        <div className="py-4 px-3 rounded-lg border-2 border-gray-300 border-dashed w-[430px]">
          <List {...listContentConfig} />
        </div>
      </div>
      <div className="w-full">
        <SelectedContent {...selectedContentConfig} listLoaded={listContentConfig?.listLoaded || false} />
      </div>
    </div>
  </div>
);

export default MultiColumnContent;