import { ClipboardDocumentCheckIcon, FunnelIcon, RectangleStackIcon, UserIcon } from "@heroicons/react/24/outline";
import { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { getUserDetails } from "src/actions/user";
import { apiRequest } from "src/async/apiUtils";
import BackIndicator from "src/components/Navigation/BackIndicator";
import Tabs from "src/components/Shared/Tabs/Tabs";
import { H1 } from "src/components/Shared/Text/Headers";
import GlobalFiltersList from "src/components/Users/Edit/GlobalFilters/GlobalFiltersList";
import UserPermissions from "src/components/Users/Edit/Permissions/UserPermissions";
import UserProfileDetailsEdit from "src/components/Users/Edit/UserProfileDetailsEdit";
import UserWorkspaceList from "src/components/Users/Edit/Workspaces/UserWorkspaceList";
import UserProfile from "src/components/Users/UserProfile";
import { userWorkspacesAndAssociatedPermissions } from "src/actions/workspace";

const UserEdit = ({ me, ...props }) => {
  const navigate = useNavigate();

  const { id } = useParams();
  const [user, setUser] = useState({});
  const [workspaces, setWorkspaces] = useState([]);
  // const [workspaceGroupsLoaded, setWorkspaceGroupsLoaded] = useState(false);
  const [workspaceGroups, setWorkspaceGroups] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [workspaceEditorList, setWorkspaceEditorList] = useState([]);
  const [loaded, setLoaded] = useState(false);

  // useLoadData("post", "/users/details", { body: { id: id ?? null } }, (res) => setUser(res.data));

  const fetchUser = async (_id) => {
    setUser({});
    try {
      const { data: user } = await apiRequest("post", `/users/details`, { body: { id: _id ?? null } });
      setUser(user.data);
    } catch (error) {
      // console.dir("ERROR:", error);
    }
  };

  useEffect(() => {
    fetchUser(id);
  }, [id]);

  useEffect(() => {
    if (id) {
      const ac = new AbortController();
      const loadData = async () => {
        try {
          const workspace = await props.userWorkspacesAndAssociatedPermissions({ user_id: id }, ac.signal);
          setWorkspaceEditorList(workspace);
        } catch (error) {
          // toast.error(error.message);
        } finally {
          setLoaded(true);
        }
      };
      loadData();
      return () => ac.abort();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const accessableTab = (accessObj, key) => {
    if (accessObj && Object.keys(accessObj).length > 0) {
      const updatedObjs = Object.values(accessObj);
      for (const updatedObj of updatedObjs) {
        const createKey = key + "_update";
        const updateKey = key + "_editor_create";
        const deleteKey = key + "_editor_update";
        if (updatedObj[createKey] || updatedObj[updateKey] || updatedObj[deleteKey]) return true;
      }
      return false;
    }
    return false;
  };

  // Load workspaces
  useEffect(() => {
    const loadWorkspaces = async () => {
      const res = await apiRequest("post", "/workspaces/list", {
        body: { includes: ["groups", "users", "pages"] },
      });

      const data = res.data;

      if (data.status === 200) {
        setWorkspaces(
          data.data.map((item) => ({
            ...item,
            selected: user.workspaceIds.includes(item?._id),
          }))
        );
      }
    };

    if (user?._id) {
      const permissions = accessableTab(me?.userPermissions, "permission");
      setTabs([
        {
          name: "View Profile",
          icon: UserIcon,
          component: (
            <UserProfileDetailsEdit
              workspaces={workspaces}
              groups={workspaceGroups}
              setWorkspaces={setWorkspaces}
              setGroups={setWorkspaceGroups}
              fetchUser={fetchUser}
            />
          ),
          visible: true,
        },
        { name: "Workspaces", icon: RectangleStackIcon, component: <UserWorkspaceList userDetails={user} />, visible: true },
        { name: "Editors", icon: ClipboardDocumentCheckIcon, component: <UserPermissions loaded={loaded} workspaceList={workspaceEditorList} />, visible: (user?.type === "admin" && me?.type !== "admin") || (me?.type === "editor" && !permissions) || (me?.type === "editor" && workspaceEditorList.length  === 0) ? false : true },
        {
          name: "Data Access",
          icon: FunnelIcon,
          component: <GlobalFiltersList />,
          visible: (user?.type === "admin" && me?.type !== "admin") || me?.type === "editor" ? false : true,
        },
        // { name: "Settings", icon: Cog8ToothIcon, component: <div>Hello world</div> },
      ]);
      loadWorkspaces();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?._id, me?._id, loaded, workspaceEditorList]);

  const redirectToWorkspace = () => {
    if (me?.type === "editor" && Array.isArray(me?.workspaces) && me.workspaces.length === 1) {
      navigate(`/workspaces/${me.workspaces[0]._id}?tab=Users`);
    } else {
      navigate("/" + window.location.pathname.split("/")[1]);
    }
  };

  return (
    <>
      <H1 item={{ icon: BackIndicator, onClick: me?.type === "editor" ? redirectToWorkspace : null }}>
        <p className="text-gray-300 font-light capitalize">{user?.type === "admin" ? "Admins" : "Users"} /</p>
        <div className="h-10 w-10 rounded-full overflow-hidden border border-gray-100">
          {useCallback(
            () => (
              <UserProfile
                user={user}
                size="sm"
              />
            ),

            // eslint-disable-next-line react-hooks/exhaustive-deps
            [user?._id]
          )()}
        </div>
        {user?.name}
      </H1>
      <Tabs tabs={tabs.filter((tab) => tab.visible)}></Tabs>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.userDetails,
    site: state.site,
    me: state.auth.user,
  };
};

export default connect(mapStateToProps, { getUserDetails, userWorkspacesAndAssociatedPermissions })(UserEdit);
