import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { noLogo } from "src/config/host";

const Logo = ({ children, ...props }) => {
  const [visibleLogo, setVisibleLogo] = useState(null);
  useEffect(() => {
    let logoExists = Object.keys(props?.workspaceDetails).length && (props?.me?.type === "user" || (props?.me?.type === "editor" && localStorage.getItem("impersonatedUserId"))) ? (props?.workspaceDetails?.image_logo ? props?.workspaceDetails?.image_logo + "?" + Date.now() : null) : props.site?.image_logo + "?" + Date.now();
    if (logoExists) {
      setVisibleLogo(logoExists);
    }
  }, [props?.workspaceDetails, props?.me, props.site]);

  return (
    <div className="flex items-center h-[56px] flex-shrink-0">
      {children}
      {visibleLogo && (
        <div className="transition-all duration-75 min-w-[130px]">
          <Link to="/workspaces">
            <img
              className="h-10 sm:h-12 w-auto"
              src={visibleLogo}
              alt="Site logo"
              onError={(e) => (e.target.src = `${noLogo}`)}
            />
          </Link>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
    workspaceDetails: state.workspaceDetails,
    me: state.auth.user,
  };
};

export default connect(mapStateToProps, null)(Logo);
