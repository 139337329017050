import { PlusIcon } from "@heroicons/react/24/outline";
import { ArrowPathIcon } from "@heroicons/react/24/solid";
import { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { manageSiteSettings } from "src/actions/site";
import Button from "src/components/Shared/Buttons/Button";
import Section from "src/components/Shared/Containers/Section";
import Input from "src/components/Shared/Forms/Inputs/Input";
import Toggle from "src/components/Shared/Forms/Toggles/Toggle";
import ToggleHeader from "src/components/Shared/Forms/Toggles/ToggleHeader";
import { UserCustomFieldContext } from "src/context/UserCustomFieldContext";
import Preloader from "src/components/Shared/LoadingAnimations/Preloader";
import NoneFound from "src/components/Shared/NoData/NoneFound";
import UserCustomFieldItem from "src/components/Users/UserCustomFieldItem";
import { apiRequest } from "src/async/apiUtils";
import { v4 } from "uuid";

const BetaHeader = ({ title, horizontalRow = true, isBeta = true }) => {
  return (
    <div className="grid gap-y-4">
      {horizontalRow && <hr className="mb-5" />}
      <div className="mb-4 flex w-full items-center justify-between">
        <h3 className="pb-1 text-xl font-semibold text-gray-500">
          {title} <span className="font-normal text-gray-300">{isBeta && "(BETA)"}</span>
        </h3>
      </div>
    </div>
  );
};

const PrivacyAndSecurity = ({ site, me = {}, manageSiteSettings }) => {
  const [userDeactivationEnabled, setUserDeactivationEnabled] = useState(false);
  const [userDeactivationTimeframe, setUserDeactivationTimeframe] = useState("");
  const [userDeletionEnabled, setUserDeletionEnabled] = useState(false);
  const [userDeletionTimeframe, setUserDeletionTimeframe] = useState("");
  const [userInactivityLogoutEnabled, setUserInactivityLogoutEnabled] = useState(site?.user_inactivity_logout_enabled || false);
  const [userInactivityLogoutTimeframe, setUserInactivityLogoutTimeframe] = useState(site?.user_inactivity_logout_timeframe || "");
  const [twoFactorEnabled, setTwoFactorEnabled] = useState(site?.two_factor_enabled || false);
  const [twoFactorTimeframe, setTwoFactorTimeframe] = useState(site?.two_factor_timeframe || "");
  const [isLoading, setIsLoading] = useState(false);
  const [onChangeDisable, setOnChangeDisable] = useState(true);

  const [customFieldsLoading, setCustomFieldsLoading] = useState(true);
  const [disableCustomField, setDisableCustomField] = useState(false);
  const [initCustomFields, setInitCustomFields] = useState([]);
  const [customFields, setCustomFields] = useState([]);

  const [isCustomFieldsUpdated, setIsCustomFieldsUpdated] = useState(false);

  const setPageData = (site) => {
    if (site?._id) {
      setUserDeactivationEnabled(site.user_deactivation_enabled || false);
      setUserDeactivationTimeframe(site.user_deactivation_timeframe || "");
      setUserDeletionEnabled(site.user_deletion_enabled || false);
      setUserDeletionTimeframe(site.user_deletion_timeframe || "");
      setUserInactivityLogoutEnabled(site.user_inactivity_logout_enabled || false);
      setUserInactivityLogoutTimeframe(site.user_inactivity_logout_timeframe || "");
      setTwoFactorEnabled(site.two_factor_enabled || false);
      setTwoFactorTimeframe(site.two_factor_timeframe || "");
    }
  };

  useEffect(() => {
    setPageData(site);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site]);

  const loadCustomFields = useCallback(() => {
    (async () => {
      setCustomFieldsLoading(true);

      try {
        const { data: resData } = await apiRequest("GET", `/users/custom-fields`);

        if (resData.status === 200) {
          setInitCustomFields(
            resData.data.map((item) =>
              !item.default_value
                ? item
                : {
                    ...item,
                    allow_default_value: true,
                  },
            ),
          );

          setCustomFields(
            resData.data.map((item) =>
              !item.default_value
                ? item
                : {
                    ...item,
                    allow_default_value: true,
                  },
            ),
          );
        }
      } catch (err) {
      } finally {
        setCustomFieldsLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    loadCustomFields();
  }, [loadCustomFields]);

  const addCustomField = () => {
    setCustomFields((fields) => [
      ...fields,
      {
        id: v4(),
        label: "",
        is_required: false,
        allow_default_value: false,
        default_value: "",
      },
    ]);
  };

  const onCustomFieldUpdate = async () => {
    setDisableCustomField(true);

    try {
      const { data: resData } = await apiRequest("PUT", `/users/custom-fields`, {
        body: {
          fields: customFields.map((item) => ({
            ...item,
            default_value: !item.allow_default_value ? null : item.default_value,
          })),
        },
      });

      if (resData.status === 200) {
        toast.success(resData.message);

        setIsCustomFieldsUpdated(false);

        if (resData.data) {
          setInitCustomFields(
            resData.data.map((item) =>
              !item.default_value
                ? {
                    ...item,
                    allow_default_value: false,
                  }
                : {
                    ...item,
                    allow_default_value: true,
                  },
            ),
          );
          setCustomFields(
            resData.data.map((item) =>
              !item.default_value
                ? {
                    ...item,
                    allow_default_value: false,
                  }
                : {
                    ...item,
                    allow_default_value: true,
                  },
            ),
          );
        } else {
          setInitCustomFields([]);
          setCustomFields([]);
        }
      } else {
        toast.error(resData.message);
      }
    } catch (err) {
      toast.error(err.message);
    } finally {
      setDisableCustomField(false);
    }
  };

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      const message = await manageSiteSettings({
        user_deactivation_enabled: userDeactivationEnabled,
        user_deactivation_timeframe: userDeactivationTimeframe,
        user_deletion_enabled: userDeletionEnabled,
        user_deletion_timeframe: userDeletionTimeframe,
        user_inactivity_logout_enabled: userInactivityLogoutEnabled,
        user_inactivity_logout_timeframe: userInactivityLogoutTimeframe,
        two_factor_enabled: twoFactorEnabled,
        two_factor_timeframe: twoFactorTimeframe,
      });

      toast.success(message);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
      setOnChangeDisable(true);
    }
  };

  return (
    <Section>
      <div className="grid w-full gap-y-10">
        <div className="relative mb-5 w-full space-y-12">
          {me?.type === "admin" && (
            <UserCustomFieldContext.Provider value={{ fields: customFields, setFields: setCustomFields }}>
              <div className="mt-6 w-full">
                <div className="flex items-center justify-between">
                  <BetaHeader
                    title="Add Custom Fields"
                    horizontalRow={false}
                    isBeta={false}
                  />
                  <Button
                    version="gray"
                    disabled={disableCustomField || customFieldsLoading}
                    onClick={() => {
                      addCustomField();
                      setIsCustomFieldsUpdated(true);
                    }}>
                    <PlusIcon className="h-5 w-5" />
                    Add field
                  </Button>
                </div>

                {customFieldsLoading ? (
                  <Preloader />
                ) : customFields.length > 0 ? (
                  <div className="mt-2 rounded-md border border-gray-200 px-4 py-2">
                    {customFields.map((field, i) => (
                      <UserCustomFieldItem
                        key={field?._id || field?.id}
                        index={i}
                        onChange={() => setIsCustomFieldsUpdated(true)}
                      />
                    ))}
                  </div>
                ) : (
                  <NoneFound />
                )}
                {isCustomFieldsUpdated && !customFieldsLoading && (
                  <div className="mt-3 flex items-center justify-end gap-x-2">
                    <Button
                      version="secondary"
                      onClick={() => {
                        setCustomFields(initCustomFields);
                        setIsCustomFieldsUpdated(false);
                      }}
                      disabled={disableCustomField}>
                      Cancel
                    </Button>
                    <Button
                      onClick={onCustomFieldUpdate}
                      disabled={disableCustomField}>
                      Update
                    </Button>
                  </div>
                )}
              </div>
            </UserCustomFieldContext.Provider>
          )}
        </div>
        <div>
          <BetaHeader title="Inactivity Manager" />
          <div className="flex flex-col gap-y-12">
            <div className="relative w-full">
              <ToggleHeader
                title="Inactivity auto-logout"
                subtitle="Define how long a user may be inactive.">
                <Toggle
                  checked={userInactivityLogoutEnabled}
                  onChange={(e) => {
                    setOnChangeDisable(false);
                    setUserInactivityLogoutEnabled(e);
                  }}
                />
              </ToggleHeader>
              <div className="flex w-full items-center gap-x-5 gap-y-3 px-8 font-light">
                <p>Log a user out after</p>
                <div className="w-[70px]">
                  <Input
                    name="auto-logout"
                    disabled={!userInactivityLogoutEnabled}
                    value={userInactivityLogoutTimeframe}
                    onChange={(e) => {
                      setOnChangeDisable(false);
                      setUserInactivityLogoutTimeframe(e.target.value.replace(/[^0-9]/g, ""));
                    }}
                  />
                </div>
                <p>minutes spent inactive.</p>
              </div>
            </div>
            <div className="relative w-full">
              <ToggleHeader
                title="Automatic User Deactivation"
                subtitle="(Excluding admins)">
                <Toggle
                  checked={userDeactivationEnabled}
                  onChange={(e) => {
                    setOnChangeDisable(false);
                    setUserDeactivationEnabled(e);
                  }}
                />
              </ToggleHeader>
              <div className="flex w-full items-center gap-x-5 gap-y-3 px-8 font-light">
                <p>
                  <span className="font-semibold">DEACTIVATE</span> users after
                </p>
                <div className="w-[70px]">
                  <Input
                    name="user-deactivation"
                    disabled={!userDeactivationEnabled}
                    value={userDeactivationTimeframe}
                    onChange={(e) => {
                      setOnChangeDisable(false);
                      setUserDeactivationTimeframe(e.target.value.replace(/[^0-9]/g, ""));
                    }}
                  />
                </div>
                <p>days without a login.</p>
              </div>
            </div>

            <div className="relative w-full">
              <ToggleHeader
                title="Automatic User Deletion"
                subtitle="(Excluding admins)">
                <Toggle
                  checked={userDeletionEnabled}
                  onChange={(e) => {
                    setOnChangeDisable(false);
                    setUserDeletionEnabled(e);
                  }}
                />
              </ToggleHeader>
              <div className="flex w-full items-center gap-x-5 gap-y-3 px-8 font-light">
                <p>
                  Permanently <span className="font-semibold">DELETE</span> users after
                </p>
                <div className="w-[70px]">
                  <Input
                    name="user-deletion"
                    disabled={!userDeletionEnabled}
                    value={userDeletionTimeframe}
                    onChange={(e) => {
                      setOnChangeDisable(false);
                      setUserDeletionTimeframe(e.target.value.replace(/[^0-9]/g, ""));
                    }}
                  />
                </div>
                <p>days without a login.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="relative mb-5 w-full space-y-12">
          <div className="mt-6 w-full">
            <BetaHeader title="2FA-protected" />
            <div className="relative w-full">
              <ToggleHeader
                title="Require email accounts to be 2FA"
                subtitle="Set if devices can be remembered and for how long.">
                <Toggle
                  checked={twoFactorEnabled}
                  onChange={(e) => {
                    setOnChangeDisable(false);
                    setTwoFactorEnabled(e);
                  }}
                />
              </ToggleHeader>
              <div className="flex w-full items-center gap-x-5 gap-y-3 px-8 font-light">
                <p>2FA is required after</p>
                <div className="w-[70px]">
                  <Input
                    name="2fa-require"
                    disabled={!twoFactorEnabled}
                    value={twoFactorTimeframe}
                    onChange={(e) => {
                      setOnChangeDisable(false);
                      setTwoFactorTimeframe(e.target.value.replace(/[^0-9]/g, ""));
                    }}
                  />
                </div>
                <p>days without a login.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex w-full justify-end gap-x-4">
        <Button
          disabled={isLoading || onChangeDisable}
          onClick={onSubmit}>
          Save{isLoading && <ArrowPathIcon className="ml-1 h-5 w-5 animate-spin" />}
        </Button>
      </div>
    </Section>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
    me: state.auth.user,
  };
};

export default connect(mapStateToProps, { manageSiteSettings })(PrivacyAndSecurity);
