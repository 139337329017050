import { Navigate, Route, Routes } from "react-router-dom";
import { FeatureFlagRoute } from "src/components/FeatureFlags/FeatureFlagRoute";
import Activity from "src/pages/activity";
import Admins from "src/pages/admins";
import Login from "src/pages/auth/login";
import Companies from "src/pages/companies";
import ResetPassword from "src/pages/forgot-password/reset-password";
import ForgotPassword from "src/pages/forgot-password/submit-email";
import WelcomePassword from "src/pages/forgot-password/welcome-password";
import Groups from "src/pages/groups";
import Pages from "src/pages/pages";
import PortalWorkspace from "src/pages/portal/workspace";
import PortalWorkspaces from "src/pages/portal/workspaces";
import RouteNotFound404 from "src/pages/route-not-found-404";
import SiteSettings from "src/pages/site-settings";
import SSO from "src/pages/sso/sso";
import SSOUpsert from "src/pages/sso/upsert";
import TechnicalIssues from "src/pages/technical-issues";
import UserAdd from "src/pages/users/add";
import UserEdit from "src/pages/users/edit";
import Users from "src/pages/users/index";
import ChangePassword from "src/pages/users/me/change-password";
import WorkspaceEdit from "src/pages/workspaces/edit";
import Workspaces from "src/pages/workspaces/workspaces";
import FreeAuthRoute from "src/routes/FreeAuthRoute";
import ProtectedRoute from "src/routes/ProtectedRoute";
import SSORedirectManageRoute from "src/routes/SSORedirectManageRoute";

import Users2 from "src/pages/users/users2";
import SsoLogout from "src/pages/auth/sso-logout";
import LogoutCallback from "src/pages/auth/logout-callback";
import EmbedWorkspace from "src/pages/portal/EmbedWorkspace";
import Phoenix from "src/pages/phoenix";

// import QlikDemo from "src/pages/qlik-demo";
// import Emailer from "src/pages/emailer";

//Router
export default (
  <Routes>
    <Route
      path="/"
      element={
        <SSORedirectManageRoute>
          <FreeAuthRoute>
            <Login />
          </FreeAuthRoute>
        </SSORedirectManageRoute>
      }
    />
    <Route
      path="/login"
      element={
        <SSORedirectManageRoute>
          <FreeAuthRoute>
            <Login />
          </FreeAuthRoute>
        </SSORedirectManageRoute>
      }
    />
    <Route
      path="/logout"
      element={
        <FreeAuthRoute>
          <SsoLogout />
        </FreeAuthRoute>
      }
    />
    {/* FORGOT PASSWORD ROUTES */}
    <Route
      path="/forgot-password/submit-email"
      element={
        <FreeAuthRoute>
          <ForgotPassword />
        </FreeAuthRoute>
      }
    />
    <Route
      path="/forgot-password/reset-password/:token"
      element={
        <FreeAuthRoute>
          <ResetPassword />
        </FreeAuthRoute>
      }
    />

    <Route
      path="/token/:token"
      element={
        <FreeAuthRoute>
          <WelcomePassword />
        </FreeAuthRoute>
      }
    />
    <Route
      path="/phoenix"
      element={
        // <ProtectedRoute roles={["super", "admin", "editor"]}>
        <ProtectedRoute roles={["admin"]}>
          <Phoenix />
        </ProtectedRoute>
      }
    />
    <Route
      path="/logout/callback"
      element={
        <ProtectedRoute roles={["super", "admin", "editor"]}>
          <LogoutCallback />
        </ProtectedRoute>
      }
    />
    {/* COMPANIES ROUTES */}
    <Route
      path="/companies"
      element={
        <ProtectedRoute roles={["super", "admin"]}>
          <Companies />
        </ProtectedRoute>
      }
    />
    <Route
      path="/users2"
      element={
        // <ProtectedRoute roles={["super", "admin", "editor"]}>
        <ProtectedRoute roles={["admin"]}>
          <Users2 />
        </ProtectedRoute>
      }
    />
    {/* WORKSPACE ROUTES */}
    <Route
      path="/workspaces"
      element={
        <ProtectedRoute roles={["super", "admin", "editor"]}>
          <Workspaces />
        </ProtectedRoute>
      }
    />
    <Route
      path="/workspaces/:id"
      element={
        <ProtectedRoute roles={["super", "admin", "editor"]}>
          <WorkspaceEdit />
        </ProtectedRoute>
      }
    />
    <Route
      path="/workspaces/menu"
      element={
        <ProtectedRoute roles={["user", "editor"]}>
          <PortalWorkspaces />
        </ProtectedRoute>
      }
    />
    <Route
      path="/workspace/:workspaceId/group/:groupId/page/:pageId"
      element={
        <ProtectedRoute roles={["user", "editor"]}>
          <PortalWorkspace />
        </ProtectedRoute>
      }
    />
    <Route
      path="/workspace/:workspaceId/page/:pageId"
      element={
        <ProtectedRoute roles={["user", "editor"]}>
          <PortalWorkspace />
        </ProtectedRoute>
      }
    />
    <Route
      path="/workspace/:workspaceId/embed"
      element={
        <ProtectedRoute roles={["user", "editor"]}>
          <PortalWorkspace />
        </ProtectedRoute>
      }
    />
    <Route
      path="/embed/items/:workspaceId/:pageId"
      element={
        <ProtectedRoute roles={["user", "editor"]}>
          <EmbedWorkspace />
        </ProtectedRoute>
      }
    />

    {/* PAGE ROUTES */}
    <Route
      path="/pages"
      element={
        <ProtectedRoute roles={["super", "admin", "editor"]}>
          <Pages />
        </ProtectedRoute>
      }
    />

    {/* USER ROUTES */}
    <Route
      path="/users"
      element={
        <ProtectedRoute roles={["super", "admin", "editor"]}>
          <Users />
        </ProtectedRoute>
      }
    />
    <Route
      path="/users/add"
      element={
        <ProtectedRoute roles={["super", "admin", "editor"]}>
          <UserAdd />
        </ProtectedRoute>
      }
    />
    <Route
      path="/users/:id"
      element={
        <ProtectedRoute roles={["super", "admin", "editor"]}>
          <UserEdit />
        </ProtectedRoute>
      }
    />
    <Route
      path="/users/edit/me"
      element={
        <ProtectedRoute>
          <UserEdit />
        </ProtectedRoute>
      }
    />
    <Route
      path="/users/edit/me/change-password"
      element={
        <ProtectedRoute>
          <ChangePassword />
        </ProtectedRoute>
      }
    />

    {/* ADMIN ROUTES */}
    <Route
      path="/admins"
      element={
        <ProtectedRoute roles={["super", "admin"]}>
          <Admins />
        </ProtectedRoute>
      }
    />
    <Route
      path="/admins/:id"
      element={
        <ProtectedRoute roles={["super", "admin"]}>
          <UserEdit />
        </ProtectedRoute>
      }
    />
    <Route
      path="/admins/add"
      element={
        <ProtectedRoute roles={["super", "admin"]}>
          <UserAdd />
        </ProtectedRoute>
      }
    />

    {/* GROUP ROUTES */}
    <Route
      path="/groups"
      element={
        <ProtectedRoute roles={["super", "admin", "editor"]}>
          <Groups />
        </ProtectedRoute>
      }
    />

    {/* SSO ROUTES */}
    <Route
      path="/sso"
      element={
        <ProtectedRoute roles={["super", "admin"]}>
          <FeatureFlagRoute feature={"SSO"}>
            <SSO />
          </FeatureFlagRoute>
        </ProtectedRoute>
      }
    />
    <Route
      path="/sso/add"
      element={
        <ProtectedRoute roles={["super", "admin"]}>
          <FeatureFlagRoute feature={"SSO"}>
            <SSOUpsert />
          </FeatureFlagRoute>
        </ProtectedRoute>
      }
    />
    <Route
      path="/sso/edit/:id"
      element={
        <ProtectedRoute roles={["super", "admin"]}>
          <FeatureFlagRoute feature={"SSO"}>
            <SSOUpsert />
          </FeatureFlagRoute>
        </ProtectedRoute>
      }
    />

    {/* ACTIVITY ROUTES */}
    <Route
      path="/activity"
      element={
        <ProtectedRoute roles={["super", "admin"]}>
          <FeatureFlagRoute feature="Activity">
            <Activity />
          </FeatureFlagRoute>
        </ProtectedRoute>
      }
    />

    {/* SETTINGS ROUTES */}
    <Route
      path="/site-settings"
      element={
        <ProtectedRoute roles={["super", "admin"]}>
          <SiteSettings />
        </ProtectedRoute>
      }
    />

    {/* <Route
      path="/qlik-demo"
      element={
        <ProtectedRoute>
          <QlikDemo />
        </ProtectedRoute>
      }
    /> */}

    {/* <Route
      path="/dataset-users"
      element={
        <ProtectedRoute roles={["super", "admin"]}>
          <DatasetUsers portal={true} />
        </ProtectedRoute>
      }
    /> */}

    {/* <Route
      path="/emailer"
      element={
        <ProtectedRoute roles={["super", "admin"]}>
          <Emailer />
        </ProtectedRoute>
      }
    /> */}

    <Route
      path="/technical-issues"
      element={
        <FreeAuthRoute>
          <TechnicalIssues />
        </FreeAuthRoute>
      }
    />

    <Route
      path="/404"
      element={<RouteNotFound404 />}
    />
    <Route
      path="*"
      element={
        <Navigate
          replace
          to="/404"
        />
      }
    />
  </Routes>
);
