import WorkspaceIntegrationClients from "src/components/Workspaces/Workspace/Settings/WorkspaceIntegration/WorkspaceIntegrationClients";

const WorkspaceIntegrationItem = ({ is_global = false, workspaceId = "", integration = {}, workspaceDetails = {}, editCredential = () => {}, openCreatedByModal = () => {}, removeCredential = () => {}, makeDefaultButtonDisabled = false, setMakeDefaultButtonDisabled = () => {}, refreshIntegrations = () => {} }) => {
  return (
    <>
      <div className="flex mb-1">
        <div className="border border-b-0 rounded-t-md flex px-2 py-1">
          <div className="w-7 h-7 overflow-hidden rounded flex-shrink-0">
            <div
              className=""
              dangerouslySetInnerHTML={{ __html: integration.image }}
            />
          </div>
          <div className="w-full px-4 flex-shrink">
            <div className="text-gray-400 font-semibold text-lg">{integration.name}</div>
          </div>
        </div>
        <div className="w-full border-b"></div>
      </div>
      <WorkspaceIntegrationClients
        integration={integration}
        workspaceId={workspaceId}
        workspaceDetails={workspaceDetails}
        is_global={is_global}
        editCredential={editCredential}
        openCreatedByModal={openCreatedByModal}
        removeCredential={removeCredential}
        makeDefaultButtonDisabled={makeDefaultButtonDisabled}
        setMakeDefaultButtonDisabled={setMakeDefaultButtonDisabled}
        refreshIntegrations={refreshIntegrations}
      />
    </>
  );
};

export default WorkspaceIntegrationItem;
