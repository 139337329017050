import { Menu, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { classNames } from "src/helpers/classNames";
import Checkbox from "src/components/Shared/Forms/Inputs/Checkbox";
import { isSVGString } from "src/helpers";
import { baseUrl } from "src/config/host";
import Button from "src/components/Shared/Buttons/Button";

const MultiSelectDropdown = ({ placeholder, selectedItem = [], buttonClass, buttonLabelClass, buttonIconClass, dropdownData, disabled = false, onClick = () => {} }) => {
  const [selected, setSelected] = useState(selectedItem.length > 0 ? `${selectedItem.length} Selected` : placeholder);

  useEffect(() => {
    setSelected(selectedItem.length > 0 ? `${selectedItem.length} Selected` : placeholder);
  }, [placeholder, selectedItem]);

  return (
    <>
      <Menu
        as="div"
        className="relative">
        <div>
          <Menu.Button className={classNames("flex h-10 w-full items-center justify-between gap-2 rounded-md border border-gray-300 pl-4 text-left", buttonClass)}>
            <div className={classNames("w-full flex-shrink text-sm font-medium text-gray-600", buttonLabelClass)}>{selected}</div>
            <div className="flex-shrink-0">
              <ChevronDownIcon
                className={classNames("mr-2 h-5 w-5 text-gray-400", buttonIconClass)}
                aria-hidden="true"
              />
            </div>
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95">
          {dropdownData?.length > 0 && (
            <Menu.Items className="absolute left-0 mt-2 w-72 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
              <div className="scrollbar max-h-60 overflow-y-auto py-2">
                {dropdownData.map((item) => (
                  <Button
                    type="button"
                    version="default"
                    className="flex w-full items-center gap-2 px-3 py-2 text-left hover:bg-gray-100"
                    key={item._id}>
                    <div className="h-8 w-8 flex-shrink-0 overflow-hidden rounded-md object-contain">
                      {isSVGString(item.image) ? (
                        <div
                          className=""
                          dangerouslySetInnerHTML={{ __html: item.image }}
                        />
                      ) : (
                        <img
                          src={item?.image?.includes(".s3.") ? item?.image + "?" + Date.now() : item?.image?.includes("data:image/") ? item?.image : baseUrl + item?.image}
                          alt={item?.name}
                          className={classNames("h-full w-full object-contain")}
                        />
                      )}
                    </div>
                    <div className="w-full flex-shrink text-[13px] font-semibold text-gray-600">{item?.name}</div>
                    <div className="flex-shrink-0">
                      <Checkbox
                        isChecked={selectedItem.includes(item._id)}
                        onChange={(e) => onClick(e, item)}
                      />
                    </div>
                  </Button>
                ))}
              </div>
            </Menu.Items>
          )}
        </Transition>
      </Menu>
    </>
  );
};

export default MultiSelectDropdown;
