import TableRow from "src/components/Shared/Table/TableRow";
import WorkspaceSubscriberInstance from "src/components/Workspaces/WorkspaceSubscriberInstance";
import { formatDate } from "src/helpers/formatDate";

function ActivityEntry({ activity }) {

  let activityTypes = {
    LOGIN: "Login",
    PAGE_VIEW: "Page View",
  };

  return (
    <TableRow keyId={activity?._id}>
      <td className="w-1/3 whitespace-pre-wrap break-all py-2 px-3 pl-4 sm:pl-6 text-gray-500 sm:table-cell md:w-1/5 sm:py-3 sm:text-sm">
        {activity?.user_id ? (
          <>
            <div className="text-gray-900">{activity?.user_id?.name ? activity?.user_id?.name : "N/A"}</div>
            <div className="text-gray-400">{activity?.user_id?.email ? activity?.user_id?.email : "No email listed"}</div>
          </>
        ) : (
          <div className="text-gray-400">*User Deleted*</div>
        )}
      </td>
      <td className="w-1/5 hidden whitespace-pre-wrap break-all py-2 px-3 text-gray-500 sm:py-3 md:text-sm lg:table-cell">
        <div className="text-gray-900">
          {activityTypes[activity?.type]}
          <div className="text-gray-300">{activity?.data?.login_portal === "ADMIN_PORTAL" ? "Admin Portal" : "User Portal"}</div>
        </div>
      </td>
      <td className="w-1/3 hidden whitespace-pre-wrap break-word py-2 px-3 text-gray-500 sm:table-cell md:w-1/5 sm:py-3 sm:text-sm">
        {activity?.type === "PAGE_VIEW" ? (
          <>
            {
              !activity?.data?.page_id?.name &&
              <div className="flex">
                <WorkspaceSubscriberInstance />
              </div>         
            }
            <div className="text-gray-900 flex flex-col">
              {activity?.data?.page_id?.name || ""}
              <span className="text-gray-300">{activity?.data?.workspace_id?.name}</span>
            </div>
          </>
        ) : (
          <span className="text-gray-300">Authentication</span>
        )}
      </td>
      <td className="w-1/4 whitespace-pre-wrap break-all py-2 px-3 text-gray-500 sm:table-cell lg:w-1/5 sm:py-3 sm:text-sm">
        <div className="text-gray-900 2xl:block hidden">{formatDate(activity?.created_at, "LLLL")}</div>
        <div className="text-gray-900 flex flex-col 2xl:hidden">
          {formatDate(activity?.created_at, "MM/DD/YYYY")} <span className="text-gray-400">{formatDate(activity?.created_at, "h:mm:ssA")}</span>
        </div>
      </td>
    </TableRow>
  );
}

export default ActivityEntry;
