import * as HeroIcons from "@heroicons/react/24/outline";
import { CloudArrowDownIcon, MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useEffect, useRef, useState } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { toast } from "react-toastify";
import Button from "src/components/Shared/Buttons/Button";
import Input from "src/components/Shared/Forms/Inputs/Input";
import { isSVGString } from "src/helpers";
import { classNames } from "src/helpers/classNames";
import { convertToBase64 } from "src/helpers/image";
import useOnClickOutside from "src/hooks/useOnClickOutside";

const GroupIconManage = ({ label, labelClasses, placeholder, divClasses, inputClasses, iconClasses, iconPosition = "left", icon = "fa-regular fa-magnifying-glass", xPlacement, dropdownClass, dropdownData = [], image, setImage = () => {} }) => {
  const inputFileRef = useRef(null);
  const iconNames = Object.keys(HeroIcons);
  const ref = useRef();
  useOnClickOutside(ref, () => setIsShwon(false));
  // const allowedExts = ["jpg", "jpeg", "png", "bmp", "gif", "svg", "webp", "avif"];

  let iconNamesArray = [];
  iconNames.forEach((iconName) => {
    const IconComponent = HeroIcons[iconName];
    let svgString = renderToStaticMarkup(<IconComponent />);
    svgString = svgString.replace('stroke-width="1.5"', 'stroke-width="2"');
    iconNamesArray.push({
      iconName,
      svgString,
    });
  });

  const [icons, setIcons] = useState(iconNames);
  const [keyword, setKeyword] = useState("");
  const [isShown, setIsShwon] = useState(false);

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && isImageType(selectedFile.type)) {
      convertToBase64(e.target.files[0], setImage);
      inputFileRef.current.value = null;
    } else {
      toast.error("Please select a valid image file.");
      inputFileRef.current.value = null;
    }
  };

  const isImageType = (fileType) => {
    return fileType.startsWith("image/");
  };

  const handleChange = () => {
    const filteredData = keyword === "" ? iconNamesArray : iconNamesArray.filter((item) => item.iconName.toLowerCase()?.replace(/\s+/g, "").includes(keyword.toLowerCase().replace(/\s+/g, "")));
    setIcons(filteredData);
  };

  useEffect(() => {
    handleChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword]);

  return (
    <>
      <div className="relative space-y-8">
        <div
          ref={ref}
          as={"div"}
          className="relative">
          <div className={classNames("relative flex h-12 w-full bg-white shadow rounded-md border border-gray-100 overflow-hidden", divClasses)}>
            {image && isSVGString(image) ? (
              <div className="flex items-center space-x-2 py-2 px-4 w-full">
                <div
                  className="w-6 h-6"
                  dangerouslySetInnerHTML={{ __html: image }}></div>
                <div className="!ml-auto">
                  <Button
                    type="button"
                    version="default"
                    className="w-7 h-7 bg-red-100 hover:bg-red-200 border border-red-100 hover:border-red-200 rounded-full flex items-center justify-center transition-all duration-200"
                    onClick={() => {
                      setImage("");
                      setIsShwon(true);
                    }}>
                    <XMarkIcon className={classNames("w-5 h-5 text-red-500")} />
                  </Button>
                </div>
              </div>
            ) : (
              <>
                {iconPosition === "left" && (
                  <div className={classNames("w-12 h-12 flex items-center justify-center flex-shrink-0", iconClasses)}>
                    <MagnifyingGlassIcon className={classNames("w-5 h-5 text-gray-400")} />
                  </div>
                )}
                <Input
                  type="text"
                  name="keyword"
                  value={keyword}
                  onFocus={() => setIsShwon(true)}
                  // onBlur={() => setIsShwon(false)}
                  onChange={(e) => setKeyword(e.target.value)}
                  inputClassNames={classNames("!border-0 h-12 w-full !ring-0 bg-transparent text-black text-sm font-medium px-3", inputClasses)}
                  placeholder={placeholder}
                />
                {iconPosition === "right" && (
                  <div className={classNames("w-12 h-12 flex items-center justify-center flex-shrink-0", iconClasses)}>
                    <MagnifyingGlassIcon className={classNames("w-5 h-5 text-gray-400")} />
                  </div>
                )}
              </>
            )}
          </div>
          {isShown && icons.length > 0 && (
            <div className={classNames("absolute z-30 top-full left-0 mt-1 px-2 py-2 w-full origin-top-right bg-white divide-y divide-gray-200 rounded-md shadow ring-0 overflow-auto scroll-smooth scrollbar max-h-80 border border-gray-100", dropdownClass)}>
              <div className="w-full grid grid-cols-3 gap-2">
                {icons.map((item) => {
                  const IconComponent = HeroIcons[item.iconName];
                  return (
                    <Button
                      key={item.iconName}
                      version="default"
                      className="flex items-center w-full px-2 py-2 rounded hover:bg-gray-200 focus:outline-none space-x-2 flex-shrink"
                      onClick={() => {
                        setImage(item.svgString);
                        inputFileRef.current.value = null;
                        setIsShwon(false);
                      }}>
                      <IconComponent className="w-5 h-5 flex-shrink-0" />
                      <span className="text-[11px] flex-shrink whitespace-wrap break-words">{item.iconName}</span>
                    </Button>
                  );
                })}
              </div>
            </div>
          )}
        </div>
        <div className="h-[1px] bg-gray-200 w-full flex justify-center items-center !my-8">
          <span className="text-gray-400 flex justify-center items-center h-10 bg-white px-3 text-base font-semibold">Or</span>
        </div>
        <div className="w-full grid gap-5">
          <div className="relative overflow-hidden">
            <div className="max-w-3xl transition-all duration-150 h-[230px] relative border-2 border-dashed border-gray-300 rounded-3xl p-5 overflow-hidden flex flex-col justify-center items-center">
              <input
                ref={inputFileRef}
                type="file"
                onChange={handleFileChange}
                accept="image/*"
                className="absolute top-0 left-0 h-full w-full z-10 opacity-0 cursor-pointer"
              />
              {image && !isSVGString(image) ? (
                <div className="relative font-medium flex focus:outline-none text-gray-400 border-leftNavColor cursor-pointer sm:w-auto z-20">
                  <div className="w-full max-w-md mx-auto overflow-hidden bg-white space-y-4">
                    <div className="w-32 h-32 overflow-hidden rounded-lg border border-gray-200 bg-white shadow flex-shrink-0">
                      <img
                        src={image}
                        alt={"groupImage"}
                        className="w-full h-full object-contain"
                      />
                    </div>
                    <div className="flex space-x-2">
                      <Button
                        type="button"
                        version="default"
                        className="text-red-500 w-full flex items-center justify-center space-x-3 py-2 px-2 transition-all duration-200 bg-red-100 rounded-lg text-sm"
                        onClick={() => {
                          setImage("");
                        }}>
                        <XMarkIcon className="w-5 h-5 text-red-500" /> Remove
                      </Button>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="h-full w-full p-5 flex flex-col justify-center items-center relative">
                  <div className="flex flex-col justify-center items-center space-y-4">
                    <CloudArrowDownIcon className="w-12 h-12 text-gray-400" />
                    <div className="text-gray-400 font-semibold">Drag & Drop file here</div>
                    <div className="h-[1px] bg-gray-200 w-full flex justify-center items-center !my-8">
                      <span className="text-gray-700 flex justify-center items-center w-8 h-8 rounded-full bg-gray-50 border border-gray-900/10 text-xs font-semibold uppercase">or</span>
                    </div>
                  </div>
                  <label htmlFor="bulk_upload-input">
                    <Button
                      version="secondary"
                      onClick={() => {}}>
                      Select file
                    </Button>
                  </label>
                  <input
                    type="file"
                    onChange={(e) => {
                      handleFileChange(e);
                      e.target.value = null;
                    }}
                    id="bulk_upload-input"
                    name="bulk_upload-input"
                    accept="image/*"
                    className="hidden"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GroupIconManage;
