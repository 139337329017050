import { FunnelIcon } from "@heroicons/react/24/outline";
import { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { workspaceIntegrationFilterCreate, workspaceIntegrationFilterDelete, workspaceIntegrationFilterList, workspaceIntegrationFilterManage } from "src/actions/workspace";
import { apiRequest } from "src/async/apiUtils";
import Button from "src/components/Shared/Buttons/Button";
import EditContainer from "src/components/Shared/Containers/EditContainer";
import NoDataDashedContainer from "src/components/Shared/NoData/NoDataDashedContainer";
import WorkspaceIntegrationFiltersItem from "src/components/Workspaces/Workspace/Settings/WorkspaceIntegration/WorkspaceIntegrationFiltersItem";
import useFetch from "src/hooks/useFetch";
import { v4, validate as uuidValidate } from "uuid";

const WorkspaceIntegrationFilters = ({ user, workspaceId, ...props }) => {
  const [filters, setFilters] = useState([]);
  const [customFields, setCustomFields] = useState([]);
  const trustedAttributeArr = [
    {
      _id: "user.fullName",
      value: "user.fullName",
      name: "Full Name",
    },
    {
      _id: "user.email",
      value: "user.email",
      name: "Email Address",
    },
  ];

  const valueType = [
    {
      _id: "value",
      value: "value",
      name: "Text Value",
    },
    {
      _id: "attribute",
      value: "attribute",
      name: "User Attribute",
    },
  ];

  const {
    response: { data: operators },
  } = useFetch("/operators/list");

  const getFilters = async () => {
    try {
      const data = await props.workspaceIntegrationFilterList({ workspace_id: workspaceId });
      setFilters(data.map((item) => ({ ...item, addWhere: item?.datasource_id ? true : false })));
    } catch (error) {
      // console.dir("ERROR:", error);
    }
  };

  useEffect(() => {
    getFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addFilter = async () => {
    const uniqueId = v4();
    setFilters([
      ...filters,
      {
        _id: uniqueId,
        operator_id: null,
        column_name: null,
        value_type: "value",
        column_value: null,
        trusted_attribute: null,
        datasource_id: null,
        addWhere: false,
      },
    ]);
  };

  const updateFilter = async (filter) => {
    try {
      if (uuidValidate(filter._id)) {
        const res = await props.workspaceIntegrationFilterCreate({ filter, workspace_id: workspaceId });
        setFilters((filters) =>
          filters.map((flt) =>
            flt._id !== filter?._id
              ? flt
              : {
                  ...flt,
                  _id: res?.data?._id,
                  saveDisabled: false,
                  addWhere: flt?.datasource_id ? true : false,
                },
          ),
        );
        toast.success(res.message);
      } else {
        const res = await props.workspaceIntegrationFilterManage({ filter, workspace_id: workspaceId });
        setFilters((filters) =>
          filters.map((flt) =>
            flt._id !== filter?._id
              ? flt
              : {
                  ...flt,
                  saveDisabled: false,
                  addWhere: flt?.datasource_id ? true : false,
                },
          ),
        );
        toast.success(res.message);
      }
    } catch (error) {
      setFilters((filters) =>
        filters.map((flt) =>
          flt._id !== filter?._id
            ? flt
            : {
                ...flt,
                saveDisabled: false,
                addWhere: flt?.datasource_id ? true : false,
              },
        ),
      );
      toast.error(error.message);
    }
  };

  const removeFilter = async (id) => {
    const ac = new AbortController();
    try {
      const res = await props.workspaceIntegrationFilterDelete({ id, workspace_id: workspaceId }, ac.signal);
      setFilters(filters.filter((flt) => flt._id !== id));
      toast.success(res.message);
    } catch (error) {
      toast.error(error.message);
    }
    return () => ac.abort();
  };

  const loadCustomFields = useCallback(() => {
    (async () => {
      try {
        const { data: resData } = await apiRequest("GET", `/users/custom-fields`);

        if (resData.status === 200 && resData.data) {
          setCustomFields(resData.data);
        }
      } catch (err) {
        console.dir("err", err);
      }
    })();
  }, []);

  useEffect(() => {
    loadCustomFields();
  }, [loadCustomFields]);

  const allTrustedAttributes = [
    ...trustedAttributeArr,
    ...customFields.map((field) => ({
      _id: `user.${field.code}`,
      value: `user.${field.code}`,
      name: field.label,
    })),
  ];

  const filterButton = (
    <Button
      version="primary"
      onClick={addFilter}>
      <div className="flex gap-x-3">
        <FunnelIcon className="h-5 w-5 stroke-2" /> Apply Filter
      </div>
    </Button>
  );

  return (
    <EditContainer
      title="Filters"
      preview={
        <div className="flex h-10 w-56 items-center">
          <div className="flex gap-x-2 rounded border border-gray-100 px-3 py-1 shadow">
            {filters.length ? (
              <>
                <div>{filters.length}</div>
                Applied Filter
                {filters.length > 1 && "s"}
              </>
            ) : (
              "No Credentials"
            )}
          </div>
        </div>
      }
      defaultOptions={{
        onCancelButtonVisible: false,
        onSuccessButtonVisible: false,
      }}
      defaultOpen={filters.length === 0}
      fullWidth={true}>
      <>
        <div className="relative">
          <p className="mb-2">Manage the workspace's filters from one location.</p>
          <div className="mt-2 space-y-3 rounded bg-white pb-3">
            {filters.length > 0 ? (
              <>
                {filters.map((filter, index) => {
                  return (
                    <WorkspaceIntegrationFiltersItem
                      key={filter?._id}
                      user={user}
                      workspaceId={workspaceId}
                      state={filter}
                      operators={operators}
                      trustedAttributeArr={allTrustedAttributes}
                      valueType={valueType}
                      setState={(e) => {
                        setFilters(
                          filters.map((fl, i) => {
                            if (index === i) {
                              return e;
                            } else {
                              return fl;
                            }
                          }),
                        );
                      }}
                      removeFilter={() => {
                        if (uuidValidate(filter._id)) {
                          setFilters((prevData) => prevData.filter((data) => data?._id !== filter._id));
                        } else {
                          removeFilter(filter._id);
                        }
                      }}
                      manageFilter={updateFilter}
                    />
                  );
                })}
                <div className="flex pt-4">{filterButton}</div>
              </>
            ) : (
              <NoDataDashedContainer addButton={filterButton} />
            )}
          </div>
        </div>
      </>
    </EditContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    site: state.site,
    workspace: state.workspaceDetails,
  };
};

export default connect(mapStateToProps, { workspaceIntegrationFilterList, workspaceIntegrationFilterCreate, workspaceIntegrationFilterManage, workspaceIntegrationFilterDelete })(WorkspaceIntegrationFilters);
