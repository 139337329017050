import { ChevronRightIcon } from "@heroicons/react/24/outline";

const SubArrowIcon = () => {
  return (
    <div className="bg-gray-50 w-6 h-6 flex justify-center items-center rounded-full mr-3">
      <ChevronRightIcon className="ml-[1px] h-4 w-4 stroke-gray-400 stroke-[3px]" />
    </div>
  );
};

export default SubArrowIcon;
