import { Square2StackIcon } from "@heroicons/react/24/outline";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Input from "src/components/Shared/Forms/Inputs/Input";
import { apiUrl } from "src/config/host";
import Button from "src/components/Shared/Buttons/Button";

const SSOServiceMetadata = () => {
  const IDPConfigurations = [
    {
      label: "SP-Entity ID/Issuer",
      url: window.location.origin,
    },
    {
      label: "ACS (Assertion Customer Service)",
      url: apiUrl + "/saml",
    },
    {
      label: "Audience URI",
      url: window.location.origin,
    },
  ];

  return (
    <div className="shadow bg-white rounded-md mt-4 p-6 relative w-full max-w-4xl mb-8">
      <h5 className="text-base font-medium mb-10">Note The Following To Configure The IDP</h5>
      <div className="grid grid-cols-4 gap-4">
        {IDPConfigurations.map((config) => {
          return (
            <div
              key={config.label}
              className="col-span-4 sm:col-span-4 lg:col-span-3">
              <div className="relative">
                <Input
                  label={config.label}
                  disabled={true}
                  value={config.url}
                />
                <CopyToClipboard
                  text={config.url}
                  onCopy={() => toast.success("Copied to clipboard")}>
                  <Button
                    type="button"
                    version="default"
                    className="cursor-pointer p-0 absolute bottom-0 right-1">
                    <Square2StackIcon className="hover:text-highlightColor w-10 h-10 p-2 text-gray-500" />
                  </Button>
                </CopyToClipboard>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
  };
};
export default connect(mapStateToProps, null)(SSOServiceMetadata);
