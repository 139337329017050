import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { userWorkspacesAndAssociatedPermissions } from "src/actions/workspace";
import MultiColumnContent from "src/components/Shared/MultiColumnContent/MultiColumnContent";
import WorkspacePermissionSelectedItem from "src/components/Workspaces/Workspace/Permissions/WorkspacePermissionSelectedItem";
import UserPermissionHeader from "./UserPermissionHeader";

const UserPermissions = ({ loaded = false, workspaceList = [], ...props }) => {
  const [workspaceSearchList, setWorkspaceSearchList] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [activeWorkspace, setActiveWorkspace] = useState(null);

  useEffect(() => {
    if (keyword) {
      setWorkspaceSearchList(workspaceList.filter((workspace) => workspace.workspace_id.name.toLocaleLowerCase().includes(keyword.toLocaleLowerCase())));
    } else {
      setWorkspaceSearchList(workspaceList ? workspaceList : []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceList, keyword]);

  useEffect(() => {
    if (workspaceSearchList.length > 0) {
      if (!activeWorkspace) {
        setActiveWorkspace(workspaceSearchList[0]);
      }
      if (activeWorkspace && !workspaceSearchList.map((workspace) => workspace?._id).includes(activeWorkspace?._id)) {
        setActiveWorkspace(workspaceSearchList[0]);
      }
    } else {
      setActiveWorkspace(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceSearchList]);

  return (
    <MultiColumnContent
      headerContentConfig={{
        headerEnabled: true,
        searchEnabled: true,
        keyword: keyword,
        onKeywordChange: (value) => setKeyword(value),
      }}
      listContentConfig={{
        listLoaded: loaded,
        listData: workspaceSearchList,
        listComponent: (props) => (
          <UserPermissionHeader
            {...props}
            activeWorkspace={activeWorkspace}
            setActiveWorkspace={setActiveWorkspace}
          />
        ),
      }}
      selectedContentConfig={{
        selectedData: activeWorkspace,
        selectedComponent: () => (
          <WorkspacePermissionSelectedItem
            activeUser={activeWorkspace}
            disabled={true}
            pageName={"user"}
          />
        ),
      }}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
  };
};
export default connect(mapStateToProps, { userWorkspacesAndAssociatedPermissions })(UserPermissions);
