import { connect } from "react-redux";
import StylesPreview from "src/components/Shared/Styles/StylesPreview";
import Button from "src/components/Shared/Buttons/Button";
import { authorizeUserComponentAccess } from "src/helpers/authorizeUserComponentAccess";
import { ArrowPathIcon } from "@heroicons/react/24/solid";
import { useParams } from "react-router";

const StylesBuilder = ({
  data = {},
  layoutType,
  handleBrowserTabText = null,
  onSubmit = () => {},
  title = "",
  secondaryTitle = "",
  headerContent = null,
  setWorkspace = () => {},
  propsButtonOption = null,
  setButtonIsDisabled = () => {},
  setSquareLogoButtonIsDisabled = () => {},
  updatedSite = {},
  setUpdatedSite = () => {},
  getSiteData = () => {},
  handleStylesDataChange = () => {},
  handleSquareLogoStylesDataChange = () => {},
  ...props
}) => {
  const { id } = useParams();

  const onClear = () => {
    setButtonIsDisabled(true);
    setUpdatedSite({
      ...updatedSite,
      ...getSiteData("custom_colors"),
    });
  };

  return (
    <div className="max-w-7xl grid space-y-8">
      <StylesPreview
        layoutType={layoutType}
        handleBrowserTabText={handleBrowserTabText}
        menu={["Home", "About Us", "Feedback"]}
        styles={updatedSite}
        user={props.user}
        setWorkspace={setWorkspace}
        onChange={handleStylesDataChange}
        workspaceDetails={data}
      />
      <div className="border-t border-gray-200 mt-5 pt-3 w-full flex justify-end gap-x-4">
        <Button
          version="gray"
          disabled={propsButtonOption?.buttonLoader}
          onClick={onClear}>
          Cancel
        </Button>
        {!propsButtonOption?.buttonLoader ? (
          authorizeUserComponentAccess(props.user, id, "layout", ["update"]) && (
            <Button
              disabled={propsButtonOption?.buttonIsDisabled}
              onClick={() => onSubmit(updatedSite)}>
              Save
            </Button>
          )
        ) : (
          <Button disabled={propsButtonOption?.buttonLoader}>
            Update
            <ArrowPathIcon className="h-5 w-5 ml-1 animate-spin" />
          </Button>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, {})(StylesBuilder);
