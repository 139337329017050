import { ArrowLongRightIcon, Bars3Icon, Cog6ToothIcon, PlusIcon, Square2StackIcon, TrashIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { Form, Formik } from "formik";
import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Tooltip as ReactTooltip } from "react-tooltip";
import Button from "src/components/Shared/Buttons/Button";
import Input from "src/components/Shared/Forms/FormikForms/Input";
import QuickMenu from "src/components/Shared/QuickMenu";
import WorkspaceIntegrationFiltersItem from "src/components/Workspaces/Workspace/Settings/WorkspaceIntegration/WorkspaceIntegrationFiltersItem";
import { classNames } from "src/helpers/classNames";
import * as Yup from "yup";
import { v4 } from "uuid";

const DisplayPageForm = ({
  pageOptions = [],
  operatorOptions = [],
  permission = {},
  setPermission = () => {},
  updatePermission = () => {},
  removePermission = () => {},
  setDataChanges = () => {},
  assignDisplayName = () => {},
  dataChanges = 0,
  trustedAttributeArr = [],
  valueType,
  draggableProps,
  dragHandleProps,
  innerRef,
  workspaceDetails,
  authorizeUserComponentAccessPermission = true,
  showNestedData = true,
  hideAliasManageButton = false,
  ...props
}) => {
  const [pageType, setPageType] = useState("");
  const [pageCopied, setPageCopied] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (pageCopied) {
      setTimeout(() => {
        setPageCopied(false);
      }, 6000);
    }
  }, [pageCopied]);

  const handleConditionAdd = () => {
    setPermission({
      ...permission,
      filters: [
        ...permission.filters,
        {
          id: v4(),
          column_name: "",
          operator_id: null,
          value_type: "value",
          column_value: "",
          trusted_attribute: "",
          addWhere: false,
          datasource_id: "",
        },
      ],
    });
    setDataChanges(dataChanges + 1);
  };

  useEffect(() => {
    let index = pageOptions.findIndex((op) => op._id === permission?.page_id);
    if (index !== -1) {
      setPageType(pageOptions[index].page_type);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageOptions, permission?.page_id]);

  const selectedPage = pageOptions.find((page) => page._id === permission.page_id);

  useEffect(() => {
    if (selectedPage?.page_type) {
      let itemList = [];
      if (selectedPage?.page_type === "DOMO" && !permission?.addWhere) {
        itemList.push({
          name: `Add Where`,
          onClick: () => {
            setPermission({
              ...permission,
              addWhere: true,
            });
            setDataChanges(dataChanges + 1);
          },
        });
      }
      if (!permission?.displayNameShow) {
        itemList.push({
          name: "Add Alias",
          onClick: () => {
            setPermission({
              ...permission,
              displayNameShow: true,
            });
          },
        });
      }
      setItems(itemList);
    } else {
      setItems([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataChanges, permission, selectedPage?.page_type]);

  return (
    <div
      className={"relative w-full max-w-9xl " + (props?.className ? props?.className : "")}
      ref={innerRef}
      {...draggableProps}>
      <Formik
        enableReinitialize={true}
        initialValues={{
          page_id: permission?.page_id || "",
          datasource_id: permission?.datasource_id || "",
          page_alias: permission?.page_alias || "",
        }}
        validationSchema={Yup.object({
          page_id: Yup.string().required("Required"),
        })}>
        {({ values, errors, touched }) => {
          const handleOnChange = (e) => {
            if (e.target.name === "page_id") {
              const page = pageOptions.find((op) => op._id === e.target.value);
              if (page) {
                setPageType(page.page_type);
                setPermission({
                  ...permission,
                  filters: [],
                  datasource_id: "",
                  addWhere: false,
                  [e.target.name]: e.target.value,
                });
              }
            } else {
              setPermission({
                ...permission,
                [e.target.name]: e.target.value,
              });
            }
            setDataChanges(dataChanges + 1);
          };
          return (
            <Form
              onChange={handleOnChange}
              className="relative w-full max-w-9xl rounded bg-white">
              <div className={`relative flex w-full rounded-md border border-gray-200  ${pageType === "DOMO" ? "rounded-br-none" : ""}`}>
                {!props?.viewOnly && authorizeUserComponentAccessPermission && (
                  <Button
                    dragHandleProps={dragHandleProps}
                    type="button"
                    version="default"
                    className="flex w-8 cursor-move items-center justify-center border-r border-gray-200 bg-gray-100 text-gray-700 outline-none ring-0 sm:min-w-[32px]">
                    <Bars3Icon className="h-5 w-5 stroke-2" />
                  </Button>
                )}
                <div className="flex w-full items-center justify-between px-4 py-3">
                  <div className="flex ">
                    <div className="flex min-w-[280px] max-w-[280px] pr-4">
                      {props?.viewOnly ? (
                        <div className="block h-10 w-full truncate rounded-md border border-gray-300 px-4 py-2 shadow-sm focus:border-highlightColor focus:outline-none focus:ring-highlightColor sm:text-sm">
                          {pageOptions.find((pageOption) => pageOption?._id === values.page_id)?.name}
                        </div>
                      ) : (
                        <Input
                          name="page_id"
                          as="select"
                          value={values.page_id}>
                          <option value="">Select</option>
                          {pageOptions.map((pageOption) => (
                            <option
                              key={pageOption._id}
                              value={pageOption._id}>
                              {pageOption.name}
                            </option>
                          ))}
                        </Input>
                      )}
                    </div>
                    {permission?.displayNameShow && (
                      <>
                        <div className="relative flex min-w-[280px] flex-col pl-8 pr-2">
                          <div className="absolute left-0 top-2">
                            <ArrowLongRightIcon className="h-5 w-5" />
                          </div>
                          <div className="relative">
                            <div className="text absolute -top-2 left-2 rounded-full border-[1px] border-gray-100 bg-white p-1 px-2 py-1 text-xs font-semibold leading-none text-gray-400">Display Name</div>
                            <div className="group">
                              {props?.viewOnly ? (
                                <div className="block h-10 w-full rounded-md border border-gray-300 px-4 py-2 shadow-sm focus:border-highlightColor focus:outline-none focus:ring-highlightColor sm:text-sm">{permission?.page_alias}</div>
                              ) : (
                                <Input
                                  label=""
                                  name="page_alias"
                                  placeholder=""
                                  value={values.page_id}
                                />
                              )}
                              {!props?.viewOnly && authorizeUserComponentAccessPermission && (
                                <div className="absolute right-0 top-0 flex h-full">
                                  <Button
                                    type="button"
                                    version="default"
                                    className={"flex w-8 min-w-[32px] items-center justify-center rounded-r-md border border-l border-gray-300 bg-gray-50/50 text-gray-400 outline-none ring-0 transition-all duration-150 hover:bg-gray-100 hover:text-gray-500"}
                                    onClick={() => {
                                      setPermission({
                                        ...permission,
                                        page_alias: "",
                                        displayNameShow: false,
                                        displayNameChanges: true,
                                      });
                                      setDataChanges(dataChanges + 1);
                                      // if (permission?._id) {
                                      //   assignDisplayName({ ...permission, ordering: props?.ordering, page_alias: "" });
                                      // }
                                    }}>
                                    <TrashIcon className="h-5 w-5" />
                                  </Button>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="flex items-center">
                    {!props?.viewOnly && authorizeUserComponentAccessPermission && items.length > 0 && (
                      <QuickMenu
                        icon={
                          <div className="flex h-full items-center justify-center rounded-md px-2 py-2 text-gray-400 hover:bg-gray-50 hover:text-gray-500">
                            <Cog6ToothIcon className="h-6 w-6" />
                          </div>
                        }
                        position="LEFT"
                        items={items}
                      />
                    )}
                    {workspaceDetails?.layout_type === "NO_NAVIGATION" && permission.page_id && (
                      <>
                        <Button
                          data-tooltip-id={`${props?.id}-custom-link`}
                          type="button"
                          version="default"
                          onClick={() => {
                            navigator.clipboard.writeText(`${props?.site?.site_domain}/workspaces/${props?.workspaceId}/page/${permission?.page_id}`);
                            setPageCopied(true);
                          }}
                          className="hidden h-8 w-8 items-center justify-center rounded-md border-gray-200 text-gray-400 outline-none ring-0 transition-all duration-200 hover:bg-gray-50 hover:text-gray-500 sm:flex">
                          <div className="relative">
                            <Square2StackIcon className={classNames("h-6 w-6 transition-all duration-150", pageCopied ? "text-green-600/70" : "")} />
                            <div className={classNames("absolute -right-2 -top-2 rounded-full bg-white opacity-0 transition-all duration-150", pageCopied ? "opacity-100" : "")}>
                              <CheckCircleIcon className="h-5 w-5 text-green-600/50" />
                            </div>
                          </div>
                        </Button>
                        <ReactTooltip
                          id={`${props?.id}-custom-link`}
                          delayShow={200}
                          positionStrategy="fixed"
                          className="rounded bg-gray-700 px-2 py-2 opacity-100">
                          <div className="font-normal leading-[10px]">Copy page link.</div>
                        </ReactTooltip>
                      </>
                    )}
                    {!props?.viewOnly && authorizeUserComponentAccessPermission && (
                      <Button
                        type="button"
                        version="default"
                        onClick={removePermission}
                        className="flex h-8 w-8 items-center justify-center rounded-md border-gray-200 text-gray-400 outline-none ring-0 transition-all duration-200 hover:bg-gray-50 hover:text-gray-500">
                        <TrashIcon className="h-5 w-5" />
                      </Button>
                    )}
                  </div>
                </div>
              </div>
              {permission?.addWhere && (
                <div className="relative ml-[32px] grid gap-y-2 border border-t-0 border-gray-200 bg-white px-4 py-2">
                  <div className="flex w-full items-center gap-4">
                    <div className="relative flex items-center rounded-md border border-gray-200 pl-3 pr-10 ">
                      <div className="text-sm text-gray-800">
                        Apply all filters only to the following <span className="mx-2 inline-flex rounded bg-gray-100 px-2 py-1 font-semibold">datasets</span>
                      </div>
                      <div className="group relative  w-80">
                        {props?.viewOnly ? (
                          <div className="p-2">
                            <div className="block h-10 w-full truncate rounded-md border border-gray-300 px-4 py-2 shadow-sm focus:border-highlightColor focus:outline-none focus:ring-highlightColor sm:text-sm">{permission?.datasource_id}</div>
                          </div>
                        ) : (
                          <div className="flex gap-2 p-2">
                            <Input
                              label=""
                              name="datasource_id"
                              placeholder="Datasource ID"
                            />
                          </div>
                        )}
                      </div>
                      {!props?.viewOnly && authorizeUserComponentAccessPermission && (
                        <div className="absolute right-0 top-0 flex h-full">
                          <Button
                            type="button"
                            version="default"
                            className={"flex w-8 min-w-[32px] items-center justify-center rounded-r-md border-l border-gray-200 bg-gray-50/50 text-gray-400 outline-none ring-0 transition-all duration-150 hover:bg-gray-100 hover:text-gray-500"}
                            onClick={() => {
                              setDataChanges(dataChanges + 1);
                              setPermission({
                                ...permission,
                                datasource_id: "",
                                addWhere: false,
                              });
                            }}>
                            <TrashIcon className="h-5 w-5" />
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </Form>
          );
        }}
      </Formik>

      {(pageType === "DOMO" || pageType === "METABASE") && (
        <>
          {(permission?.filters?.length > 0 || (!props?.viewOnly && authorizeUserComponentAccessPermission)) && (
            <div className="border-b-rounded relative ml-[22px] grid gap-y-2 rounded-b-md border border-t-0 border-gray-200 bg-white px-4 py-4 sm:ml-[32px]">
              {permission.filters.map((condition, index) => (
                <WorkspaceIntegrationFiltersItem
                  key={condition?._id || condition?.id}
                  viewOnly={props?.viewOnly}
                  from="group"
                  state={condition}
                  operators={operatorOptions}
                  trustedAttributeArr={trustedAttributeArr}
                  valueType={valueType}
                  setState={(e) => {
                    setPermission({
                      ...permission,
                      filters: permission.filters.map((p, i) => {
                        if (i === index) {
                          return e;
                        } else {
                          return p;
                        }
                      }),
                    });
                    setDataChanges(dataChanges + 1);
                  }}
                  removeFilter={() => {
                    setDataChanges(dataChanges + 1);
                    setPermission({
                      ...permission,
                      filters: permission.filters.filter((p, i) => i !== index),
                    });
                  }}
                  isAddWhere={pageType === "DOMO" ? true : false}
                  authorizeUserComponentAccessPermission={authorizeUserComponentAccessPermission}
                />
              ))}
              {!props?.viewOnly && authorizeUserComponentAccessPermission && (
                <div className="relative flex">
                  <Button
                    onClick={handleConditionAdd}
                    type="button"
                    version="default"
                    className="flex h-10 items-center justify-center gap-2 px-0 py-0 text-base font-medium text-highlightColor">
                    <PlusIcon className="h-4 w-4 stroke-2" /> Add Condition
                  </Button>
                </div>
              )}
              {/* Nested Pages */}
              {selectedPage?.nested_pages && Array.isArray(selectedPage.nested_pages) && selectedPage?.nested_pages.length > 0 && (
                <>
                  {/* <div className="relative pt-4 border-t border-dashed  border-gray-300">*/}
                  <div className="relative">
                    <div className="relative mt-4 rounded-md border border-gray-200 px-3 py-3 pt-4">
                      <div className="absolute -top-3 left-8 bg-white px-2 text-sm font-semibold text-gray-500">Nested Pages</div>
                      <div className="space-y-2">
                        {selectedPage.nested_pages.map((page, i) => {
                          const pageItem = pageOptions.find((item) => item._id === page.page_id);
                          return (
                            <Fragment key={i}>
                              {!pageItem ? (
                                <></>
                              ) : (
                                <div className="flex items-center justify-between">
                                  {/* {props?.viewOnly ? (
                                  <div className="h-10 w-48 whitespace-nowrap rounded-md border border-gray-300 py-2 px-4 shadow-sm focus:border-highlightColor focus:outline-none focus:ring-highlightColor sm:text-sm inline-flex items-center">{pageItem.name}</div>
                                ) : (
                                  <select
                                    className="w-[30%] h-10 block rounded-md border border-gray-300 py-2 px-4 shadow-sm focus:border-highlightColor focus:outline-none focus:ring-highlightColor sm:text-sm"
                                    disabled
                                    value={pageItem.page_id}>
                                    <option value={pageItem.page_id}>{pageItem.name}</option>
                                  </select>
                                )} */}
                                  <div className="inline-flex h-10 w-full  max-w-xs items-center rounded-md border border-gray-300 px-4 py-2 focus:border-highlightColor focus:outline-none focus:ring-highlightColor sm:text-sm">
                                    <span className="inline-block truncate whitespace-nowrap">{pageItem.name}</span>
                                  </div>
                                </div>
                              )}
                            </Fragment>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    me: state.auth.user,
    site: state.site,
  };
};

export default connect(mapStateToProps, {})(DisplayPageForm);
