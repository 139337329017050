import { connect } from "react-redux";
import Image from "src/components/Shared/Image";
import { classNames } from "src/helpers/classNames";

const UserPermissionHeader = ({ item, activeWorkspace = {}, setActiveWorkspace = () => {} }) => {
  return (
    <div className={classNames("relative flex h-16 w-[400px] rounded-md bg-white shadow", item._id === activeWorkspace?._id ? "" : "")}>
      <div
        onClick={() => setActiveWorkspace(item)}
        className={classNames("w-full flex cursor-pointer items-center justify-between px-6 gap-10 relative bg-white border-2 rounded-md max-w-full transition-all", item?._id === activeWorkspace?._id ? "border-highlightColor" : "border-gray-100 ")}>
        <div className="relative flex items-center gap-x-2 w-full">
          <div className="w-12 h-12 rounded-full overflow-hidden flex items-center justify-center">
            <Image
              image={item?.workspace_id?.square_logo || item?.workspace_id?.image_favicon || item?.workspace_id?.image_logo}
              alt={"User permission tab data item"}
            />
          </div>
          <p className="font-semibold text-gray-700 max-w-[150px] truncate">{item?.workspace_id?.name}</p>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    me: state.auth.user,
  };
};

export default connect(mapStateToProps, {})(UserPermissionHeader);
