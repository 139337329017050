import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { verifyToken } from "src/actions/auth";
import ResetPasswordContent from "src/components/Auth/ResetPasswordContent";
import ResetPasswordUrlInvalid from "src/components/Auth/ResetPasswordUrlInvalid";
import Preloader from "src/components/Shared/LoadingAnimations/Preloader";

const VerifyToken = ({ verifyToken }) => {
  const [status, setStatus] = useState("pending");
  const params = useParams();

  const loadOut = {
    pending: <Preloader />,
    failed: <ResetPasswordUrlInvalid url="/forgot-password/submit-email" />,
    success: <ResetPasswordContent token={params.token || null} />,
  };

  useEffect(() => {
    const ac = new AbortController();

    const loadToken = async () => {
      try {
        await verifyToken({ token: params.token || null }, ac.signal);
        setStatus("success");
        // return navigate(`/forgot-password/reset-password/${data}`);
      } catch (error) {
        setStatus("failed");
      }
    };

    loadToken();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loadOut[status];
};

export default connect(null, { verifyToken })(VerifyToken);
