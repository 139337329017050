import { ChevronDownIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import Button from "src/components/Shared/Buttons/Button";
import Checkbox from "src/components/Shared/Forms/Inputs/Checkbox";
import { classNames } from "src/helpers/classNames";

const WorkspacePermissionItem = ({ data, index, activeUser, setActiveUser = () => {}, setPermissionChanges = () => {}, disabled = false }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [access, setAccess] = useState("None");

  useEffect(() => {
    if (data?.code === "page") {
      setAccess(activeUser?.page_update ? "Full" : "None");
    } else if (data?.code === "user") {
      setAccess(activeUser?.user_update ? "Full" : "None");
    } else if (data?.code === "settings") {
      setAccess(activeUser?.settings_update ? "Full" : activeUser?.layout_update || activeUser?.integration_update || activeUser?.workspace_filter_update || activeUser?.workspace_delete ? "Partial" : "None");
    } else if (data?.code === "group") {
      setAccess(activeUser?.group_update ? "Full" : activeUser?.group_content_page_update || activeUser?.group_user_update ? "Partial" : "None");
    } else if (data?.code === "permission") {
      setAccess(activeUser?.permission_update ? "Full" : activeUser?.permission_editor_create || activeUser?.permission_editor_update ? "Partial" : "None");
    }
  }, [data?.code, activeUser]);

  const handleUserPagePermission = () => {
    if (data.code === "user") {
      setActiveUser((prevData) => ({
        ...prevData,
        user_update: !prevData.user_update,
      }));
      setPermissionChanges((prevData) => prevData + 1);
    }

    if (data.code === "page") {
      setActiveUser((prevData) => ({
        ...prevData,
        page_update: !prevData.page_update,
      }));
      setPermissionChanges((prevData) => prevData + 1);
    }

    if (data.code === "group") {
      setActiveUser((prevData) => ({
        ...prevData,
        group_update: !prevData.group_update,
        group_content_page_update: !prevData.group_update,
        group_user_update: !prevData.group_update,
      }));
      setPermissionChanges((prevData) => prevData + 1);
    }

    if (data.code === "permission") {
      setActiveUser((prevData) => ({
        ...prevData,
        permission_update: !prevData.permission_update,
        permission_editor_update: !prevData.permission_update,
        permission_editor_create: !prevData.permission_update,
      }));
      setPermissionChanges((prevData) => prevData + 1);
    }

    if (data.code === "settings") {
      setActiveUser((prevData) => ({
        ...prevData,
        settings_update: !prevData.settings_update,
        layout_update: !prevData.settings_update,
        integration_update: !prevData.settings_update,
        workspace_filter_update: !prevData.settings_update,
        workspace_delete: !prevData.settings_update,
      }));
      setPermissionChanges((prevData) => prevData + 1);
    }
  };

  return (
    <>
      <div className={classNames("p-1 border rounded-lg transition-all duration-200 border-transparent")}>
        <div className={classNames("relative overflow-hidden flex z-0", index % 2 === 0 ? "!bg-gray-50" : "!bg-gray-50/50")}>
          <div className={classNames("absolute top-0 left-0 w-full h-full z-[-1]  transition-all duration-200 rounded-md bg-transparent")}></div>
          <div className={classNames("pl-4 py-5", index % 2 === 0 ? "!bg-gray-50" : "!bg-gray-50/50")}>
            <Checkbox
              isChecked={activeUser[`${data?.code}_update`]}
              onChange={handleUserPagePermission}
              checkboxInputClass={classNames("!w-5 !h-5 !min-w-[20px] !min-h-[20px]")}
              checkboxBoxClass={"!w-5 !h-5 !min-w-[20px] !min-h-[20px]"}
              checkboxIconClass={"!w-5 !h-5"}
            />
          </div>
          <Button className={classNames("relative cursor-text z-0 border-none !shadow-none !w-full !block !text-left !justify-start hover:bg-gray-50 focus:!ring-0 overflow-hidden !py-4 transition-all duration-200", index % 2 === 0 ? "!bg-gray-50" : "!bg-gray-50/50")}>
            <div className="w-full text-gray-950 flex-shrink">
              <h2 className={classNames("flex gap-x-4 text-lg font-semibold transition-all duration-200 text-gray-900")}>
                {data.name}
                <div
                  className={classNames(
                    "rounded-full pb-[2px] px-3 flex items-center justify-center gap-2 font-semibold text-base transition-all duration-200 text-gray-900",
                    access === "Full" && "bg-green-50/80",
                    access === "Partial" && "bg-amber-50/60",
                    access === "None" && "bg-gray-50/80"
                  )}>
                  <p className={classNames("font-light", access === "Full" && "text-green-500", access === "Partial" && "text-amber-500", access === "None" && "text-gray-300")}>
                    Access:<span className="font-normal opacity-100 pl-2">{access}</span>
                  </p>
                </div>
              </h2>
              <div className={classNames("text-[13px] transition-all duration-200 text-gray-400")}>{data.caption}</div>
            </div>
            <div className="flex items-center justify-center flex-shrink-0 mr-10"></div>
            <div
              onClick={() => setIsOpen(!isOpen)}
              className={classNames(
                "w-8 h-8 rounded-full flex items-center justify-center flex-shrink-0 overflow-hidden bg-black/5 transition-all duration-200 cursor-pointer",
                isOpen ? "-rotate-180" : "",
                ["user", "page"].includes(data.code) ? "opacity-0 invisible" : "opacity-100 visible"
              )}>
              {!["user", "page"].includes(data.code) && <ChevronDownIcon className={classNames("w-5 h-5 transition-all duration-200 text-gray-500")} />}
            </div>
          </Button>
        </div>
        <div className={classNames("relative transition-all duration-200", isOpen && !["user", "page"].includes(data.code) ? "max-h-[999999px]" : "max-h-0 overflow-hidden")}>
          <div className="relative min-h-[0px] py-6 px-4">
            {data?.code === "group" && (
              <div className="space-y-4">
                <div className="space-y-3 pl-6">
                  <div>
                    <Checkbox
                      forceCheck={disabled}
                      disabled={disabled}
                      isChecked={activeUser?.group_content_page_update}
                      checkboxName={"group_content_page_update"}
                      onChange={(e) => {
                        setActiveUser((prevData) => ({
                          ...prevData,
                          group_update: e.target.checked && prevData.group_user_update ? true : false,
                          [e.target.name]: e.target.checked,
                        }));
                        setPermissionChanges((prevData) => prevData + 1);
                      }}
                      checkboxLabel={"Only page and condition control"}
                    />
                  </div>
                  <div>
                    <Checkbox
                      forceCheck={disabled}
                      disabled={disabled}
                      isChecked={activeUser?.group_user_update}
                      checkboxName={"group_user_update"}
                      onChange={(e) => {
                        setActiveUser((prevData) => ({
                          ...prevData,
                          group_update: e.target.checked && prevData.group_content_page_update ? true : false,
                          [e.target.name]: e.target.checked,
                        }));
                        setPermissionChanges((prevData) => prevData + 1);
                      }}
                      checkboxLabel={"Allow only manage access of users"}
                    />
                  </div>
                </div>
              </div>
            )}
            {data?.code === "permission" && (
              <div className="space-y-4">
                <div className="space-y-3 pl-6">
                  <div>
                    <Checkbox
                      forceCheck={disabled}
                      disabled={disabled}
                      isChecked={activeUser?.permission_editor_update}
                      checkboxName={"permission_editor_update"}
                      onChange={(e) => {
                        setActiveUser((prevData) => ({
                          ...prevData,
                          permission_update: e.target.checked && prevData?.permission_editor_create ? true : false,
                          [e.target.name]: e.target.checked,
                        }));
                        setPermissionChanges((prevData) => prevData + 1);
                      }}
                      checkboxLabel={"Workspace editor can grant permission access to permissions they hold"}
                    />
                  </div>
                  <div>
                    <Checkbox
                      forceCheck={disabled}
                      disabled={disabled}
                      isChecked={activeUser?.permission_editor_create}
                      checkboxName={"permission_editor_create"}
                      onChange={(e) => {
                        setActiveUser((prevData) => ({
                          ...prevData,
                          permission_update: e.target.checked && prevData?.permission_editor_update ? true : false,
                          [e.target.name]: e.target.checked,
                        }));
                        setPermissionChanges((prevData) => prevData + 1);
                      }}
                      checkboxLabel={"Allow creation of additional editors to workspace."}
                    />
                  </div>
                </div>
              </div>
            )}
            {data?.code === "settings" && (
              <div className="space-y-4">
                <div className="space-y-3 pl-6">
                  <div>
                    <Checkbox
                      forceCheck={disabled}
                      disabled={disabled}
                      isChecked={activeUser?.layout_update}
                      checkboxName={"layout_update"}
                      onChange={(e) => {
                        setActiveUser((prevData) => ({
                          ...prevData,
                          settings_update: e.target.checked && prevData?.integration_update && prevData?.workspace_filter_update && prevData?.workspace_delete ? true : false,
                          [e.target.name]: e.target.checked,
                        }));
                        setPermissionChanges((prevData) => prevData + 1);
                      }}
                      checkboxLabel={"Allow full access to styling controls"}
                    />
                  </div>
                  <div>
                    <Checkbox
                      forceCheck={disabled}
                      disabled={disabled}
                      isChecked={activeUser?.integration_update}
                      checkboxName={"integration_update"}
                      onChange={(e) => {
                        setActiveUser((prevData) => ({
                          ...prevData,
                          settings_update: e.target.checked && prevData?.layout_update && prevData?.workspace_filter_update && prevData?.workspace_delete ? true : false,
                          [e.target.name]: e.target.checked,
                        }));
                        setPermissionChanges((prevData) => prevData + 1);
                      }}
                      checkboxLabel={"Allow full access to integration controls"}
                    />
                  </div>
                  <div>
                    <Checkbox
                      forceCheck={disabled}
                      disabled={disabled}
                      isChecked={activeUser?.workspace_filter_update}
                      checkboxName={"workspace_filter_update"}
                      onChange={(e) => {
                        setActiveUser((prevData) => ({
                          ...prevData,
                          settings_update: e.target.checked && prevData?.layout_update && prevData?.integration_update && prevData?.workspace_delete ? true : false,
                          [e.target.name]: e.target.checked,
                        }));
                        setPermissionChanges((prevData) => prevData + 1);
                      }}
                      checkboxLabel={"Allow full access to workspace filter controls"}
                    />
                  </div>
                  <div>
                    <Checkbox
                      forceCheck={disabled}
                      disabled={disabled}
                      isChecked={activeUser?.workspace_delete}
                      checkboxName={"workspace_delete"}
                      onChange={(e) => {
                        setActiveUser((prevData) => ({
                          ...prevData,
                          settings_update: e.target.checked && prevData?.layout_update && prevData?.integration_update && prevData?.workspace_filter_update ? true : false,
                          [e.target.name]: e.target.checked,
                        }));
                        setPermissionChanges((prevData) => prevData + 1);
                      }}
                      checkboxLabel={"Allow ability to delete workspace"}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkspacePermissionItem;
