import { connect } from "react-redux";
import { PencilIcon, TrashIcon, UserIcon } from "@heroicons/react/24/outline";
import { authorizeUserComponentAccess } from "src/helpers/authorizeUserComponentAccess";
import DomoIntegrationClient from "./IntegrationClients/DomoIntegrationClient";
import MetabaseIntegrationClient from "./IntegrationClients/MetabaseIntegrationClient";
import Button from "src/components/Shared/Buttons/Button";
import { formatDate } from "src/helpers/formatDate";
import { classNames } from "src/helpers/classNames";
import { workspaceIntegrationCredentialMakeDefault } from "src/actions/workspace";
import { useState } from "react";
import { toast } from "react-toastify";

const WorkspaceIntegrationClientsItem = ({
  workspaceId,
  user,
  integration,
  credential,
  editCredential = () => {},
  openCreatedByModal = () => {},
  removeCredential = () => {},
  makeDefaultButtonDisabled = false,
  setMakeDefaultButtonDisabled = () => {},
  refreshIntegrations = () => {},
  ...props
}) => {
  const [makeDefaultIsLoading, setMakeDefaultIsLoading] = useState(false);

  const getAttributeValue = (name) => {
    return credential.embed_attributes.find((embed_attribute) => embed_attribute.key === name)?.value;
  };

  const makeDefault = async () => {
    try {
      setMakeDefaultButtonDisabled(true);
      setMakeDefaultIsLoading(true);
      await props.workspaceIntegrationCredentialMakeDefault({ integration_id: integration._id, workspace_id: workspaceId, credential_id: credential?._id });
      setMakeDefaultIsLoading(false);
      setMakeDefaultButtonDisabled(false);
      refreshIntegrations();
    } catch (error) {
      setMakeDefaultIsLoading(false);
      setMakeDefaultButtonDisabled(false);
      toast.error(error.message);
    }
  };

  return (
    <div className="flex h-12 w-full overflow-hidden rounded-md border border-gray-50 px-4 py-1 shadow">
      <div className="flex w-2/3">
        {integration?.code === "domo" && (
          <DomoIntegrationClient
            credential={credential}
            getAttributeValue={getAttributeValue}
          />
        )}
        {integration?.code === "metabase" && (
          <MetabaseIntegrationClient
            credential={credential}
            getAttributeValue={getAttributeValue}
          />
        )}
      </div>

      <div className="flex w-1/3 items-center justify-between">
        <div className="flex flex-col items-center justify-center">
          {credential?.user?.name && <div className="text-sm font-semibold text-gray-800">{credential?.user?.name}</div>}
          <div className="flex items-center text-xs text-gray-400">Created {formatDate(credential?.created_at, "MM/DD/YYYY")}</div>
        </div>
        <div className="flex gap-x-1">
          {authorizeUserComponentAccess(user, workspaceId, "integration", ["update"]) && (
            <>
              {workspaceId && credential?.created_by?.toString() === user?._id?.toString() && (
                <Button
                  disabled={makeDefaultButtonDisabled || credential?.default_users_credential?.includes(user?._id)}
                  loading={makeDefaultIsLoading}
                  onClick={makeDefault}
                  version={credential?.default_users_credential?.includes(user?._id) ? "default" : "gray"}
                  className={classNames("flex items-center justify-center rounded-md px-2 text-xs font-semibold leading-5", credential?.default_users_credential?.includes(user?._id) ? "bg-green-200 text-green-700 opacity-50" : "bg-gray-50 text-gray-500")}>
                  {credential?.default_users_credential?.includes(user?._id) ? "Default" : "Make default"}
                </Button>
              )}
              {user?.type === "admin" && (
                <Button
                  type="button"
                  version="default"
                  onClick={() => openCreatedByModal(integration, credential)}
                  className="flex h-8 w-8 items-center justify-center rounded-md border-gray-200 text-gray-500 outline-none ring-0 transition-all duration-200 hover:bg-gray-50 hover:text-gray-600">
                  <UserIcon className="h-5 w-5" />
                </Button>
              )}
              <Button
                type="button"
                version="default"
                onClick={() =>
                  editCredential(
                    integration,
                    integration?.embed_types.find((embed_type) => embed_type.type === credential?.integration_type),
                    credential,
                  )
                }
                className="flex h-8 w-8 items-center justify-center rounded-md border-gray-200 text-gray-500 outline-none ring-0 transition-all duration-200 hover:bg-gray-50 hover:text-gray-600">
                <PencilIcon className="h-5 w-5" />
              </Button>
              <Button
                type="button"
                version="default"
                onClick={() => removeCredential(integration._id, credential?._id)}
                className="flex h-8 w-8 items-center justify-center rounded-md border-gray-200 text-gray-500 outline-none ring-0 transition-all duration-200 hover:bg-gray-50 hover:text-gray-600">
                <TrashIcon className="h-5 w-5" />
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};
export default connect(mapStateToProps, { workspaceIntegrationCredentialMakeDefault })(WorkspaceIntegrationClientsItem);
