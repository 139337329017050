import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getPages } from "src/actions/page";
import { apiRequest } from "src/async/apiUtils";
import ActivityEntry from "src/components/Activity/ActivityEntry";
import DateSearch from "src/components/Activity/DateSearch";
import PaginationFooter from "src/components/Shared/PaginationFooter";
import MultiSelectObjectFilter from "src/components/Shared/Table/MultiRowSelect/MultiSelectObjectFilter";
import SortOrder from "src/components/Shared/Table/SortOrder";
import TRHeader from "src/components/Shared/Table/TRHeader";
import Table from "src/components/Shared/Table/Table";
import TableOptions from "src/components/Shared/TableOptions";
import { formatDate } from "src/helpers/formatDate";
import useFetch from "src/hooks/useFetch";
import DataExport from "src/components/Shared/DataExport";

const ActivityList = ({ props }) => {
  const activityTypes = [
    { key: "PAGE_VIEW", value: "Page View" },
    { key: "LOGIN", value: "Logins/Authentication" },
  ];
  const [sortDirection, setSortDirection] = useState("ASC");

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [startDate, setStateDate] = useState("");
  const [endDate, setEndDate] = useState("");

  // Filters
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedActivityTypes, setSelectedActivityTypes] = useState([]);
  const [selectedPages, setSelectedPages] = useState([]);
  // const [deleteId, setDeleteId] = useState(null);

  const handleSelectedItems = (itemsArray, setSelected) => {
    let updatedSelected = itemsArray.reduce((filteredItemsArray, item) => (item.selected ? [...filteredItemsArray, item.key] : filteredItemsArray), []);
    setSelected(updatedSelected);
    setOffset(0);
  };

  const handlePaginationChange = ({ limit, offset }) => {
    setLimit(limit);
    setOffset(offset);
  };

  const {
    response: { data: pages },
  } = useFetch(`/pages/list`);

  const {
    response: { data: users },
  } = useFetch(`/users/list`);

  const {
    response: { data: activities, meta: activitiesMeta },
    status: { done: activitiesLoaded },
    refreshData: refreshActivities,
  } = useFetch(`/activities/list-activity`, {
    method: "PUT",
    query: {
      limit: limit,
      offset: offset * limit,
      start_date: startDate,
      end_date: endDate,
      sort_direction: sortDirection,
    },
    data: {
      users: selectedUsers,
      pages: selectedPages,
      activity_types: selectedActivityTypes,
    },
  });

  useEffect(() => {
    refreshActivities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, offset, startDate, endDate, selectedUsers, selectedActivityTypes, selectedPages, sortDirection]);

  const exportData = async () => {
    const activityParams = {
      start_date: startDate,
      end_date: endDate,
      sort_direction: sortDirection,
    };
    const activityBody = {
      users: selectedUsers,
      pages: selectedPages,
      activity_types: selectedActivityTypes,
    };
    try {
      const { data: responseData } = await apiRequest("put", "/activities/list-activity", { body: activityBody, queries: activityParams }, {}, true);
      let csvData = [];
      if (responseData.data.length > 0) {
        for (let index = 0; index < responseData.data.length; index++) {
          const element = responseData.data[index];
          let activity_types = element?.type;
          let typeIndex = activityTypes.findIndex((ty) => ty.key === element?.type);
          if (typeIndex !== -1) {
            activity_types = activityTypes[typeIndex].value;
          }
          csvData.push({
            Email: element?.user_id?.email,
            Name: element?.user_id?.name,
            "Activity Type": activity_types,
            Workspace: element?.data?.workspace_id?.name,
            "Page Accessed": element?.data?.page_id?.name,
            Date: formatDate(element?.created_at, "LLL"),
          });
        }
      }
      return csvData;
    } catch (error) {
      // console.dir("ERROR:", error);
    }
  };

  return (
    <>
      <TableOptions
        leftContent={
          <DateSearch
            setStateDate={setStateDate}
            setEndDate={setEndDate}
          />
        }
      />
      <Table
        tableHeader={
          <TRHeader>
            <th
              scope="col"
              className="rounded-tl-lg py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
              <div className="flex items-center gap-x-1">
                <p>Name</p>
                <div className="flex">
                  <MultiSelectObjectFilter
                    filterSelect={true}
                    defaultOptions={users?.map((user) => {
                      return { key: user._id, value: user.name, selected: selectedUsers.includes(user._id), object: user };
                    })}
                    searchableFields={["name", "email"]}
                    onChange={(e) => {
                      handleSelectedItems(e, setSelectedUsers);
                    }}
                  />
                </div>
              </div>
            </th>
            <th
              scope="col"
              className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
              <div className="flex items-center gap-x-1">
                <p>Activity Type</p>
                <div className="flex">
                  <MultiSelectObjectFilter
                    filterSelect={true}
                    defaultOptions={activityTypes.map((type) => {
                      return { key: type.key, value: type.value, selected: selectedActivityTypes.includes(type.key), object: { _id: type.key, ...type } };
                    })}
                    onChange={(e) => {
                      handleSelectedItems(e, setSelectedActivityTypes);
                    }}
                  />
                </div>
              </div>
            </th>
            <th
              scope="col"
              className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
              <div className="flex items-center gap-x-1">
                <p>
                  {" "}
                  Page <span className="hidden md:inline">Accessed</span>
                </p>
                <div className="flex">
                  <MultiSelectObjectFilter
                    filterSelect={true}
                    defaultOptions={pages.reduce((acc, page) => {
                      const isDuplicate = acc.some((option) => option.key === page._id);
                      if (!isDuplicate) {
                        acc.push({ key: page._id, value: page.name, selected: selectedPages.includes(page._id), object: page });
                      }
                      return acc;
                    }, [])}
                    searchableFields={["name"]}
                    onChange={(e) => {
                      handleSelectedItems(e, setSelectedPages);
                    }}
                  />
                </div>
              </div>
            </th>
            <th
              scope="col"
              className="table-cell rounded-tr-lg px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              <div className="flex items-center gap-x-1">
                <p>Date</p>
                <SortOrder
                  sortDirection={sortDirection}
                  setSortDirection={(value) => {
                    setSortDirection(value);
                    setOffset(0);
                  }}
                />
              </div>
            </th>
          </TRHeader>
        }
        colSpan="4"
        loaded={activitiesLoaded}
        dataExists={activities.length > 0}>
        {activities.map((activity) => (
          <ActivityEntry
            key={activity._id}
            activity={activity}
            dataExists={activities.length > 0}
          />
        ))}
      </Table>

      <PaginationFooter
        itemName="Activity record"
        limit={limit}
        offset={offset}
        count={activitiesMeta.count}
        onChange={handlePaginationChange}
      />
      {activities.length > 0 && (
        <DataExport
          title="Activity Report"
          headings={["Email", "Name", "Activity Type", "Workspace", "Page Accessed", "Date"]}
          prepData={exportData}
        />
      )}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    pages: Object.values(state.pages),
  };
};
export default connect(mapStateToProps, {
  getPages,
})(ActivityList);
