import { connect } from "react-redux";
import { Link } from "react-router-dom";

const LandingPage = ({ from, workspaceId, pageId, pageName, navigationBlocks }) => {
  return (
    <div className="w-full p-8">
      <div className="mb-10 grid grid-cols-3 gap-x-4 gap-y-10">
        {navigationBlocks.map((navigationBlock, index) => {
          return (
            <div
              className="relative"
              key={navigationBlock?._id}>
              <div className="space-y-2 rounded p-2 ring-sky-600 transition-all duration-200 hover:ring-2">
                <div className="text-xl font-bold text-gray-700">{navigationBlock.title}</div>
                <div className="w-full overflow-hidden rounded">
                  <img
                    className=""
                    height={navigationBlock.height}
                    width={navigationBlock.width}
                    src={navigationBlock.image + "?" + Date.now()}
                    alt=""
                  />
                </div>
              </div>
              {navigationBlock.links.length > 0 && (
                <div className="space-y-2 p-2">
                  {navigationBlock.links.map((link, index2) => {
                    return (
                      <div
                        className="flex"
                        key={link?._id}>
                        {link.page_id?.page_type === "EXTERNAL_LINK" ? (
                          <a
                            href={from === "previous" ? "#" : link.page_id?.page_link}
                            target={link.page_id?.page_link_newtab ? "_blank" : "_self"}
                            className="text-base font-semibold text-sky-600 transition-all duration-200 hover:text-sky-800"
                            rel="noreferrer">
                            {link.link_text}
                          </a>
                        ) : (
                          <Link
                            to={from === "previous" ? "#" : `/workspace/${workspaceId}/link/${link.page_id?._id}`}
                            className="text-base font-semibold text-sky-600 transition-all duration-200 hover:text-sky-800">
                            {link.link_text}
                          </Link>
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, {})(LandingPage);
