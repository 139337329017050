import { useEffect, useState } from "react";
import { getPageCredentialOptions } from "src/actions/page";
import TextAccordion from "src/components/Shared/Accordions/TextAccordion";
import Input from "src/components/Shared/Forms/Inputs/Input";
import Toggle from "src/components/Shared/Forms/Toggles/Toggle";
import Modal from "src/components/Shared/Modal";
import { H5 } from "src/components/Shared/Text/Headers";
import { connect } from "react-redux";
import useFetch from "src/hooks/useFetch";
import { apiRequest } from "src/async/apiUtils";

const WorkspacesAddModal = ({ isOpen, onCancel = () => {}, onSuccess = () => {}, getPageCredentialOptions, credentials = [] }) => {
  const [disabled, setDisabled] = useState(false);
  const [workspaceTypeName, setWorkspaceTypeName] = useState("");
  const [workspaceType, setWorkspaceType] = useState("IFRAME_EMBED");

  const [name, setName] = useState("");
  const [subscriberInstanceURL, setSubscriberInstanceURL] = useState("");
  const [keyAttributeValue, setKeyAttributeValue] = useState("");
  const [clientId, setClientId] = useState("");
  const [clientSecret, setClientSecret] = useState("");

  const [, setIsAvailableEditEmbedSettings] = useState(false);

  const {
    response: { data: integrations },
  } = useFetch("/pages/integration");

  const loadPageCreds = async () => {
    // setCredLoading(true);
    const ac = new AbortController();
    try {
      await getPageCredentialOptions({}, ac.signal);
    } catch (error) {
      // console.dir("ERROR:", error);
    }
    return () => ac.abort();
  };

  useEffect(() => {
    loadPageCreds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    for (const credential of credentials) {
      if (credential?.jwt_secret_count > 0 && credential?.embed_url && credential?.key_attribute) {
        setIsAvailableEditEmbedSettings(true);
      } else {
        setIsAvailableEditEmbedSettings(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentials]);

  const updateWorkspaceType = () => {
    let type = "IFRAME_EMBED";
    if (workspaceType === "IFRAME_EMBED") {
      type = "JWT_FULL_EMBED";
    }
    setWorkspaceType(type);
  };

  const createWorkspace = async () => {
    try {
      setDisabled(true);

      const { data } = await apiRequest(
        "POST",
        `/workspaces/add`,
        {
          body: {
            name,
            subscriber_instance_url: subscriberInstanceURL,
            key_attribute_value: keyAttributeValue,
            client_id: clientId,
            client_secret: clientSecret,
            workspace_type: workspaceType,
          },
        },
        {
          showToastMessage: true, onSuccess: () => {}, onFailure: () => {}
        }
      );
      if (data.status === 200) {
        onSuccess();
        setName("");
      }

      setTimeout(() => setDisabled(false), 300);
    } catch (error) {
      setTimeout(() => setDisabled(false), 300);
    }
  };

  useEffect(() => {
    if (workspaceType) {
      setWorkspaceTypeName(workspaceType.charAt(0).toUpperCase() + workspaceType.slice(1).toLowerCase().replace("_", " "));
      setSubscriberInstanceURL("");
      setKeyAttributeValue("");
      setClientId("");
      setClientSecret("");
    }
  }, [workspaceType]);

  const onClear = () => {
    setName("")
    setWorkspaceType("IFRAME_EMBED")
  }

  useEffect(() => {
    if (!isOpen) {
      onClear()
    }
  }, [ isOpen ])

  return (
    <Modal
      title="Workspace"
      secondaryTitle={`Create ${workspaceTypeName}`}
      isOpen={isOpen}
      onCancel={() => {
        onCancel()
        onClear()
      }}
      onSuccess={createWorkspace}
      defaultOptions={{
        onSuccessButtonText: "Save",
        onSuccessLoaderVisible: disabled,
        onSuccessLoaderStart: disabled,
      }}>
      <div className="relative w-full space-y-4">
        <div className="relative w-full mb-4">
          <Input
            name="name"
            label="Workspace name"
            autoComplete="off"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        {integrations?.length > 0 && (
          <TextAccordion
            headerTextPosition="left"
            headerText="Advanced details">
            <div className="flex md:flex-row flex-col gap-y-3">
              <Toggle
                checked={workspaceType === "JWT_FULL_EMBED"}
                onChange={updateWorkspaceType}
              />
              <H5 caption="Embed a full BI tool for your clients to use.">Full JWT application Embed</H5>
            </div>
          </TextAccordion>
        )}
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    credentials: state.pageCredentialOptions,
  };
};

export default connect(mapStateToProps, { getPageCredentialOptions })(WorkspacesAddModal);
