import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { refreshTokenVerify } from "src/actions/auth";
import { apiUrl } from "src/config/host";
import { urlBuilder } from "src/helpers/urlBuilder";

const useFetch = (url, options = {}, token = "", canCall = true) => {
  let accessToken = localStorage.getItem("access_token");
  let headers = { "Content-Type": "application/json", Accept: "application/json" };
  if (accessToken) {
    headers = {
      ...headers,
      Authorization: `Bearer ${accessToken}`,
    };
  }

  const [response, setResponse] = useState({ data: [], meta: {} });
  const [status, setStatus] = useState({ idle: true, fetching: false, done: false, fail: false });

  let params = options.params;
  delete options.params;

  const optionsRef = useRef({
    headers,
    ...options,
  });

  const requestData = () => {
    setStatus({ idle: false, fetching: true, done: false, fail: false });
    (async () => {
      try {
        let response = await axios(apiUrl + urlBuilder(url, params), { ...optionsRef.current, data: options.data, params: options.query, method: optionsRef.current.method || "get" });

        if (response.status === 401 && response.data?.invalid) {
          localStorage.removeItem("userId");
          localStorage.removeItem("access_token");
          localStorage.removeItem("refresh_token");

          window.location.href = "/login";
          return;
        }

        if (response.status === 401 && response.data?.expired) {
          const { refreshTokenRes, refreshTokenData } = await refreshTokenVerify();

          if (refreshTokenRes.status === 200) {
            optionsRef.current.headers.Authorization = `Bearer ${refreshTokenData.refresh_token}`;

            response = await axios(apiUrl + urlBuilder(url, params), { ...optionsRef.current, data: options.data, params: options.query, method: optionsRef.current.method || "get" });
          } else {
            // localStorage.removeItem('access_token')
            // localStorage.removeItem('refresh_token')
            // window.location.href = '/login'
          }
        }

        setResponse(response.data);
        setStatus({ idle: false, fetching: false, done: true, fail: false });
      } catch (e) {
        if (e?.response?.status === 401 && e?.response?.data?.invalid) {
          if (["/", "/login", "/logout", "/logout/callback", "/forgot-password/submit-email", "/forgot-password/reset-password/", "/token/", "/technical-issues"].some((item) => window.location.pathname.includes(item))) {
            return;
          } else {
            localStorage.removeItem("userId");
            localStorage.removeItem("access_token");
            localStorage.removeItem("refresh_token");

            window.location.href = "/login";
            return;
          }
        }

        if (e?.response?.status === 401 && e?.response?.data?.expired) {
          const { refreshTokenRes, refreshTokenData } = await refreshTokenVerify();

          if (refreshTokenRes.status !== 200) {
            // localStorage.removeItem('access_token')
            // localStorage.removeItem('refresh_token')

            // window.location.href = '/login'

            setStatus({ idle: false, fetching: false, done: false, fail: true });
          } else {
            optionsRef.current.headers.Authorization = `Bearer ${refreshTokenData.refresh_token}`;

            const response = await axios(apiUrl + urlBuilder(url, params), { ...optionsRef.current, data: options.data, params: options.query, method: optionsRef.current.method || "get" });

            setResponse(response.data);
            setStatus({ idle: false, fetching: false, done: true, fail: false });
          }
        } else {
          setStatus({ idle: false, fetching: false, done: false, fail: true });
        }
      }
    })();
  };

  useEffect(() => {
    if (url && canCall) {
      requestData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, canCall]);

  useEffect(() => {
    if (token && canCall) {
      optionsRef.current.headers.Authorization = `Bearer ${token}`;
      requestData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, canCall]);

  const refreshData = () => {
    if (url) {
      requestData();
    }
  };

  return { response, status, refreshData };
};

export default useFetch;
