import { useContext, useEffect } from "react";
import { connect } from "react-redux";
import { getDomoWorkspaceFirstPage } from "src/actions/page";
import Button from "src/components/Shared/Buttons/Button";
import Image from "src/components/Shared/Image";
import Preloader from "src/components/Shared/LoadingAnimations/Preloader";
import { UserWorkspaceContext } from "src/context/UserWorkspaceContext";

function Workspaces({ user, site }) {
  const { workspaceLoading, workspaces: workspaceList } = useContext(UserWorkspaceContext);

  const replyClick = async (wrk) => {
    try {
      const response = await getDomoWorkspaceFirstPage({ clicked_id: wrk?._id, user_id: user._id });
      if (response.status === 200) {
        window.open(response.link, response.target);
      }
    } catch (error) {
      // console.dir("ERROR:", error);
    }
  };

  useEffect(() => {
    if (workspaceList.length === 1) {
      replyClick(workspaceList[0]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceList.length === 1]);

  if (workspaceLoading || workspaceList.length === 1) {
    return (
      <div className="flex h-full w-full justify-center">
        <Preloader />
      </div>
    );
  }

  return (
    <div className="relative flex min-h-screen">
      <div
        className="relative w-full transition-all"
        id="content">
        <div
          className="bg-texture min-h-[800px] w-full px-5 py-8"
          id="pageContent">
          <div className="mx-auto my-32 w-full max-w-3xl rounded-lg bg-white  p-8 shadow-lg">
            <div className="my-8">
              <div className="text-light text-center text-2xl text-gray-700">Choose your workspace</div>
              <div className="-mx-4 mt-8 flex flex-wrap justify-center">
                {workspaceList.map((wrk, index) => {
                  return (
                    <div
                      className="relative m-4"
                      key={wrk?._id}>
                      <Button
                        onClick={(e) => {
                          replyClick(wrk);
                        }}
                        style={{ backgroundColor: wrk?.top_bar_color }}
                        version="default"
                        className="duration-50 flex h-32 w-32 items-center justify-center overflow-hidden rounded-[20px] border-2 border-gray-200 bg-white p-4 transition-all hover:border-blue-600">
                        <Image
                          className="max-h-[60px]"
                          image={wrk.image_logo || wrk.image_favicon || wrk.square_logo}
                          alt={"Workspace"}
                        />
                        {/* <img
                          className="w-full h-full max-h-[60px] object-contain"
                          src={wrk.image_logo?.includes(".s3.") ? wrk.image_logo + "?" + Date.now() : wrk.image_logo?.includes("data:image/") ? wrk.image_logo : baseUrl + wrk.image_logo}
                          onError={(e) => (e.target.src = `${noLogo}`)}
                          alt=""
                        /> */}
                      </Button>
                      <div className="mt-4 text-center text-base font-medium text-gray-600">{wrk?.name}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    site: state.site,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, {})(Workspaces);
