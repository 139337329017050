import React from "react";
import MultiSelectObject from "src/components/Shared/Forms/Selects/MultiSelectObject/MultiSelectObject";

export default function MultiSelectObjectUsers({ usersLoaded = false, users = [], selectedUserIds = [], handleSelectedUsers = () => {}, strictOnChangeMode = true }) {
  return (
    <MultiSelectObject
      strictOnChangeMode={strictOnChangeMode}
      defaultOptions={
        usersLoaded &&
        users.map((user) => {
          return { key: user._id || user.id, value: user.name, secondaryValue: user.email, selected: selectedUserIds.includes(user.id || user._id), object: user };
        })
      }
      searchComponent={(object) => {
        return (
          <div className="flex relative items-center justify-center gap-x-2">
            <div>{object.name}</div>
            <div className="text-sm text-gray-400">{object.email}</div>
          </div>
        );
      }}
      bubbleComponent={(object) => {
        return (
          <div className="relative px-1 flex flex-col gap-x-2">
            <div className="leading-4">{object.name}</div>
            <div className="leading-4 text-xs text-gray-400">{object.email}</div>
          </div>
        );
      }}
      searchableFields={["name", "email"]}
      title="Assign users"
      onChange={handleSelectedUsers}
    />
  );
}
