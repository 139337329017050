import { CheckIcon, InformationCircleIcon, NoSymbolIcon, PhotoIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { getAuthorizedUser } from "src/actions/auth";
import { manageWorkspaceSettings } from "src/actions/workspace";
import LeftNavigation from "src/assets/svgs/left_navigation.svg";
import TopNavigation from "src/assets/svgs/top_navigation.svg";
import EditContainer from "src/components/Shared/Containers/EditContainer";
import Section from "src/components/Shared/Containers/Section";
import SelectMenu from "src/components/Shared/Forms/Selects/SelectMenu";
import Toggle from "src/components/Shared/Forms/Toggles/Toggle";
import ToggleHeader from "src/components/Shared/Forms/Toggles/ToggleHeader";
import StylesBuilder from "src/components/Shared/Styles/StylesBuilder";
import { H2, H4 } from "src/components/Shared/Text/Headers";
import { baseUrl, noFavicon, noLogo, publicImageStorage } from "src/config/host";
import { classNames } from "src/helpers/classNames";
import useFetch from "src/hooks/useFetch";

const WorkspaceLayoutStyles = ({ setWorkspace = () => {}, ...props }) => {
  const allowedExts = ["jpg", "jpeg", "png", "bmp", "gif", "svg", "webp", "avif"];

  const { id } = useParams();
  const [layoutType, setLayoutType] = useState("");
  const [layoutLandingPageId, setLayoutLandingPageId] = useState(null);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [buttonIsDisabled, setButtonIsDisabled] = useState(true);
  const [squareLogoButtonLoader, setSquareLogoButtonLoader] = useState(false);
  const [squareLogoButtonIsDisabled, setSquareLogoButtonIsDisabled] = useState(true);
  const [useGlobalSettings, setUseGlobalSettings] = useState(false);
  const [updatedSite, setUpdatedSite] = useState({});

  const options = [
    {
      value: "LEFT_NAVIGATION",
      key: (
        <div className="flex h-7 items-center gap-x-5">
          <img
            className="h-7 w-7 hover:stroke-highlightColor"
            src={LeftNavigation}
            alt="left navigation"
          />
          <p className="text-xl">Vertical</p>
        </div>
      ),
    },
    {
      value: "TOP_NAVIGATION",
      key: (
        <div className="flex h-7 items-center gap-x-5">
          <img
            className="h-7 w-7 hover:stroke-highlightColor hover:text-highlightColor"
            src={TopNavigation}
            alt="top navigation"
          />
          <p className="text-xl">Horizontal</p>
        </div>
      ),
    },
  ];

  const {
    response: { data: workspace },
    refreshData: refreshWorkspace,
  } = useFetch(`/workspaces/:workspace_id`, {params: { workspace_id: id }});

  useEffect(() => {
    const setUser = async () => {
      await props.getAuthorizedUser();
    };
    setUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (workspace?.layout_type) setLayoutType(workspace?.layout_type);
  }, [workspace?.layout_type]);

  useEffect(() => {
    setLayoutLandingPageId(workspace?.layout_landing_page_id);
  }, [workspace?.layout_landing_page_id]);

  useEffect(() => {
    setUseGlobalSettings(workspace?.use_global_settings);
  }, [workspace?.use_global_settings]);

  useEffect(() => {
    if (workspace?._id) {
      setUpdatedSite(getSiteData());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspace?._id]);

  useEffect(() => {
    refreshWorkspace();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.workspaceDetails?.name]);

  const getSiteData = (type = "all") => {
    if (type === "all") {
      return {
        _id: workspace._id,
        style_key: "workspace_id",
        name: workspace.name,
        image_logo: workspace.image_logo || "",
        square_logo: workspace.square_logo || "",
        image_favicon: workspace.image_favicon || noFavicon,
        top_bar_color: workspace?.top_bar_color || "#FFFFFF",
        top_bar_text_color: workspace?.top_bar_text_color || "#FFFFFF",
        side_bar_color: workspace?.side_bar_color || "#FFFFFF",
        side_bar_text_color: workspace?.side_bar_text_color || "#FFFFFF",
        side_bar_shade_color: workspace?.side_bar_shade_color || "#FFFFFF",
        left_nav_active_text_color: workspace?.left_nav_active_text_color || "#FFFFFF",
        highlight_color: workspace?.highlight_color || "#FFFFFF",
        background_color: workspace?.background_color || "#FFFFFF",
        sso_button_color: workspace.sso_button_color,
        sso_button_text_color: workspace.sso_button_text_color,
        use_global_settings: workspace.use_global_settings || false,
        max_width: workspace.max_width,
        layout_type: workspace.layout_type || "LEFT_NAVIGATION",
      };
    } else if (type === "custom_colors") {
      return {
        _id: workspace._id,
        style_key: "workspace_id",
        name: workspace.name,
        image_logo: workspace.image_logo || "",
        image_favicon: workspace.image_favicon || noFavicon,
        top_bar_color: workspace?.top_bar_color || "#FFFFFF",
        top_bar_text_color: workspace?.top_bar_text_color || "#FFFFFF",
        side_bar_color: workspace?.side_bar_color || "#FFFFFF",
        side_bar_text_color: workspace?.side_bar_text_color || "#FFFFFF",
        side_bar_shade_color: workspace?.side_bar_shade_color || "#FFFFFF",
        left_nav_active_text_color: workspace?.left_nav_active_text_color || "#FFFFFF",
        highlight_color: workspace?.highlight_color || "#FFFFFF",
        background_color: workspace?.background_color || "#FFFFFF",
        max_width: workspace.max_width,
      };
    } else if (type === "white_label") {
      return {
        _id: workspace._id,
        style_key: "workspace_id",
        use_global_settings: workspace.use_global_settings || false,
      };
    } else if (type === "square_logo") {
      return {
        _id: workspace._id,
        style_key: "workspace_id",
        square_logo: workspace.square_logo || "",
      };
    } else if (type === "portal_layout") {
      return {
        _id: workspace._id,
        style_key: "workspace_id",
        layout_type: workspace.layout_type || "LEFT_NAVIGATION",
      };
    }
  };

  const handleStylesDataChange = (keyValue = {}) => {
    setButtonIsDisabled(false);
    setUpdatedSite((updatedSite) => ({ ...updatedSite, ...keyValue }));
  };

  const handleSquareLogoStylesDataChange = (keyValue = {}) => {
    setSquareLogoButtonIsDisabled(false);
    setUpdatedSite((updatedSite) => ({ ...updatedSite, ...keyValue }));
  };

  const handleStylesUpdate = async (body) => {
    let updatedValues = body ? { ...body, name: props.workspaceDetails?.name } : updatedSite;
    try {
      setButtonLoader(true);

      if (updatedValues?.max_width && !/^(\d{1,2}(\.\d+)?%|100%|\d+(?:\.\d+)?px)$/.test(updatedValues.max_width)) {
        toast.error("Please enter a valid max width. For example, 100% or 100px.");
        setButtonLoader(false);
        return;
      }

      if (updatedValues.image_favicon !== workspace.image_favicon) {
        // await apiRequest("DELETE", `/workspaces/:workspace_id/settings/images/:image_id`, {params: {workspace_id: updatedValues._id, image_id: "image_favicon"}});
      }
      if (updatedValues.image_logo !== workspace.image_logo) {
        // await apiRequest("DELETE", `/workspaces/:workspace_id/settings/images/:image_id`, {params: {workspace_id: updatedValues._id, image_id: "image_logo"});
      }
      let payload = {
        ...updatedValues,
        workspace_id: updatedValues._id,
        layout_type: layoutType,
      };

      if (layoutType === "NO_NAVIGATION" && workspace.workspace_type === "IFRAME_EMBED") {
        if (!layoutLandingPageId) {
          toast.error('You must select a landing page when "No Navigation" is selected');
          return;
        }
        payload.layout_landing_page_id = layoutLandingPageId;
      }

      const message = await props.manageWorkspaceSettings({
        workspace_id: updatedValues._id,
        ...updatedValues,
        layout_type: layoutType,
        layout_landing_page_id: layoutLandingPageId,
      });

      setWorkspace();
      refreshWorkspace();

      setButtonLoader(false);
      toast.success(message);
    } catch (error) {
      setButtonLoader(false);
      toast.error(error.message);
    } finally {
      setButtonIsDisabled(true);
    }
  };

  const handleStylesUpdateSquareLogo = async (body) => {
    try {
      setSquareLogoButtonLoader(true);
      const message = await props.manageWorkspaceSettings({ workspace_id: body._id, ...body });
      refreshWorkspace();
      setSquareLogoButtonLoader(false);
      toast.success(message);
    } catch (error) {
      setSquareLogoButtonLoader(false);
      toast.error(error.message);
    } finally {
      setSquareLogoButtonIsDisabled(true);
    }
  };

  const onSquareLogoClear = () => {
    setSquareLogoButtonIsDisabled(true);
    setUpdatedSite({
      ...updatedSite,
      ...getSiteData("square_logo"),
    });
  };

  const ImageHandler = ({ image = "", layout = "square" }) => {
    const [currentImage, setCurrentImage] = useState(null);
    const [imageUrl, setImageUrl] = useState();

    const areFilesEqual = (file1, file2) => {
      // Check if both files have the same name, size, and type
      if (!(file1 instanceof File) || !(file2 instanceof File)) return false;

      return file1.name === file2.name && file1.size === file2.size && file1.type === file2.type && file1.lastModified === file2.lastModified;
    };

    let imageSource = useMemo(() => {
      if (image instanceof File && areFilesEqual(image, currentImage)) {
        return imageUrl;
      }

      let objUrl = "";

      if (image instanceof File) {
        objUrl = URL.createObjectURL(image);
        setImageUrl(objUrl);
        setCurrentImage(image);
      }

      return image instanceof File ? objUrl : image?.includes(publicImageStorage) ? image + "?" + Date.now() : image?.includes("data:image/") ? image : baseUrl + image;
    }, [image, currentImage, imageUrl]);

    const imageComponent = useCallback(() => {
      return (
        <div className={`group relative cursor-pointer ${layout === "rectangle" ? "w-40" : "w-20"} h-20 rounded-md border border-gray-200/60 bg-gray-300/20 hover:border-highlightColor`}>
          {updatedSite._id && (
            <img
              src={imageSource}
              alt="Logo"
              className="h-full w-full rounded-lg object-contain"
              onError={(e) => (e.target.src = noLogo)}
            />
          )}
          <>
            <label
              htmlFor={`change_logo_square_logo`}
              className="absolute left-0 top-0 z-40 h-full w-full cursor-pointer">
              <div className="absolute bottom-0 left-0 flex h-10 w-full cursor-pointer items-center justify-center gap-x-2 rounded-b bg-gray-700/60 px-2 py-1 text-lg text-white transition-all duration-200 group-hover:bg-highlightColor">
                <PhotoIcon className="h-6 w-6 stroke-2" />
                Edit
              </div>
            </label>
            <input
              type="file"
              id={`change_logo_square_logo`}
              name={`change_logo_square_logo`}
              className="hidden"
              onChange={(e) => onImageChange(e)}
            />
          </>
          {imageSource && (
            <div
              className="absolute -right-3 -top-3 z-40 hidden h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-200 text-center transition-all hover:bg-gray-300 group-hover:flex"
              onClick={() => {
                handleSquareLogoStylesDataChange({ [`square_logo`]: "" });
                setWorkspace((workspace) => ({ ...workspace, square_logo: null }));
              }}>
              <XMarkIcon className="h-5 w-5 text-gray-600" />
            </div>
          )}
        </div>
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imageSource, layout, props.user, updatedSite._id]);

    return imageComponent();
  };

  const onImageChange = (e, type) => {
    let changeType = "square_logo";
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];

      if (!allowedExts.includes(ext)) {
        toast.error("Please upload a valid image.");
      } else {
        handleSquareLogoStylesDataChange({ [changeType]: e.target.files[0] });

        setWorkspace((workspace) => ({
          ...workspace,
          [changeType]: e.target.files[0],
        }));
      }
    }
  };

  const ImageHandlerMemoized = useMemo(() => <ImageHandler image={updatedSite.square_logo} />, [updatedSite.square_logo]);

  const onClear = (type = "all") => {
    setButtonIsDisabled(true);
    setUpdatedSite({
      ...updatedSite,
      ...getSiteData(type),
    });
  };

  return (
    <Section>
      <div className="">
        <H2 margin={false}>Layout and Styles</H2>
        {updatedSite?._id && (
          <>
            <EditContainer
              title="White Label"
              preview={
                <div className="flex h-10 w-56 items-center">
                  <p className={classNames("flex gap-x-2 rounded px-2 py-1 opacity-60", useGlobalSettings ? "bg-gray-50" : "bg-green-50 text-green-700")}>
                    {useGlobalSettings ? (
                      <>
                        <NoSymbolIcon className="h-5 w-5" /> Disabled
                      </>
                    ) : (
                      <>
                        <CheckIcon className="h-5 w-5" /> Enabled
                      </>
                    )}
                  </p>
                </div>
              }
              onSuccess={handleStylesUpdate}
              onCancel={() => onClear("white_label")}>
              <div className="min-w-[260px]">
                <ToggleHeader
                  title="Allow white labeling"
                  subtitle="Workspace styles will override defaults."
                  position="left">
                  <Toggle
                    checked={!useGlobalSettings}
                    onChange={() => {
                      setUseGlobalSettings(!useGlobalSettings);
                      handleStylesDataChange({ use_global_settings: !useGlobalSettings });
                    }}
                  />
                </ToggleHeader>
              </div>
            </EditContainer>
            <EditContainer
              title="Square Logo"
              preview={
                <div className="flex h-10 w-10 items-center">
                  {updatedSite._id && (
                    <img
                      src={updatedSite.square_logo instanceof File ? URL.createObjectURL(updatedSite.square_logo) : updatedSite.square_logo}
                      alt="Logo"
                      className="h-full w-full rounded-lg object-contain"
                      onError={(e) => (e.target.src = noLogo)}
                    />
                  )}
                </div>
              }
              onSuccess={() => handleStylesUpdateSquareLogo({ _id: updatedSite._id, square_logo: updatedSite.square_logo })}
              onCancel={onSquareLogoClear}
              defaultOpen={!updatedSite.square_logo}>
              <>
                {/* {!siteOnly && ( */}
                <div className="flex items-end justify-between">
                  <div className="flex-shrink">
                    <div className="text-base font-semibold text-gray-700">Used for dropdowns and buttons.</div>
                    <div className="mb-2 text-sm font-medium text-gray-400">Recomended image ratio is 1x1</div>
                    <div className="flex gap-x-7">
                      {ImageHandlerMemoized}
                      {/* <div className="flex items-center space-x-2 ml-auto">
                    <Button onClick={() => handleStylesUpdateSquareLogo({ _id: updatedSite._id, square_logo: updatedSite.square_logo })}>Upload</Button>
                    <Button
                    version="gray"
                    onClick={onSquareLogoClear}>
                    Undo
                    </Button>
                  </div> */}
                    </div>
                    {!updatedSite.square_logo && (
                      <div className="mt-4 flex items-center gap-x-2 rounded border border-blue-600 pr-2 text-blue-800">
                        <div className="rounded rounded-r-none bg-blue-200 px-2 py-1 text-lg font-bold">
                          <InformationCircleIcon className="h-6 w-6 stroke-2" />
                        </div>{" "}
                        Add a unique logo to tell workspaces apart
                      </div>
                    )}
                  </div>
                </div>
                {/* )} */}
              </>
            </EditContainer>
            {workspace.workspace_type === "IFRAME_EMBED" && (
              <EditContainer
                title="Layout"
                preview={<div className="flex h-10 w-56 items-center">{options[options.findIndex((option) => option.value === layoutType)]?.key}</div>}
                onSuccess={handleStylesUpdate}
                onCancel={onClear}>
                <>
                  <H4
                    caption="*Options for this workspace's end user experience."
                    margin={false}>
                    Select orientation
                  </H4>
                  <div className="mt-1 w-56">
                    <SelectMenu
                      size="md"
                      options={options}
                      startIndex={options.findIndex((option) => option.value === layoutType)}
                      setOption={(option) => {
                        setLayoutType(option.value);
                        handleStylesDataChange({ layout_type: option.value });
                      }}
                    />
                  </div>
                </>
              </EditContainer>
            )}
            <EditContainer
              title="Colors"
              preview={<div className="flex h-10 items-center">Customize this workspace's themes and styles.</div>}
              onSuccess={handleStylesUpdate}
              onCancel={onClear}
              defaultOpen={true}
              fullWidth={true}
              defaultOptions={{
                onSuccessButtonVisible: false,
                onCancelButtonVisible: false,
              }}
              borderBottom={false}>
              <div className="mt-8">
                <StylesBuilder
                  data={workspace}
                  propsButtonOption={{
                    buttonLoader: buttonLoader,
                    buttonIsDisabled: buttonIsDisabled,
                  }}
                  setButtonIsDisabled={setButtonIsDisabled}
                  onSubmit={handleStylesUpdate}
                  updatedSite={updatedSite}
                  setUpdatedSite={setUpdatedSite}
                  getSiteData={getSiteData}
                  handleStylesDataChange={handleStylesDataChange}
                  title="Layout & Styles"
                  secondaryTitle="(Colors, logo, favicon, etc.)"
                  layoutType={layoutType}
                  setWorkspace={setWorkspace}
                />
              </div>
            </EditContainer>
          </>
        )}
      </div>
    </Section>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
    workspaceDetails: state.workspaceDetails,
  };
};

export default connect(mapStateToProps, { getAuthorizedUser, manageWorkspaceSettings })(WorkspaceLayoutStyles);
