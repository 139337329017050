import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { classNames } from "src/helpers/classNames";
import { getDomoPageList } from "src/actions/page";
import Preloader from "src/components/Shared/LoadingAnimations/Preloader";
import { apiRequest } from "src/async/apiUtils";

const EmbedWorkspace = ({ user = {}, site = {}, workspaceDetails = {}, getDomoPageList = () => {} }) => {
  const { workspaceId, pageId } = useParams();

  const [loadingPage, setLoadingPage] = useState(true);
  const [srcDoc, setSrcDoc] = useState("");

  const getDomoPageDetails = async (body, signal = null) => {
    try {
      const userId = localStorage.getItem("impersonatedUserId");
      const url = `embed/items/:workspace_slug/:page_slug/:user_id`;

      const {data} = await apiRequest("GET", `/${url}`, {params: {workspace_slug: body.workspaceId, page_slug: body.pageId, user_id: userId}}, {signal});

      if (data.status === 200) {
        return data;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

  const pageError = `
    <div class="w-full min-h-full flex flex-col justify-center py-12 px-2 sm:px-6 lg:px-8">
      <div class="sm:mx-auto sm:w-full sm:max-w-md flex flex-col items-center">
        <button
          onclick="location.reload()"
          class="bg-gray-50 rounded-md py-2 px-3 flex items-center gap-x-5 group cursor-pointer shadow-md hover:shadow-lg transition-all duration-150 hover:bg-gray-100">
          <p class="h-full flex items-center text-2xl group-hover:text-gray-500 text-gray-400 transition-all duration-150 pb-1">Reload Page</p>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" data-slot="icon" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
          </svg>

        </button>
        <h2 class="mt-6 text-center text-3xl sm:text-3xl font-extrabold text-gray-900">Oop... there was a loading error.</h2>
        <div class="mt-1 text-center text-regular text-gray-600">Double check your credentials.<br/>It appears the credentials that were added were <span class="font-semibold">invalid</span>.</div>
      </div>
    </div>
  `;

  const getPage = async (signal = null) => {
    setLoadingPage(true);
    setSrcDoc("");

    try {
      const res = await getDomoPageDetails(
        {
          pageId,
          workspaceId,
        },
        signal
      );

      if (res.page_type === "CUSTOM_HTML") {
        setSrcDoc(res.custom_html);
      } else if (res.page_type === "EXTERNAL_LINK") {
        window.open(res.page_link, res.page_link_newtab ? "_blank" : "_self");
      } else if (res.page_type === "JWT_FULL_EMBED") {
        let { embedUrl } = res;
        let htmlIframe = `<iframe id="iframe" src="${embedUrl}" width="100%" height="100%" marginheight="0" marginwidth="0" frameBorder="0"></iframe>`;
        setSrcDoc(htmlIframe);
      } else if (res.page_type === "DOMO") {
        let { embedToken, embedUrl } = res;

        let htmlIframe = `<iframe id="iframe" title="page-embed" src="" width="100%" height="100%" marginHeight="0" marginWidth="0" frameBorder="0" srcDoc='<html>
          <body>
            <form id="form" action="${embedUrl}" method="post">
              <input type="hidden" name="embedToken" value="${embedToken}" />
            </form>
          </body>
          <script>document.getElementById("form").submit();</script>
        </html>'></iframe>`;

        setSrcDoc(htmlIframe);
      } else if (res.page_type === "METABASE") {
        let { embedUrl } = res;

        let htmlIframe = `<iframe id="iframe" title="page-embed" src="${embedUrl}" width="100%" height="100%" marginHeight="0" marginWidth="0" frameBorder="0"></iframe>`;

        setSrcDoc(htmlIframe);
      }

      setLoadingPage(false);
    } catch (err) {
      if (err.message === "The user aborted a request.") {
        setLoadingPage(true);
      } else {
        setSrcDoc(pageError);
        setLoadingPage(false);
      }
    }
  };

  useEffect(() => {
    const ac = new AbortController();

    if (user?._id) {
      getPage(ac.signal);
    }

    return () => {
      ac.abort();
      setSrcDoc("");
      setLoadingPage(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceId, pageId, user?._id]);

  useEffect(() => {
    if (user?._id) {
      getDomoPageList({ user_id: user?._id, workspace_id: workspaceId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceId, user?._id]);

  if (!workspaceDetails?._id && loadingPage)
    return (
      <div className="flex justify-center items-center h-full w-full">
        <Preloader />
      </div>
    );

  return (
    <>
      {loadingPage ? (
        <div className={classNames("flex justify-center items-center", workspaceDetails?.layout_type === "LEFT_NAVIGATION" ? "h-full" : "")}>
          <Preloader />
        </div>
      ) : (
        <div
          dangerouslySetInnerHTML={{ __html: srcDoc }}
          className={classNames("w-full", workspaceDetails?.layout_type === "LEFT_NAVIGATION" ? "h-full" : "")}
          style={{
            maxWidth: workspaceDetails?.use_global_settings ? (typeof site?.max_width === "string" ? site?.max_width : "100%") : typeof workspaceDetails?.max_width === "string" ? workspaceDetails.max_width : "100%",
          }}></div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    site: state.site,
    workspaceDetails: state.workspaceDetails,
  };
};

export default connect(mapStateToProps, { getDomoPageList })(EmbedWorkspace);
