import { useEffect, useRef } from 'react';

const LogoutTimer = ({ timeoutInSeconds, onLogout }) => {
  const timerRef = useRef(null);

  const resetTimer = () => {
    clearTimeout(timerRef.current);
    const newTimer = setTimeout(() => {
      onLogout();
    }, timeoutInSeconds * 60 * 1000);
    timerRef.current = newTimer;
  };

  const handleActivity = () => {
    resetTimer();
  };

  useEffect(() => {
    // Initial setup on component mount
    resetTimer();

    // Attach event listeners to reset the timer on user activity
    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);

    // Clean up the event listeners on component unmount
    return () => {
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
      clearTimeout(timerRef.current);  // Clear the timer on unmount
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onLogout, timeoutInSeconds]);

  return null;
};

export default LogoutTimer;
