import { PlusCircleIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import Equals from "src/assets/svgs/operators/equals.svg";
import GreaterThanOrEqual from "src/assets/svgs/operators/greater-than-equal.svg";
import GreaterThan from "src/assets/svgs/operators/greater-than.svg";
import LessThanOrEqual from "src/assets/svgs/operators/less-than-equal.svg";
import LessThan from "src/assets/svgs/operators/less-than.svg";
import NotEqual from "src/assets/svgs/operators/not-equal.svg";
import Button from "src/components/Shared/Buttons/Button";
import Input from "src/components/Shared/Forms/Inputs/Input";
import Select from "src/components/Shared/Forms/Selects/Select";
import SubArrowIcon from "src/components/Shared/Icons/SubArrowIcon";
import { classNames } from "src/helpers/classNames";
import { operatorNameConverter } from "src/helpers/operators/operatorNameConverter";
import { validate as uuidValidate } from "uuid";

let conversionTableSVG = {
  EQUALS: Equals,
  NOT_EQUALS: NotEqual,
  GREATER_THAN: GreaterThan,
  GREATER_THAN_EQUALS_TO: GreaterThanOrEqual,
  LESS_THAN: LessThan,
  LESS_THAN_EQUALS_TO: LessThanOrEqual,
};

let conversionTableText = {
  IN: "IN",
  NOT_IN: "NOT IN",
  CONTAINS: "CONTAINS",
};

const WorkspaceIntegrationFiltersItem = ({ state, setState = () => { }, removeFilter = () => { }, manageFilter = () => { }, operators, trustedAttributeArr, valueType, from = null, viewOnly = false, authorizeUserComponentAccessPermission = true, isAddWhere = true }) => {
  const [valueTypeOption, setValueTypeOption] = useState(valueType);
  const [operatorId, setOperatorId] = useState({ _id: -1, name: "" });
  const [valueTypeId, setValueTypeId] = useState({ _id: "value", name: "Text Value" });
  const [trustedAttributeId, setTrustedAttributeId] = useState({ _id: -1, name: "" });
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [operatorList, setOperatorList] = useState([]);

  useEffect(() => {
    setOperatorList(
      operators.map((operatorOption) => ({
        _id: operatorOption._id,
        code: operatorOption.code,
        name: operatorNameConverter(operatorOption.name),
        value: (
          <div className="">
            {conversionTableSVG[operatorOption.name] ? (
              <img
                className="m-0 h-5 w-auto p-0"
                src={conversionTableSVG[operatorOption.name]}
                alt="operator"
              />
            ) : (
              <p className="min-w-40 text-lg font-semibold subpixel-antialiased">{conversionTableText[operatorOption.name]}</p>
            )}
          </div>
        ),
      })),
    );
  }, [operators]);

  useEffect(() => {
    const index = valueType.findIndex((item) => item._id === state?.value_type);
    if (index !== -1) {
      setValueTypeId(valueType[index]);
      setValueTypeOption(valueType.filter((v) => v._id !== state?.value_type));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.value_type]);

  useEffect(() => {
    const index = operatorList.findIndex((item) => item._id === state?.operator_id);
    if (index !== -1) {
      setOperatorId(operatorList[index]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.operator_id, operatorList?.length]);

  useEffect(() => {
    const index = trustedAttributeArr.findIndex((item) => item._id === state?.trusted_attribute);
    if (index !== -1) {
      setTrustedAttributeId(trustedAttributeArr[index]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.trusted_attribute, trustedAttributeArr]);

  const showButtonVisibility = (state) => {
    if (state?.column_name && state?.operator_id && ((state?.value_type === "value" && state?.column_value) || (state?.value_type === "attribute" && state?.trusted_attribute))) {
      setShowSaveButton(true);
    } else {
      setShowSaveButton(false);
    }
  };

  return (
    <div className="relative w-full rounded-md border border-gray-200 px-4 pb-3 pr-10 pt-3">
      <div className="flex w-full items-center justify-between">
        <div className="relative flex w-full max-w-4xl flex-col items-end gap-x-3 sm:flex-row">
          <div className="grow">
            {viewOnly ? (
              <>
                <label className="block text-sm font-medium text-gray-700">Column Name</label>
                <div className="inline-flex h-10 w-full items-center whitespace-nowrap rounded-md border border-gray-300 px-4 py-2 shadow-sm focus:border-highlightColor focus:outline-none focus:ring-highlightColor sm:text-sm">{state.column_name}</div>
              </>
            ) : (
              <Input
                name="column-name"
                label="Column name"
                value={state?.column_name || ""}
                onChange={(e) => {
                  setState({ ...state, column_name: e.target.value });
                  showButtonVisibility({ ...state, column_name: e.target.value });
                }}
              />
            )}
          </div>
          <div className="w-[110px]">
            {viewOnly ? (
              <>
                <label className="block text-sm font-medium text-gray-700">Operator</label>
                <div className="inline-flex h-10 w-full items-center whitespace-nowrap rounded-md border border-gray-300 px-4 py-2 shadow-sm focus:border-highlightColor focus:outline-none focus:ring-highlightColor sm:text-sm">{operatorId?.name}</div>
              </>
            ) : (
              <Select
                label="Operator"
                options={operatorList}
                selected={operatorId}
                setSelected={(e) => {
                  setState({ ...state, operator_id: e._id });
                  showButtonVisibility({ ...state, operator_id: e._id });
                }}
              />
            )}
          </div>
          <div className="w-1/2">
            {viewOnly ? (
              <>
                <label className="block text-sm font-medium text-gray-700">{valueTypeId?.name}</label>
                <div className="inline-flex h-10 w-full items-center whitespace-nowrap rounded-md border border-gray-300 px-4 py-2 shadow-sm focus:border-highlightColor focus:outline-none focus:ring-highlightColor sm:text-sm">
                  {state?.value_type === "attribute" ? trustedAttributeId?.name : state.column_value}
                </div>
              </>
            ) : (
              <>
                <Select
                  options={valueTypeOption}
                  selected={valueTypeId}
                  selectType={"label"}
                  setSelected={(e) => {
                    setState({ ...state, value_type: e._id, column_value: "", trusted_attribute: "" });
                    setTrustedAttributeId({ _id: -1, name: "" });
                    showButtonVisibility({ ...state, value_type: e._id, column_value: "", trusted_attribute: "" });
                  }}
                />
                {state?.value_type === "attribute" ? (
                  <Select
                    options={trustedAttributeArr}
                    selected={trustedAttributeId}
                    setSelected={(e) => {
                      setState({ ...state, trusted_attribute: e._id });
                      showButtonVisibility({ ...state, trusted_attribute: e._id });
                    }}
                  />
                ) : (
                  <Input
                    name="column-value"
                    value={state?.column_value || ""}
                    onChange={(e) => {
                      setState({ ...state, column_value: e.target.value });
                      showButtonVisibility({ ...state, column_value: e.target.value });
                    }}
                  />
                )}
              </>
            )}
          </div>
          {!viewOnly && authorizeUserComponentAccessPermission && isAddWhere && !state?.addWhere && (
            <div className="absolute -right-1 -top-1">
              <Button
                type="button"
                version="default"
                className="mb-1 flex items-center gap-1 rounded-full border border-gray-300 bg-white px-2 py-[2px] text-gray-400 transition-all duration-150 hover:border-gray-400 hover:text-gray-500 hover:shadow"
                onClick={() => setState({ ...state, addWhere: true })}>
                <PlusCircleIcon className="h-4 w-4" />
                <div className="block pt-0.5 text-xs leading-none">Add Where</div>
              </Button>
            </div>
          )}
        </div>
        <div className="ml-3 flex flex-grow items-center justify-between pt-[18px]">
          {!viewOnly && authorizeUserComponentAccessPermission && showSaveButton && !from && (
            <Button
              type="button"
              version="default"
              disabled={state?.saveDisabled}
              className={classNames(
                "rounded-md border  border-highlightColor bg-highlightColor px-2 py-1 text-white transition-all duration-300  hover:border-highlightColor hover:bg-gray-100/70 hover:text-highlightColor focus:outline-none",
                state?.saveDisabled ? "opacity-50" : "",
              )}
              onClick={() => {
                setShowSaveButton(false);
                setState({ ...state, saveDisabled: true });
                manageFilter(state);
              }}>
              <span className="sr-only">Save</span>
              Save
            </Button>
          )}
        </div>
      </div>
      {!viewOnly && authorizeUserComponentAccessPermission && (!uuidValidate(state._id) || from === "group") && (
        <div className="absolute right-0 top-0 flex h-full border-l border-gray-200">
          <Button
            type="button"
            version="default"
            disabled={state?.saveDisabled}
            className={classNames("flex w-8 min-w-[32px] items-center justify-center rounded-r-md bg-gray-50/50 text-gray-400 outline-none ring-0 transition-all duration-150 hover:bg-gray-100 hover:text-gray-500", state?.saveDisabled ? "opacity-50" : "")}
            onClick={removeFilter}>
            <span className="sr-only">Delete</span>
            <TrashIcon className="h-5 w-5" />
          </Button>
        </div>
      )}
      {state?.addWhere && (
        <div className="relative flex items-center pl-8 pt-3">
          <SubArrowIcon />
          <div className={classNames("relative rounded border border-gray-200 bg-white py-1.5 pl-3 text-sm text-gray-600", !viewOnly ? "pr-12" : "pr-3")}>
            Apply condition only to the following <span className="mx-2 inline-flex rounded bg-gray-100 px-2 py-1 font-semibold">datasets</span>
            <span className="group relative ml-3 inline-flex">
              {viewOnly ? (
                <>
                  <div className="flex h-10 items-center whitespace-nowrap rounded-md border border-gray-300 px-4 py-2 shadow-sm focus:border-highlightColor focus:outline-none focus:ring-highlightColor sm:text-sm max-w-96">
                    <div className="truncate">{state?.datasource_id}</div>
                  </div>
                </>
              ) : (
                <div className="w-full">
                  <Input
                    name="datasource_id"
                    value={state?.datasource_id || ""}
                    onChange={(e) => {
                      setState({ ...state, datasource_id: e.target.value });
                      showButtonVisibility({ ...state, datasource_id: e.target.value });
                    }}
                  />
                </div>
              )}
            </span>
            {!viewOnly && authorizeUserComponentAccessPermission && (
              <div className="absolute right-0 top-0 flex h-full">
                <Button
                  type="button"
                  version="default"
                  onClick={() => {
                    setState({ ...state, datasource_id: null, addWhere: false });
                    showButtonVisibility({ ...state, datasource_id: null, addWhere: false });
                  }}
                  className="flex w-8 min-w-[32px] items-center justify-center rounded-r-md border-l border-gray-200 bg-gray-50/50 text-gray-400 outline-none ring-0 transition-all duration-150 hover:bg-gray-100 hover:text-gray-500">
                  <TrashIcon className="h-5 w-5" />
                </Button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default WorkspaceIntegrationFiltersItem;
