import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import { deleteWorkspace } from "src/actions/workspace";
import Button from "src/components/Shared/Buttons/Button";
import Section from "src/components/Shared/Containers/Section";
import Toggle from "src/components/Shared/Forms/Toggles/Toggle";
import Modal from "src/components/Shared/Modal";
import { H2, H3, H5 } from "src/components/Shared/Text/Headers";
import WorkspaceIntegrationsList from "src/components/Workspaces/Workspace/Settings/WorkspaceIntegration/WorkspaceIntegrationsList";
import WorkspaceIntegrationFilters from "src/components/Workspaces/Workspace/Settings/WorkspaceIntegration/WorkspaceIntegrationFilters";
import { manageWorkspaceSettings } from "src/actions/workspace";
import TextAccordion from "src/components/Shared/Accordions/TextAccordion";
import { authorizeUserComponentAccess } from "src/helpers/authorizeUserComponentAccess";
import WorkspaceDomain from "./WorkspaceDomain";

const WorkspaceSettings = ({ workspaceId, ...props }) => {
  const [deleteId, setDeleteId] = useState(null);
  const [autoImportDomoUser, setAutoImportDomoUser] = useState(false);
  const navigate = useNavigate();

  const [deleteIsLoading, setDeleteIsLoading] = useState(false);

  useEffect(() => {
    setAutoImportDomoUser(props.workspaceDetails?.auto_import_domo_user);
  }, [props.workspaceDetails?.auto_import_domo_user]);

  const handleUpdate = async (autoImportDomoUser) => {
    try {
      await props.manageWorkspaceSettings({ workspace_id: workspaceId, auto_import_domo_user: autoImportDomoUser });
    } catch (error) {
    } finally {
    }
  };

  return (
    <>
      <Section>
        <div className="grid gap-y-10">
          <div className="grid w-full gap-y-4">
            <H2 margin={false}>Workspace Settings</H2>
            {(authorizeUserComponentAccess(props?.me, workspaceId, "integration", ["update"]) || authorizeUserComponentAccess(props?.me, workspaceId, "settings", ["update"])) && (
              <WorkspaceIntegrationsList
                workspaceId={workspaceId}
                workspaceDetails={props.workspaceDetails}
                is_global={false}
              />
            )}
            {workspaceId && props?.workspaceDetails?.workspace_type !== "JWT_FULL_EMBED" && (authorizeUserComponentAccess(props?.me, workspaceId, "settings", ["update"]) || authorizeUserComponentAccess(props?.me, workspaceId, "workspace_filter", ["update"])) && (
              <WorkspaceIntegrationFilters workspaceId={workspaceId} />
            )}
            {props?.me?.type === "admin" && props?.site?.manage_subdomains && (
              <WorkspaceDomain
                workspaceId={workspaceId}
                workspaceDetails={props.workspaceDetails}
              />
            )}
            {props?.workspaceDetails?.workspace_type === "JWT_FULL_EMBED" && (
              <TextAccordion
                startOpen={true}
                headerTextPosition="left"
                headerText="Advanced details">
                <div className="grid gap-y-6 py-3">
                  <div className="flex flex-col justify-between gap-y-3 md:flex-row">
                    <H5 caption="Automatically imports users that were added inside Domo and not within the portal. (Syncs every 15min).">Auto import DOMO users</H5>
                    <div className="ml-2">
                      <Toggle
                        checked={autoImportDomoUser}
                        onChange={(e) => {
                          setAutoImportDomoUser(e);
                          handleUpdate(e);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </TextAccordion>
            )}
          </div>
          {(authorizeUserComponentAccess(props?.me, workspaceId, "settings", ["update"]) || authorizeUserComponentAccess(props?.me, workspaceId, "workspace", ["delete"])) && (
            <div className="flex w-full items-center">
              <H3 caption="When deleting a workspace, make sure you move all resources within it.">Delete workspace</H3>
              <Button
                version="secondary"
                className="h-12 !border-red-300 !text-red-300"
                onClick={() => setDeleteId(workspaceId)}>
                Delete
              </Button>
            </div>
          )}
        </div>
      </Section>
      <Modal
        title={props?.workspaceDetails?.name}
        secondaryTitle="Delete"
        isOpen={deleteId ? true : false}
        onCancel={() => setDeleteId(null)}
        isLoading={deleteIsLoading}
        onSuccess={async () => {
          setDeleteIsLoading(true);
          await props.deleteWorkspace(deleteId);
          navigate("/workspaces");
          setDeleteIsLoading(false);
        }}
        defaultOptions={{
          onSuccessButtonText: "Delete",
        }}>
        <div className="flex flex-col gap-y-1 whitespace-nowrap px-3 py-6 text-gray-600">
          <p className="text-lg">
            Are you sure you want to <span className="px-1 font-semibold text-gray-700">DELETE</span>
            this workspace?
          </p>
          <div className="text-gray-400">Once you delete this workspace it's gone for good.</div>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    me: state.auth.user,
    site: state.site,
    workspaceDetails: state.workspaceDetails,
  };
};

export default connect(mapStateToProps, { deleteWorkspace, manageWorkspaceSettings })(WorkspaceSettings);
