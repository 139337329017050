import { ArrowRightIcon, ArrowUpRightIcon, DocumentDuplicateIcon, CheckIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { apiRequest } from "src/async/apiUtils";
import Button from "src/components/Shared/Buttons/Button";
import Input from "src/components/Shared/Forms/Inputs/Input";
import Modal from "src/components/Shared/Modal";
import { H3 } from "src/components/Shared/Text/Headers";

const WorkspaceDomainManage = ({ isOpen = false, onCancel = () => {}, domain = {}, refreshDomain = () => {} }) => {
  const { id: workspaceId } = useParams();

  const [isTyping, setIsTyping] = useState(false);
  const [onSuccessLoaderStart, setOnSuccessLoaderStart] = useState(false);
  const [domainName, setDomainName] = useState("");

  const [isCopied, setIsCopied] = useState(false);

  const isValidDomain = /^(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+$/.test(domainName);

  const handleTypingLoader = () => {
    setIsTyping(true);
    setTimeout(() => setIsTyping(false), 700);
  };

  const onSubmit = async () => {
    setOnSuccessLoaderStart(true);

    try {
      // Validate
      if (!isValidDomain) {
        toast.error("Invalid url typed, please try again.");
        return;
      }

      const res = await apiRequest('POST', `workspaces/:workspace_id/domains`, { body: { domain: domainName}, params: {workspace_id: workspaceId} });

      refreshDomain();

      toast.success(res?.data?.message);

      // Clear state
      setDomainName("");
      onCancel();
    } catch (err) {
      toast.error(err.message);
    } finally {
      setOnSuccessLoaderStart(false);
    }
  };

  const copyCName = () => {
    navigator.clipboard.writeText(domain?.default_ingress);
    setIsCopied(true);

    setTimeout(() => setIsCopied(false), 2500);
  };

  useEffect(() => {
    setDomainName("")
  }, [isOpen])

  return (
    <Modal
      title={"Add Domain"}
      isOpen={isOpen}
      size="xl"
      onCancel={onCancel}
      onSuccess={onSubmit}
      defaultOptions={{
        onSuccessButtonText: "Add Domain",
        onSuccessLoaderVisible: true,
        onSuccessLoaderStart,
      }}>
      <div className="-mx-4 space-y-8">
        <div className="w-2/3">
          <div className="space-y-4">
            <div className="text-base text-gray-500">Clearsquare does not provide domain registration services. However, you can use a domain name that you already own. For any non-ASCII characters, please use Punycode representation.</div>
            <div className="relative space-y-1">
              <Input
                label={"Domain or Subdomain Name"}
                labelClassNames={"!font-semibold"}
                value={domainName}
                loading={isTyping}
                onChange={(e) => setDomainName(e.target.value)}
                onKeyDown={handleTypingLoader}
              />
              <div className="text-sm text-gray-400">https://{domainName}</div>
            </div>
          </div>
        </div>
        {isValidDomain && (
          <div className="space-y-3">
            <H3 className="pb-2 border-b border-gray-200">Chose Domain Name System (DNS) Management</H3>
            <div className="w-2/3">
              <div className="text-base text-gray-500">This tech was invented in the Paleozoic Era, so we'll help walk you through these steps.</div>
            </div>
            <div className="relative border border-highlightColor rounded bg-white py-4 px-5">
              <div className="w-2/3 space-y-4">
                <div className="text-base text-gray-500">Create a new CNAME record for your domain on your DNS Provider. Then, paste the CNAME Alias into the record so the domain points to this app.</div>
                <div className="relative space-y-1 max-w-sm">
                  <div className="flex justify-between text-sm font-semibold text-gray-700">CNAME Alias</div>
                  <div className="flex space-x-2">
                    <Input
                      disabled
                      value={domain?.default_ingress}
                    />
                    <Button
                      version={"secondary"}
                      className="!border-gray-300 !py-0 !px-2"
                      onClick={copyCName}>
                      {!isCopied ? <DocumentDuplicateIcon className="w-6 h-6" /> : <CheckIcon className="w-6 h-6" />}
                    </Button>
                  </div>
                </div>
                <div className="relative">
                  <div className="flex justify-between text-sm font-medium text-gray-500">Example:</div>
                  <div className="relative border border-gray-300 py-5 px-4 inline-block">
                    <div className="text-sm font-semibold text-gray-500">CNAME Record</div>
                    <div className="flex items-center text-sm text-gray-500 space-x-1">
                      <span>{domain?.region_label || ""}</span>
                      <ArrowRightIcon className="w-4 h-4 stroke-2" />
                      <span>{domain?.default_ingress}</span>
                    </div>
                  </div>
                </div>
                <div className="flex">
                  <a
                    href="https://www.digitalocean.com/docs/app-platform/how-to/manage-domains/"
                    target="_blank"
                    rel="noreferrer"
                    className="font-semibold text-highlightColor underline inline-flex items-end space-x-1 text-sm">
                    <span>Learn More</span>
                    <ArrowUpRightIcon className="w-3 h-3 stroke-[4] relative -top-0.5" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default WorkspaceDomainManage;
