import { XMarkIcon } from "@heroicons/react/24/outline";
import React from "react";
import Button from "src/components/Shared/Buttons/Button";

export default function WorkspaceTag({ index = -1, tag = "", onDelete = () => {} }) {
  return (
    <div
      key={index}
      className="relative flex items-center justify-between bg-gray-200 px-2 py-1 rounded pr-7">
      <div className="text-xs flex items-center font-semibold text-gray-500 whitespace-nowrap">{tag}</div>
      <Button
        version="default"
        className="w-4.5 h-4.5 absolute top-auto bottom-auto right-1 flex items-center justify-center transition-colors duration-200 bg-transparent rounded-full hover:bg-gray-300"
        onClick={() => onDelete(index)}>
        <XMarkIcon className="w-4 h-4 text-gray-600" />
      </Button>
    </div>
  );
}
