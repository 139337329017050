import Modal from "src/components/Shared/Modal";

const types = {
  UNSAVED_DATA: { title: "Warning", text: "You have unsaved changes. Are you sure you want to navigate away?", nextButton: "Continue" },
  DELETE_DATA: { title: "Delete", text: "Are you sure you want to delete. You cannot undo this change.", nextButton: "Delete" },
};

const WarningModal = ({ isOpen = false, type = "UNSAVED_DATA", onCancel = () => {}, onSuccess = () => {} }) => {
  return (
    <Modal
      title={types[type]?.title}
      secondaryTitle=""
      isOpen={isOpen}
      onCancel={onCancel}
      onSuccess={onSuccess}
      defaultOptions={{
        onSuccessButtonText: types[type]?.nextButton,
      }}>
      <div className="grid gap-y-8 whitespace-nowrap text-sm text-gray-500">{types[type]?.text}</div>
    </Modal>
  );
};

export default WarningModal;
