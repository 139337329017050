import { classNames } from "src/helpers/classNames";
import { useField } from "formik";

let defaultFieldStyles = "h-10 block w-full rounded-md border border-gray-300 py-2 px-4 shadow-sm focus:border-highlightColor focus:outline-none focus:ring-highlightColor sm:text-sm truncate";

const Input = ({ children, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <>
      <label
        htmlFor={props.name}
        className="block text-sm font-medium text-gray-700">
        {props.label}
      </label>
      {props.as === "select" ? (
        <select
          {...props}
          {...field}
          className={classNames(defaultFieldStyles, !!meta.touched && !!meta.error ? "border-red-400" : "")}>
          {children}
        </select>
      ) : (
        <input
          {...props}
          {...field}
          className={classNames(defaultFieldStyles, !!meta.touched && !!meta.error ? "border-red-400" : "")}
        />
      )}
    </>
  );
};

export default Input;
