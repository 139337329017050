import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Modal from "src/components/Shared/Modal";
import GroupIconManage from "src/components/Workspaces/Workspace/GroupIconManage";

const WorkspaceGroupIconManageModal = ({
  title = "",
  secondaryTitle = "",
  isOpen,
  onCancel = () => { },
  onSuccess = () => { },
  workspaceDetails = {},
  isAddIconGroupLoading = false,
  groupDetails = {},
  ...props
}) => {
  const [image, setImage] = useState(null);

  useEffect(() => {
    setImage(groupDetails?.image)
  },[groupDetails?._id, groupDetails?.image])
  return (
    <>
      <Modal
        title={title}
        secondaryTitle={secondaryTitle}
        isOpen={isOpen}
        onCancel={onCancel}
        onSuccess={() => onSuccess({image})}
        defaultOptions={{
          onSuccessButtonText: "Save",
          onSuccessLoaderVisible: true,
          onSuccessLoaderStart: isAddIconGroupLoading,
        }}
        size="lg"
        defaultStyles={{
          overFlowYVisible: true,
        }}>
        <div className="relative min-h-[200px] py-4 overflow-visible">
          <GroupIconManage
            image={image}
            setImage={setImage}
            divClasses={""}
            iconPosition={"left"}
            placeholder={"What icon are you searching for?"}
          />
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user
  };
};

export default connect(mapStateToProps, {})(WorkspaceGroupIconManageModal);
