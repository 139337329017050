import { GlobeAltIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import Image from "src/components/Shared/Image";
import { noFavicon } from "src/config/host";

export default function MetabaseIntegrationClient({ credential = {}, getAttributeValue = () => {} }) {
  const [displayName, setDisplayName] = useState("");
  const [displayDescription, setDisplayDescription] = useState("");
  const [integrationType, setIntegrationType] = useState("");
  const [displayURL, setDisplayURL] = useState("");

  useEffect(() => {
    setDisplayName(getAttributeValue("name"));
    setDisplayDescription(getAttributeValue("description"));
    setDisplayURL(getAttributeValue("subscriber_instance_url"));
    if (credential?.integration_type === "IFRAME_EMBED") {
      setIntegrationType("Iframe Embeded");
    } else {
      setIntegrationType("Subscriber Instance");
    }
  }, [credential, getAttributeValue]);

  return (
    <div className="flex items-center gap-x-1">
      <div className="w-96 truncate">
        <div className="text-base text-gray-800 font-semibold leading-none">{displayName}</div>
        <div className="text-sm text-gray-400">{displayDescription || displayURL}</div>
      </div>
      {integrationType && <div className="ml-5 w-34 text-xs font-semibold text-highlightColor px-3 h-8 flex items-center justify-center rounded border shadow">{integrationType}</div>}

      <div className="h-8">
        <div className="h-full min-w-34 text-xs font-semibold text-highlightColor px-1 flex items-center justify-center gap-x-2 rounded border shadow">
          {credential?.workspace ? (
            <div className="w-6 h-6">
              <Image
                image={credential?.workspace?.square_logo || credential?.workspace?.image_favicon || credential?.workspace?.image_logo}
                failImage={noFavicon}
                alt={credential?.workspace?.name}
                className="w-full h-full"
              />
            </div>
          ) : (
            <div className="h-full flex gap-x-1 items-center">
              <GlobeAltIcon className="w-6 h-6 text-gray-400" />
              <div className="flex-shrink text-xs font-semibold text-gray-400">Global</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
