import { connect } from "react-redux"
import { ssoLoginData } from "src/actions/sso";

const SSORedirectManageRoute = ({ site = {}, ssoLoginData = () => {}, children }) => {
  const ssoLogin = async (id) => {
    try {
      const message = await ssoLoginData({ id });
      window.location.href = message;
    } catch (error) {}
  };

  if (!site?._id) {
    return <></>
  } 

  if (site.active_sso_id && site.sso_admin_redirect) {
    const localAccessToken = localStorage.getItem("access_token")
    const accessToken = new URLSearchParams(window.location.search).get("access_token");
    if (!accessToken && !localAccessToken) {
      ssoLogin(site.active_sso_id) 
      return <></>
    }

    return children
  }

  return children
}

const mapStateToProps = (state) => {
  return {
    site: state.site,
  };
};

export default connect(mapStateToProps, { ssoLoginData })(SSORedirectManageRoute);