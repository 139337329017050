import { useEffect } from "react";
import { connect } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-tooltip/dist/react-tooltip.css";
import { logout } from "src/actions/auth";
import { getOpenSiteSettings, getSiteSettings, getSiteSettingsCache } from "src/actions/site";
import ScrollToTop from "src/components/Shared/ScrollToTop";
import Layout from "src/layouts/Layout";
import routes from "src/routes/routes";
import LogoutTimer from "./components/Shared/LogoutTimer";

window.Buffer = window.Buffer || require("buffer").Buffer;

const App = ({ logout, ...props }) => {
  const { site, workspaceDetails, me } = props;

  const loadSettings = async () => {
    const ac = new AbortController();
    try {
      if (!Object.keys(workspaceDetails).length) {
        if (me?._id && localStorage.getItem("access_token")) {
          await props.getSiteSettings({ token: me?.access_token }, ac.signal);
        } else {
          await props.getOpenSiteSettings({}, ac.signal);
        }
      }
    } catch (error) {
      // throw new Error(error.message);
    }
    return () => ac.abort();
  };

  useEffect(() => {
    // props.getSiteSettingsCache();
    loadSettings();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me, localStorage.getItem("access_token")]);

  useEffect(() => {
    if (Object.keys(site).length || Object.keys(workspaceDetails).length) {
      if (me?.type === "user" || localStorage.getItem("impersonatedUserId")) {
        if (!workspaceDetails.use_global_settings) {
          document.documentElement.style.setProperty("--highlightColor", Object.keys(workspaceDetails).length ? workspaceDetails.highlight_color : site.highlight_color, "important");
          document.documentElement.style.setProperty("--headerColor", Object.keys(workspaceDetails).length ? workspaceDetails.top_bar_color : site.top_bar_color, "important");
          document.documentElement.style.setProperty("--headerTextColor", Object.keys(workspaceDetails).length ? workspaceDetails.top_bar_text_color : site.top_bar_text_color, "important");
          document.documentElement.style.setProperty("--leftNavColor", Object.keys(workspaceDetails).length ? workspaceDetails.side_bar_color : site.side_bar_color, "important");
          document.documentElement.style.setProperty("--leftNavActiveColor", Object.keys(workspaceDetails).length ? workspaceDetails.side_bar_shade_color : site.side_bar_shade_color, "important");
          document.documentElement.style.setProperty("--leftNavTextColor", Object.keys(workspaceDetails).length ? workspaceDetails.side_bar_text_color : site.side_bar_text_color, "important");
          document.documentElement.style.setProperty("--leftNavActiveTextColor", Object.keys(workspaceDetails).length ? workspaceDetails.left_nav_active_text_color : site.left_nav_active_text_color, "important");
          document.documentElement.style.setProperty("--backgroundColor", Object.keys(workspaceDetails).length ? workspaceDetails.background_color : site.background_color, "important");
          document.title = Object.keys(workspaceDetails).length ? workspaceDetails.name : site.site_name;
          if (site.image_favicon || workspaceDetails.image_favicon) {
            document.querySelector("link[rel*='icon']").href = Object.keys(workspaceDetails).length ? `${workspaceDetails.image_favicon}?${Date.now()}` : `${site.image_favicon}?${Date.now()}`;
          } else {
            document.querySelector("link[rel*='icon']").href = "./assets/img/image_favicon-default.png";
          }
        } else {
          document.documentElement.style.setProperty("--highlightColor", site.highlight_color, "important");
          document.documentElement.style.setProperty("--headerColor", site.top_bar_color, "important");
          document.documentElement.style.setProperty("--headerTextColor", site.top_bar_text_color, "important");
          document.documentElement.style.setProperty("--leftNavColor", site.side_bar_color, "important");
          document.documentElement.style.setProperty("--leftNavActiveColor", site.side_bar_shade_color, "important");
          document.documentElement.style.setProperty("--leftNavTextColor", site.side_bar_text_color, "important");
          document.documentElement.style.setProperty("--leftNavActiveTextColor", site.left_nav_active_text_color, "important");
          document.title = site.site_name;
          if (site.image_favicon) {
            document.querySelector("link[rel*='icon']").href = `${site.image_favicon}?${Date.now()}`;
          } else {
            document.querySelector("link[rel*='icon']").href = "./assets/img/image_favicon-default.png";
          }
        }
      } else if (!localStorage.getItem("impersonatedUserId")) {
        document.documentElement.style.setProperty("--highlightColor", site.highlight_color, "important");
        document.documentElement.style.setProperty("--headerColor", site.top_bar_color, "important");
        document.documentElement.style.setProperty("--headerTextColor", site.top_bar_text_color, "important");
        document.documentElement.style.setProperty("--leftNavColor", site.side_bar_color, "important");
        document.documentElement.style.setProperty("--leftNavActiveColor", site.side_bar_shade_color, "important");
        document.documentElement.style.setProperty("--leftNavTextColor", site.side_bar_text_color, "important");
        document.documentElement.style.setProperty("--leftNavActiveTextColor", site.left_nav_active_text_color, "important");
        document.title = site.site_name;
        if (site.image_favicon) {
          document.querySelector("link[rel*='icon']").href = `${site.image_favicon}?${Date.now()}`;
        } else {
          document.querySelector("link[rel*='icon']").href = "./assets/img/image_favicon-default.png";
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site, workspaceDetails, me, localStorage.getItem("impersonatedUserId")]);

  useEffect(() => {}, []);

  return (
    <BrowserRouter>
      <div className="flex h-screen">
        <ScrollToTop />
        <ToastContainer
          position="top-right"
          autoClose={900}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {site?._id && me?._id && site?.user_inactivity_logout_enabled > 0 && (
          <LogoutTimer
            timeoutInSeconds={site?.user_inactivity_logout_timeframe}
            onLogout={() => logout()}
          />
        )}
        <Layout props={props}>{routes}</Layout>
      </div>
    </BrowserRouter>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
    me: state.auth.user,
    workspaceDetails: state.workspaceDetails,
  };
};

export default connect(mapStateToProps, { getOpenSiteSettings, getSiteSettingsCache, getSiteSettings, logout })(App);
