import React, { useEffect, useState } from "react";
import SelectMenu from "src/components/Shared/Forms/Selects/SelectMenu";
import TextAccordion from "src/components/Shared/Accordions/TextAccordion";
import FrontLabeledInput from "src/components/Shared/Forms/Inputs/FrontLabeledInput";
import Button from "src/components/Shared/Buttons/Button";
import { PencilIcon } from "@heroicons/react/24/outline";

const MetabasePage = ({ newPage = {}, setNewPage = () => { }, startIndex = -1, pageCredentialOptions = {}, user = null }) => {
  const [credMenuDisabled, setCredMenuDisabled] = useState(true)
  const embedTypes = [
    { key: "Dashboard", value: "EMBED_PAGE" }
  ];

  useEffect(() => {
    if (!newPage?._id && pageCredentialOptions.find((pageCredentialOption) => pageCredentialOption?.default)) {
      setNewPage((prevData) => ({
        ...prevData,
        credential_id: pageCredentialOptions.find((pageCredentialOption) => pageCredentialOption?.default)?.value
      }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newPage?._id, pageCredentialOptions])

  useEffect(() => {
    if (!newPage?._id && pageCredentialOptions.find((pageCredentialOption) => pageCredentialOption?.default)?.value === newPage?.credential_id){
      setCredMenuDisabled(true)
    }
  }, [newPage?._id, pageCredentialOptions, newPage?.credential_id])

  return (
    <div>
      <div className="grid gap-y-4">
        <div className="flex gap-x-3 w-full flex-col gap-y-3 sm:flex-row">
          <div className="flex-none w-1/3">
            {/* Embed Type */}
            <SelectMenu
              label="Embed Type"
              startIndex={embedTypes.findIndex((item) => item.value === newPage.embed_type)}
              options={embedTypes}
              setOption={(option) => {
                setNewPage({
                  ...newPage,
                  embed_type: option.value,
                });
              }}
            />
          </div>
          <div className="flex-grow">
            {/* Credentials */}
            <div className="relative">
              <SelectMenu
                disabled={(!newPage?._id && pageCredentialOptions.find((pageCredentialOption) => pageCredentialOption?.default) && credMenuDisabled) || (newPage?._id && pageCredentialOptions.length === 0 && newPage?.credential_name)}
                label="Metabase credentials"
                emptyListText={newPage?.credential_name || "No listed items"}
                defaultText={newPage?.credential_name || "Select an option"}
                startIndex={startIndex}
                options={pageCredentialOptions || [{ key: "Select credentials", value: -1 }]}
                setOption={(option) => {
                  setNewPage({ ...newPage, credential_id: option.value });
                }}
              />
              {!newPage?._id && pageCredentialOptions.find((pageCredentialOption) => pageCredentialOption?.default) && credMenuDisabled &&
                <div className="absolute w-9 aspect-square bg-white rounded-md bottom-0.5 right-0.5 z-30 flex items-center justify-center">
                  <Button version="default" className="" onClick={() => setCredMenuDisabled(false)}>
                    <PencilIcon className="w-5 h-5 text-gray-400" />
                  </Button>
                </div>
              }
            </div>
          </div>
        </div>
        <div className="w-full">
          <FrontLabeledInput
            label="Dashboard ID"
            type="text"
            value={newPage.dashboard_id}
            onChange={(e) => setNewPage({ ...newPage, dashboard_id: e.target.value })}
          />
        </div>
      </div>

      <TextAccordion headerText="Advanced features">
        <div className="-mx-2 flex flex-wrap">
          {newPage.is_global && user?.type === "admin" && (
            <div className="mb-4 flex w-full px-2">
              <input
                id="ignore-global-filters"
                checked={newPage.ignore_global_filters}
                onChange={(e) => {
                  setNewPage({
                    ...newPage,
                    ignore_global_filters: e.target.checked,
                  });
                }}
                aria-describedby="ignore-global-filters"
                name="ignore-global-filters"
                type="checkbox"
                className="h-5 w-5 rounded border-gray-300 text-highlightColor focus:ring-highlightColor"
              />
              <div className="ml-3 max-w-[400px]">
                <label
                  htmlFor="ignore-global-filters"
                  className="text-sm text-gray-500">
                  Ignore user-specific and workspace-specific <b>Data Access</b> controls
                </label>
                <p className="whitespace-normal text-sm text-gray-400">If enabled, both user-specific and global workspace data access controls will be ignored when viewing this page.</p>
              </div>
            </div>
          )}

          <div className="px-2 mb-4 space-y-4 w-full">
            <div className="text-sm font-medium text-gray-700 capitalize py-2 border-b border-gray-200">Style</div>

            <div className="flex items-center gap-4">
              <div className="flex items-center gap-3">
                <input
                  id="bordered"
                  checked={newPage.bordered}
                  onChange={(e) => {
                    setNewPage({
                      ...newPage,
                      bordered: e.target.checked,
                    });
                  }}
                  name="bordered"
                  type="checkbox"
                  className="h-5 w-5 rounded border-gray-300 text-highlightColor focus:ring-highlightColor"
                />
                <div className="text-sm font-medium text-gray-700">Border</div>
              </div>
              <div className="flex items-center gap-3">
                <input
                  id="titled"
                  checked={newPage.titled}
                  onChange={(e) => {
                    setNewPage({
                      ...newPage,
                      titled: e.target.checked,
                    });
                  }}
                  name="titled"
                  type="checkbox"
                  className="h-5 w-5 rounded border-gray-300 text-highlightColor focus:ring-highlightColor"
                />
                <div className="text-sm font-medium text-gray-700">Title</div>
              </div>
            </div>
          </div>
          <div className="px-2 mb-4 space-y-4 w-full">
            <div className="text-sm font-medium text-gray-700 capitalize py-2 border-b border-gray-200">Appearance</div>
            <div className="flex items-center gap-4">
              <div className="relative flex items-center gap-3">
                <input
                  id="light"
                  value={""}
                  checked={newPage.theme === ""}
                  onChange={(e) => {
                    setNewPage({
                      ...newPage,
                      theme: e.target.value,
                    });
                  }}
                  name="theme"
                  type="radio"
                  className="h-5 w-5 rounded border-gray-300 text-highlightColor focus:ring-highlightColor"
                />
                <div className="text-sm font-medium text-gray-700">Light</div>
              </div>
              <div className="relative flex items-center gap-3">
                <input
                  id="night"
                  value={"night"}
                  checked={newPage.theme === "night"}
                  onChange={(e) => {
                    setNewPage({
                      ...newPage,
                      theme: e.target.value,
                    });
                  }}
                  name="theme"
                  type="radio"
                  className="h-5 w-5 rounded border-gray-300 text-highlightColor focus:ring-highlightColor"
                />
                <div className="text-sm font-medium text-gray-700">Dark</div>
              </div>
              <div className="relative flex items-center gap-3">
                <input
                  id="transparent"
                  value={"transparent"}
                  checked={newPage.theme === "transparent"}
                  onChange={(e) => {
                    setNewPage({
                      ...newPage,
                      theme: e.target.value,
                    });
                  }}
                  name="theme"
                  type="radio"
                  className="h-5 w-5 rounded border-gray-300 text-highlightColor focus:ring-highlightColor"
                />
                <div className="text-sm font-medium text-gray-700">Transparent</div>
              </div>
            </div>
          </div>
        </div>
      </TextAccordion>
    </div>
  );
};

export default MetabasePage;
