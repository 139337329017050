import { EyeIcon, EyeSlashIcon, ArrowPathIcon } from "@heroicons/react/24/outline";
import { useEffect, useRef, useState } from "react";
import Button from "src/components/Shared/Buttons/Button";
import { classNames } from "src/helpers/classNames";

const Input = ({ autoComplete = "off", label, name, placeholder, disabled = false, value = "", error = false, type = "text", inputClassNames = "", labelClassNames = "", onChange = () => {}, onFocus = () => {}, onKeyUp = () => {}, onKeyDown = () => {}, onBlur = () => {}, loading = false }) => {
  const labelClasses = classNames(labelClassNames, "flex justify-between text-sm font-medium mb-1", error ? "text-red-600" : "", disabled ? "text-gray-400 cursor-not-allowed" : "text-gray-700");
  const inputClasses = classNames("h-10 block w-full sm:text-sm border-gray-300 rounded-md shadow-sm focus:ring-0 focus:border-highlightColor", error ? "border-red-300" : "", disabled ? "text-gray-400 cursor-not-allowed bg-gray-50/50" : "text-gray-900");

  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const passwordInput = useRef(null);
  const handleChange = () => {
    setPasswordVisibility(!passwordVisibility);
  };

  useEffect(() => {
    passwordInput.current.type = passwordVisibility ? "text" : type;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passwordVisibility]);
  return (
    <>
      {label && (
        <label
          htmlFor={name}
          className={labelClasses}>
          {label} <div className="pl-2">{error ? "*Required" : ""}</div>
        </label>
      )}
      <div className="w-full relative">
        <input
          ref={passwordInput}
          disabled={disabled}
          value={value}
          type={type}
          name={name}
          autoComplete={autoComplete}
          onFocus={onFocus}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
          className={inputClassNames ? inputClassNames : inputClasses}
          placeholder={placeholder}
        />
        {type === "password" && (name === "password" || name === "confirm-password") && (
          <Button
            type="button"
            version="default"
            className="w-10 h-10 absolute top-0 right-0 bg-transparent text-slate-500"
            onClick={handleChange}>
            {!passwordVisibility ? <EyeSlashIcon className="w-5 h-5" /> : <EyeIcon className="w-5 h-5" />}
          </Button>
        )}
        { 
          loading && 
          <Button
            type="button"
            version="default"
            className="w-10 h-10 absolute top-0 right-0 bg-transparent text-slate-500 cursor-default"
          >
            <ArrowPathIcon className="w-5 h-5 animate-spin" /> 
          </Button>
        }
      </div>
    </>
  );
};

export default Input;
