import { BeakerIcon } from "@heroicons/react/24/outline";

const WorkspaceSubscriberInstance = () => {
  return (
    <div className="inline-flex gap-x-2 items-center justify-center text-sm text-gray-400 bg-gray-50/80 rounded-sm py-1 px-2 -ml-1">
      <BeakerIcon className="h-5 w-5" />
      Analytics Builder
    </div>
  );
};

export default WorkspaceSubscriberInstance;
