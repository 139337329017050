import "flatpickr/dist/themes/light.css";

export default function TableOptions({ leftContent = null, middleContent = null, rightContent = null }) {
  return (
    <div className="flex items-center justify-between mb-4">
      <div className="flex w-full justify-between">
        <div className="flex w-4/5 gap-x-2">
          <div className="w-full max-w-[400px]">{leftContent}</div>
          <div className="hidden gap-x-1 md:flex">{middleContent}</div>
        </div>
        <div className="relative flex min-w-[140px] items-center justify-end gap-x-1">{rightContent}</div>
      </div>
    </div>
  );
}
