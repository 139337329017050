import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { makeDefault } from "src/actions/sso";
import QuickMenu from "src/components/Shared/QuickMenu";
import { classNames } from "src/helpers/classNames";
import TableRow from "src/components/Shared/Table/TableRow";
import Button from "src/components/Shared/Buttons/Button";

const SSOEntry = ({ sso, onChange = () => {}, setDeleteId, site, ...props }) => {
  const navigate = useNavigate();

  const handleDefaultToggle = async () => {
    await props.makeDefault({ id: sso._id, default: !sso.default });
    onChange();
  };

  const providerType = {
    general: "General",
    auth0: "Auth0",
  };

  return (
    <TableRow keyId={sso?._id}>
      <td className="block sm:table-cell w-full sm:w-auto whitespace-pre-wrap break-word py-0 pt-3 px-6 sm:py-3.5 text-sm text-gray-500">
        <div className="text-gray-900 font-medium">{providerType[sso?.provider_type]}</div>
      </td>
      <td className="block sm:table-cell w-full sm:w-auto whitespace-pre-wrap break-word py-0 pt-3 px-6 sm:py-3.5 text-sm text-gray-500">
        <div className="text-gray-900 font-medium">{sso?.provider_name}</div>
      </td>
      <td className="block sm:table-cell w-full sm:w-auto whitespace-pre-wrap break-all py-1 px-4 sm:py-3.5 text-xs text-gray-500">
        {sso?.provider_type !== "auth0" ? <div className="text-gray-700 max-w-xs">{sso?.identity_url}</div> : <div className="text-gray-700 max-w-xs">{sso?.domain}</div>}
      </td>
      <td className="block sm:table-cell w-full sm:w-auto whitespace-pre-wrap break-all py-1 px-4 sm:py-3.5 text-xs text-gray-500">{sso?.provider_type !== "auth0" ? <div className="text-gray-700 max-w-xs">{sso?.login_url}</div> : <></>}</td>
      <td className="flex items-center justify-end w-full pt-0 pb-3 sm:w-20 sm:table-cell whitespace-nowrap px-4 sm:py-3.5 text-sm text-gray-500 ">
        <Button
          onClick={handleDefaultToggle}
          version="default"
          className={classNames("flex justify-center w-[60px] rounded-full px-2 text-xs font-semibold leading-5 transition-all duration-150", sso?.default ? "bg-green-200 text-green-700 opacity-50" : "bg-gray-100 text-gray-500")}>
          {sso?.default ? "Active" : "Inactive"}
        </Button>
      </td>
      <td className="absolute top-0 right-0 block sm:table-cell sm:relative whitespace-nowrap py-3 pl-4 pr-4 text-sm font-medium sm:pr-6">
        <div className="flex justify-end">
          <QuickMenu
            items={[
              {
                name: "Edit",
                onClick: () => {
                  navigate(`/sso/edit/${sso._id}`);
                },
              },
              {
                name: "Delete",
                showWhen: !sso.default,
                onClick: () => {
                  setDeleteId(sso._id);
                },
                color: "text-red-500",
              },
            ]}
          />
        </div>
      </td>
    </TableRow>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
  };
};

export default connect(mapStateToProps, { makeDefault })(SSOEntry);
