import { FunnelIcon } from "@heroicons/react/24/outline";
import { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { apiRequest } from "src/async/apiUtils";
import Button from "src/components/Shared/Buttons/Button";
import Section from "src/components/Shared/Containers/Section";
import IconsWithPlus from "src/components/Shared/Icons/IconsWithPlus";
import Preloader from "src/components/Shared/LoadingAnimations/Preloader";
import NoneFound from "src/components/Shared/NoData/NoneFound";
import { H3 } from "src/components/Shared/Text/Headers";
import WorkspaceIntegrationFiltersItem from "src/components/Workspaces/Workspace/Settings/WorkspaceIntegration/WorkspaceIntegrationFiltersItem";
import useFetch from "src/hooks/useFetch";
import { v4, validate as uuidValidate } from "uuid";

const GlobalFiltersList = () => {
  const user = useSelector((state) => state.auth.user);
  const { id: userId } = useParams();

  const {
    response: { data: operators = [] },
  } = useFetch("/operators/list");

  const [filtersLoaded, setFiltersLoaded] = useState(false);
  const [filters, setFilters] = useState([]);
  const [customFieldsLoading, setCustomFieldsLoading] = useState(true);
  const [customFields, setCustomFields] = useState([]);

  useEffect(() => {
    if (userId) {
      const loadFilters = async () => {
        try {
          setFiltersLoaded(false);

          const res = await apiRequest(
            "get",
            `users/:user_id/global-filters`,
            {
              params: { user_id: userId }
            }
          );

          if (res.data && res.data.status === 200) {
            setFiltersLoaded(true);
            setFilters(
              res.data.data.map((item) => ({
                ...item,
                addWhere: item?.datasource_id ? true : false,
              }))
            );
          } else {
            setFiltersLoaded(true);
          }
        } catch (error) {
          toast.error(error.message);
          setFiltersLoaded(true);
        }
      };

      loadFilters();
    }
  }, [userId]);

  const manageFilters = async (filter = {}) => {
    try {
      const res = await apiRequest(
        "post",
        `users/:user_id/global-filters/manage`,
        {
          body: { ...filter },
          params: { user_id: userId }
        }
      );
      setFilters((filters) =>
        filters.map((flt) =>
          flt._id !== filter?._id
            ? flt
            : {
                ...flt,
                _id: res.data.data,
                saveDisabled: false,
                addWhere: flt?.datasource_id ? true : false,
              }
        )
      );
      toast.success(res.data.message);
    } catch (error) {
      setFilters((filters) =>
        filters.map((flt) =>
          flt._id !== filter?._id
            ? flt
            : {
                ...flt,
                saveDisabled: false,
                addWhere: flt?.datasource_id ? true : false,
              }
        )
      );
      toast.error(error.message);
    }
  };

  const removeFilter = async (id) => {
    try {
      const res = await apiRequest(
        "delete",
        `users/:user_id/global-filters/:id`,
        {
          params: { user_id: userId, id }
        }
      );
      setFilters((prevData) => prevData.filter((data) => data?._id !== id));
      toast.success(res.data.message);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const loadCustomFields = useCallback(() => {
    (async () => {
      setCustomFieldsLoading(true);

      try {
        const { data: resData } = await apiRequest("GET", `/users/custom-fields`);

        if (resData.status === 200 && resData.data) {
          setCustomFields(resData.data);
        }
      } catch (err) {
        console.dir("err", err);
      } finally {
        setCustomFieldsLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    loadCustomFields();
  }, [loadCustomFields]);

  const trustedAttributeArr = [
    {
      _id: "user.fullName",
      value: "user.fullName",
      name: "Full Name",
    },
    {
      _id: "user.email",
      value: "user.email",
      name: "Email Address",
    },
  ];

  const allTrustedAttributes = [
    ...trustedAttributeArr,
    ...customFields.map((field) => ({
      _id: `user.${field.code}`,
      value: `user.${field.code}`,
      name: field.label,
    })),
  ];

  const valueType = [
    {
      _id: "value",
      value: "value",
      name: "Text Value",
    },
    {
      _id: "attribute",
      value: "attribute",
      name: "User Attribute",
    },
  ];

  const addFilter = async () => {
    const uniqueId = v4();

    setFilters((filters) => [
      ...filters,
      {
        _id: uniqueId,
        operator_id: null,
        column_name: null,
        value_type: "value",
        column_value: null,
        trusted_attribute: null,
        datasource_id: null,
        addWhere: false,
      },
    ]);
  };
  return (
    <Section>
      <div className="grid gap-y-10">
        <div className="w-full flex items-center">
          <H3 caption="Conditions created here will be applied to all dashboards that the user has been given access to see in all workspaces.">
            Data Access
          </H3>

          <Button
            version="secondary"
            onClick={addFilter}>
            <IconsWithPlus
              strokeColor={"stroke-highlightColor"}
              item={{ icon: FunnelIcon }}
            />
          </Button>
        </div>
        <hr className="w-full" />
        <div className="space-y-4">
          {!filtersLoaded ? (
            <Preloader />
          ) : !filters.length ? (
            <NoneFound />
          ) : (
            !customFieldsLoading &&
            filters.map((filter) => (
              <WorkspaceIntegrationFiltersItem
                key={filter._id}
                user={user}
                workspaceId={null}
                state={filter}
                operators={operators}
                trustedAttributeArr={allTrustedAttributes}
                valueType={valueType}
                setState={(state) =>
                  setFilters(filters.map((filter) => (filter._id !== state._id ? filter : state)))
                }
                removeFilter={() => {
                  if (uuidValidate(filter._id)) {
                    setFilters((prevData) => prevData.filter((data) => data?._id !== filter._id));
                  } else {
                    removeFilter(filter._id);
                  }
                }}
                manageFilter={manageFilters}
              />
            ))
          )}
        </div>
      </div>
    </Section>
  );
};

export default GlobalFiltersList;
