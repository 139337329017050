
const BlankLayout = ({ children, site }) => {

  return (
    <>
      <div className="w-full h-full flex">{children}</div>
    </>
  );
};

export default BlankLayout;
