import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { classNames } from "src/helpers/classNames";
import { H3 } from "src/components/Shared/Text/Headers";
import Button from "src/components/Shared/Buttons/Button";

const EditContainer = ({ title, preview, height = "4000px", onCancel = () => {}, onSuccess = () => {}, defaultOptions = null, isLoading = false, submitIsLoading = false, submitIsDisabled = false, fullWidth = false, defaultOpen = false, borderBottom = true, children }) => {
  const [show, setShow] = useState(defaultOpen);

  const [options, setOptions] = useState({
    onCancelButtonVisible: true,
    onCancelButtonText: "Cancel",
    onSuccessButtonVisible: true,
    onSuccessButtonText: "Save",
    onSuccessLoaderVisible: false,
    onSuccessLoaderStart: false,
  });

  useEffect(() => {
    if (defaultOptions) setOptions({ ...options, ...defaultOptions });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultOptions]);

  useEffect(() => {
    if (isLoading) {
      setOptions((prev) => ({ ...prev, onSuccessLoaderStart: isLoading, onSuccessLoaderVisible: isLoading }));
    } else {
      setTimeout(() => {
        setOptions((prev) => ({ ...prev, onSuccessLoaderStart: isLoading, onSuccessLoaderVisible: isLoading }));
      }, 300);
    }
  }, [isLoading]);

  return (
    <div className={classNames("w-full transition-all pt-6 pb-7", borderBottom ? "border-b border-slate-200" : "")}>
      <div className="flex justify-between">
        <div className="flex">
          <div className="w-64 lg:w-96">
            <H3>{title}</H3>
          </div>
          <div className={classNames("hidden md:block text-gray-400 transition-all", show ? `max-h-[${height}] pt-4` : "pt-1 max-h-auto overflow-hidden")}>{show ? !fullWidth && children : preview}</div>
        </div>
        <Button
          type="button"
          version="default"
          onClick={() => setShow(!show)}
          className="h-8 w-16 flex items-center justify-center font-bold text-lg text-highlightColor hover:underline hover:opacity-80 transition-all focus:border-2 focus:border-dashed focus:border-gray-300">
          {show ? "Close" : "Edit"}
        </Button>
      </div>
      <div className={classNames("text-gray-400 transition-all", show && fullWidth ? `w-full block max-h-[${height}]` : `pl-4 md:hidden max-h-[${height}] md:max-h-auto md:pt-0 md:overflow-hidden`)}>{show && children}</div>
      {show ? (
        <div className="py-4 flex justify-end space-x-4">
          {options.onSuccessButtonVisible && (
            <Button
              type="button"
              version="default"
              disabled={(options.onCancelLoaderVisible && options.onCancelLoaderStart) || (options.onSuccessLoaderVisible && options.onSuccessLoaderStart)}
              className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none sm:w-auto sm:text-sm ${
                !((options.onCancelLoaderVisible && options.onCancelLoaderStart) || (options.onSuccessLoaderVisible && options.onSuccessLoaderStart)) ? "!bg-highlightColor" : "bg-gray-50 !text-gray-500"
              }`}
              onClick={() => {
                onSuccess();
                if (!defaultOpen) {
                  setShow(false);
                }
              }}>
              {options.onSuccessButtonText}
              {options.onSuccessLoaderVisible && options.onSuccessLoaderStart && !options.onCancelLoaderVisible && (
                <ArrowPathIcon className={`h-5 w-5 ml-2 animate-spin text-white ${!((options.onCancelLoaderVisible && options.onCancelLoaderStart) || (options.onSuccessLoaderVisible && options.onSuccessLoaderStart)) ? "text-white" : "!text-gray-500"}`} />
              )}
            </Button>
          )}
          {options.onCancelButtonVisible && (
            <Button
              disabled={(options.onCancelLoaderVisible && options.onCancelLoaderStart) || (options.onSuccessLoaderVisible && options.onSuccessLoaderStart)}
              type="button"
              version="default"
              className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
              onClick={() => {
                onCancel();
                if (!defaultOpen) {
                  setShow(false);
                }
              }}>
              {options.onCancelButtonText}
              {options.onCancelLoaderVisible && options.onCancelLoaderStart && <ArrowPathIcon className="h-5 w-5 ml-2 animate-spin" />}
            </Button>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default EditContainer;
