import { useEffect, useState } from "react";
import { apiRequest } from "src/async/apiUtils";
import HtmlAndRichTextEditor from "src/components/Emailer/HtmlAndRichTextEditor";
import Input from "src/components/Shared/Forms/Inputs/Input";
import Modal from "src/components/Shared/Modal";

const SiteEmailManageModal = ({ data, isOpen, onCancel = () => {}, onSuccess = () => {} }) => {
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");

  const [disabled, setDisabled] = useState(false);

  const updateEmailTemplate = async () => {
    try {
      setDisabled(true);

      const { data: respData } = await apiRequest(
        "PUT",
        `/site-settings/email-template/:id`,
        {
          body: {
            subject,
            content,
          },
          params: {
            id: data._id
          }
        },
        {
          showToastMessage: true,
          onSuccess: () => {},
          onFailure: () => {},
        }
      );
      if (respData.status === 200) {
        onSuccess();
        setSubject("");
        setContent("");
      }
    } catch (error) {
    } finally {
      setTimeout(() => setDisabled(false), 300);
    }
  };

  useEffect(() => {
    if (isOpen) {
      setSubject(data.subject);
      setContent(data.content);
    }
    return () => {
      setSubject("");
      setContent("");
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.code, isOpen]);

  return (
    <Modal
      title="Email"
      size="xxxxxl"
      secondaryTitle={`Edit`}
      isOpen={isOpen}
      onCancel={onCancel}
      onSuccess={updateEmailTemplate}
      defaultOptions={{
        onSuccessButtonText: "Save",
        onSuccessLoaderVisible: disabled,
        onSuccessLoaderStart: disabled,
      }}>
      <div className="flex gap-x-4 h-[400px] lg:h-[600px] sm:pb-6">
        <div className="h-full relative w-1/2 space-y-5">
          <div>
            <Input
              name="name"
              label="Subject"
              autoComplete="off"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
          </div>
          {content && (
            <HtmlAndRichTextEditor
              htmlContent={content}
              setHtmlContent={setContent}
            />
          )}
        </div>
        <div className="flex w-1/2">
          <div className="mr-5 h-full w-[1px] bg-gray-100" />
          <div className="h-full">
            <p className="sm:text-sm text-gray-700">Preview:</p>
            <div className="h-full">
              <div
                className="border overflow-y-scroll h-full"
                dangerouslySetInnerHTML={{ __html: content }}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SiteEmailManageModal;
