import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { deletePageCredentialOptions } from "src/actions/page";
import { workspaceIntegrationCredentialManage } from "src/actions/workspace";
import NoneFound from "src/components/Shared/NoData/NoneFound";
import WorkspaceIntegrationClientsItem from "src/components/Workspaces/Workspace/Settings/WorkspaceIntegration/WorkspaceIntegrationClientsItem";

const WorkspaceIntegrationClients = ({
  user,
  workspaceId,
  deletePageCredentialOptions,
  integration,
  workspaceDetails,
  editCredential = () => {},
  openCreatedByModal = () => {},
  removeCredential = () => {},
  makeDefaultButtonDisabled = false,
  setMakeDefaultButtonDisabled = () => {},
  refreshIntegrations = () => {},
  ...props
}) => {
  const { id } = useParams();

  const [credentials, setCredentials] = useState([]);

  useEffect(() => {
    setCredentials(integration?.integrations);
  }, [integration]);

  if (id && !workspaceDetails?._id) {
    return <></>;
  }

  return (
    <div className="mt-2 space-y-2 rounded border-gray-200 bg-white">
      {credentials?.length > 0 ? (
        credentials.map((credential, index) => {
          return (
            <WorkspaceIntegrationClientsItem
              key={credential._id}
              integration={integration}
              user={user}
              workspaceId={workspaceId}
              credential={credential}
              editCredential={editCredential}
              openCreatedByModal={openCreatedByModal}
              removeCredential={removeCredential}
              makeDefaultButtonDisabled={makeDefaultButtonDisabled}
              setMakeDefaultButtonDisabled={setMakeDefaultButtonDisabled}
              refreshIntegrations={refreshIntegrations}
            />
          );
        })
      ) : (
        <NoneFound />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    site: state.site,
    workspace: state.workspaceDetails,
  };
};
export default connect(mapStateToProps, { workspaceIntegrationCredentialManage, deletePageCredentialOptions })(WorkspaceIntegrationClients);
