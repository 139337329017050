import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { workspaceIntegrationCredentialDelete } from "src/actions/workspace";
import AddCredentialDropdown from "src/components/Shared/Buttons/AddCredentialDropdown";
import EditContainer from "src/components/Shared/Containers/EditContainer";
import MultiSelectDropdown from "src/components/Shared/Forms/Selects/MultiSelectDropdown";
import Preloader from "src/components/Shared/LoadingAnimations/Preloader";
import Modal from "src/components/Shared/Modal";
import NoDataDashedContainer from "src/components/Shared/NoData/NoDataDashedContainer";
import Search from "src/components/Shared/Search";
import WorkspaceIntegrationItem from "src/components/Workspaces/Workspace/Settings/WorkspaceIntegration/WorkspaceIntegrationItem";
import WorkspacesIntegrationCreatedByManageModal from "src/components/Workspaces/WorkspacesModals/WorkspacesIntegrationCreatedByManageModal";
import WorkspacesIntegrationModal from "src/components/Workspaces/WorkspacesModals/WorkspacesIntegrationModal";
import useFetch from "src/hooks/useFetch";

const WorkspaceIntegrationsList = ({ is_global = false, user, workspaceId, workspaceDetails, ...props }) => {
  const [keyword, setkeyword] = useState("");
  const [selectedProviders, setSelectedProviders] = useState([]);
  const [selectedWorkspaces, setSelectedWorkspaces] = useState([]);
  const [providerDetails, setProviderDetails] = useState(null);
  const [embedType, setEmbedType] = useState(null);
  const [editItem, setEditItem] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isCreatedByModalOpen, setIsCreatedByModalOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [integrationId, setIntegrationId] = useState(null);
  const [credentialId, setCredentialId] = useState(null);
  const [deleteIsLoading, setDeleteIsLoading] = useState(false);
  const [, setIsAddIntegration] = useState(false);
  const [dataProviderList, setDataProviderList] = useState([]);
  const [makeDefaultButtonDisabled, setMakeDefaultButtonDisabled] = useState(false);
  const [editorUserList, setEditorUserList] = useState([]);

  const {
    response: { data: dataProviders },
  } = useFetch(workspaceId ? `/workspaces/:workspace_id/data-providers` : `/data-providers/global`, { params: { workspace_id: workspaceId } });

  const {
    response: { data: workspaces },
  } = useFetch(!workspaceId ? `/workspaces/list` : ``, { method: "post" });

  const {
    response: { data: integrations },
    status: { done: integrationsLoaded },
    refreshData: refreshIntegrations,
  } = useFetch(workspaceId ? `/workspaces/:workspace_id/integrations` : `/integrations/global`, { method: "post", data: { keyword, selectedProviders, selectedWorkspaces }, params: { workspace_id: workspaceId } });

  const {
    response: { data: editorUsers },
  } = useFetch(workspaceId ? `/workspaces/:workspace_id/editor-admin/list` : `/editor-admin/list`, { params: { workspace_id: workspaceId } });

  const deleteCredModalOpen = async (integration_id, credential_id) => {
    setIntegrationId(integration_id);
    setCredentialId(credential_id);
    setIsDeleteOpen(true);
  };

  const removeCredential = async () => {
    try {
      setDeleteIsLoading(true);
      await props.workspaceIntegrationCredentialDelete({ integration_id: integrationId, workspace_id: workspaceId, credential_id: credentialId });
      setIsDeleteOpen(false);
      setDeleteIsLoading(false);
      refreshIntegrations();
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    let providerList = [];
    if (!workspaceId || workspaceDetails?.workspace_type === "IFRAME_EMBED") {
      setIsAddIntegration(true);
      providerList = dataProviders;
    } else {
      const addedProviderList = integrations.map((integration) => integration.code);
      providerList = dataProviders.filter((dataProvider) => addedProviderList.length === 0 || !dataProvider.code.includes(addedProviderList));
    }
    setIsAddIntegration(providerList.length > 0 ? true : false);
    setDataProviderList(
      providerList.map((dataProvider) => {
        if (dataProvider?.embed_types?.length > 0) {
          const checkFullEmbedGlobal = integrations.find((integration) => integration?.code === dataProvider?.code)?.integrations?.find((integration) => !integration?.workspace_id && integration?.integration_type === "JWT_FULL_EMBED");
          return {
            ...dataProvider,
            embed_types: workspaceId
              ? dataProvider?.embed_types?.filter((embed_type) => embed_type?.type === workspaceDetails?.workspace_type)
              : checkFullEmbedGlobal
                ? dataProvider?.embed_types.filter((embed_type) => embed_type?.type !== "JWT_FULL_EMBED")
                : dataProvider?.embed_types,
          };
        } else {
          return dataProvider;
        }
      }),
    );
  }, [workspaceId, integrations, workspaceDetails, dataProviders]);

  useEffect(() => {
    refreshIntegrations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword, selectedProviders, selectedWorkspaces]);

  return (
    <EditContainer
      title="Credentials"
      preview={
        <div className="flex h-10 w-56 items-center">
          <div className="flex gap-x-2 rounded border border-gray-100 px-3 py-1 shadow">
            {integrations?.length ? (
              <>
                <div>
                  {integrations?.reduce((acc, integration) => {
                    return acc + (integration?.integrations?.length || 0);
                  }, 0)}
                </div>
                Embed Credential
                {integrations?.reduce((acc, integration) => {
                  return acc + (integration?.integrations?.length || 0);
                }, 0) > 1 && "s"}
              </>
            ) : (
              "No Credentials"
            )}
          </div>
        </div>
      }
      defaultOptions={{
        onCancelButtonVisible: false,
        onSuccessButtonVisible: false,
      }}
      fullWidth={true}
      defaultOpen={integrationsLoaded && integrations.length === 0}>
      <>
        <p className="mb-2">These listed API embed credentials are usable within this workspace</p>
        {workspaceId ? null : (
          <div className="relative z-10 mb-3 flex gap-x-2 gap-y-5">
            <div className="w-full max-w-md">
              <Search
                placeholder={"Search"}
                keyword={keyword}
                setKeyword={setkeyword}
              />
            </div>
            {integrations?.length > 1 && (
              <div className="w-48 flex-shrink-0">
                <MultiSelectDropdown
                  disabled={integrations?.length < 1}
                  placeholder={"Vendor"}
                  selectedItem={selectedProviders}
                  dropdownData={dataProviders.map((dataProvider) => ({
                    _id: dataProvider?._id,
                    name: dataProvider?.name,
                    image: dataProvider?.image,
                  }))}
                  onClick={(event, item) => {
                    if (event.target.checked) {
                      setSelectedProviders((prevData) => [...prevData, item._id]);
                    } else {
                      setSelectedProviders((prevData) => [...prevData.filter((data) => data !== item._id)]);
                    }
                  }}
                />
              </div>
            )}
            {!workspaceId && workspaces?.length > 0 && (
              <div className="w-48 flex-shrink-0">
                <MultiSelectDropdown
                  disabled={integrations?.length < 1}
                  placeholder="Workspace"
                  selectedItem={selectedWorkspaces}
                  dropdownData={workspaces.map((workspace) => ({
                    _id: workspace?._id,
                    name: workspace?.name,
                    image: workspace.square_logo || workspace.image_logo || workspace.image_favicon,
                  }))}
                  onClick={(event, item) => {
                    if (event.target.checked) {
                      setSelectedWorkspaces((prevData) => [...prevData, item._id]);
                    } else {
                      setSelectedWorkspaces((prevData) => [...prevData.filter((data) => data !== item._id)]);
                    }
                  }}
                />
              </div>
            )}
          </div>
        )}
        {integrationsLoaded ? (
          integrations?.length > 0 ? (
            <div className="space-y-2">
              {integrations.map((integration, index) => {
                return (
                  <WorkspaceIntegrationItem
                    key={integration?._id}
                    integration={integration}
                    integrationsLoaded={integrationsLoaded}
                    workspaceId={workspaceId}
                    is_global={is_global}
                    workspaceDetails={workspaceDetails}
                    editCredential={(provider, embedType, item) => {
                      setProviderDetails(provider);
                      setEmbedType(embedType);
                      setEditItem(item);
                      setIsOpen(true);
                    }}
                    openCreatedByModal={(provider, item) => {
                      if (workspaceId) {
                        setEditorUserList(editorUsers);
                      } else {
                        if (item?.workspace_id) {
                          setEditorUserList(editorUsers.filter((user) => user?.default_user || user?.workspace_id?.includes(item?.workspace_id)));
                        } else {
                          setEditorUserList(editorUsers.filter((user) => user?.type === "admin"));
                        }
                      }
                      setProviderDetails(provider);
                      setEditItem(item);
                      setIsCreatedByModalOpen(true);
                    }}
                    removeCredential={deleteCredModalOpen}
                    makeDefaultButtonDisabled={makeDefaultButtonDisabled}
                    setMakeDefaultButtonDisabled={setMakeDefaultButtonDisabled}
                    refreshIntegrations={refreshIntegrations}
                  />
                );
              })}
              {dataProviderList.length > 0 && (
                <div className="flex pt-4">
                  <AddCredentialDropdown
                    dropdownData={dataProviderList}
                    onClick={(provider, embedType) => {
                      setProviderDetails(provider);
                      setEmbedType(embedType);
                      setEditItem(null);
                      setIsOpen(true);
                    }}
                  />
                </div>
              )}
            </div>
          ) : (
            <NoDataDashedContainer
              title="No credentials found"
              addButton={
                dataProviderList.length > 0 && (
                  <AddCredentialDropdown
                    align={"right"}
                    dropdownData={dataProviderList}
                    onClick={(provider, embedType) => {
                      setProviderDetails(provider);
                      setEmbedType(embedType);
                      setEditItem(null);
                      setIsOpen(true);
                    }}
                  />
                )
              }
            />
          )
        ) : (
          <Preloader />
        )}
        <WorkspacesIntegrationModal
          isOpen={isOpen}
          workspaceId={workspaceId}
          is_global={is_global}
          providerDetails={providerDetails}
          embedType={embedType}
          editItem={editItem}
          onCancel={() => {
            setIsOpen(false);
            setProviderDetails(null);
            setEmbedType(null);
          }}
          onSuccess={() => {
            refreshIntegrations();
            setIsOpen(false);
          }}
        />
        <WorkspacesIntegrationCreatedByManageModal
          isOpen={isCreatedByModalOpen}
          workspaceId={workspaceId}
          is_global={is_global}
          providerDetails={providerDetails}
          editItem={editItem}
          users={editorUserList.map((user) => ({ _id: user?._id, name: user?.name }))}
          onCancel={() => {
            setIsCreatedByModalOpen(false);
            setProviderDetails(null);
            setEditItem(null);
          }}
          onSuccess={() => {
            refreshIntegrations();
            setIsCreatedByModalOpen(false);
          }}
        />
        <Modal
          title="Credential"
          secondaryTitle="Delete"
          isOpen={isDeleteOpen}
          onCancel={() => {
            setIntegrationId(null);
            setCredentialId(null);
            setIsDeleteOpen(false);
          }}
          isLoading={deleteIsLoading}
          onSuccess={removeCredential}
          defaultOptions={{
            onSuccessButtonText: "Delete",
          }}>
          <div className="flex flex-col gap-y-1 whitespace-nowrap px-3 py-6 text-gray-600">
            <p className="text-lg">
              Are you sure you want to <span className="px-1 font-semibold text-gray-700">DELETE</span>
              this credential?
            </p>
            <div className="text-gray-400">Once you delete this credential it's gone for good.</div>
          </div>
        </Modal>
      </>
    </EditContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};
export default connect(mapStateToProps, { workspaceIntegrationCredentialDelete })(WorkspaceIntegrationsList);
