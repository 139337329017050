import { TrashIcon } from "@heroicons/react/24/outline";
import { useContext } from "react";
import { connect } from "react-redux";
import { UserCustomFieldContext } from "src/context/UserCustomFieldContext";
import TextAccordion from "src/components/Shared/Accordions/TextAccordion";
import Checkbox from "src/components/Shared/Forms/Inputs/Checkbox";
import Input from "src/components/Shared/Forms/Inputs/Input";
import Button from "src/components/Shared/Buttons/Button";

const UserCustomFieldItem = ({ index, site, onChange }) => {
  const { fields, setFields } = useContext(UserCustomFieldContext);

  const updateField = (key, value = "") => {
    onChange();
    setFields((fields) =>
      fields.map((field, i) =>
        i !== index
          ? field
          : {
              ...field,
              [key]: value,
            }
      )
    );
  };

  const removeField = () => {
    setFields((fields) => fields.filter((field, i) => i !== index));
    onChange();
  };

  return (
    <div className="relative w-full flex flex-col gap-y-5 mb-5">
      <div className="flex items-center w-full ">
        <div className="w-full max-w-md">
          <Input
            label="Label"
            value={fields[index]?.label || ""}
            onChange={(e) => updateField("label", e.target.value)}
          />
        </div>
        <div className="mt-4 ml-3">
          <Button
            type="button"
            version="default"
            onClick={removeField}
            className="w-8 h-8 flex items-center justify-center border-gray-200 rounded-md text-gray-500 hover:text-gray-600 outline-none ring-0 transition-all duration-200 hover:bg-gray-50">
            <TrashIcon className="w-5 h-5" />
          </Button>
        </div>
      </div>
      <div className="w-full -mt-6">
        <TextAccordion
          headerTextPosition="left"
          headerText="Advanced details">
          <div className="w-full flex flex-col gap-y-5">
            <div className="grid gap-y-2">
              <div className="flex items-center gap-x-3">
                <Checkbox
                  checkboxName="set_default"
                  onChange={() => updateField("is_required", !fields[index].is_required)}
                  isChecked={fields[index].is_required}
                />
                <p className="text-gray-500">*Is a required field</p>
              </div>
              <div className="w-full gap-y-2">
                <div className="flex items-center gap-x-3">
                  <Checkbox
                    checkboxName="set_default"
                    onChange={() => updateField("allow_default_value", !fields[index].allow_default_value)}
                    isChecked={fields[index].allow_default_value}
                  />
                  <p className="text-gray-500">Add a default value</p>
                </div>
                {fields[index].allow_default_value && (
                  <div className="max-w-lg">
                    <Input
                      disabled={!fields[index].allow_default_value}
                      label="Default"
                      value={fields[index]?.default_value || ""}
                      onChange={(e) => updateField("default_value", e.target.value)}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </TextAccordion>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
  };
};

export default connect(mapStateToProps, {})(UserCustomFieldItem);
